package com.narbase.letsgo.web.routing.domain.admin.passengers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.PassengerDto

object CreatePassengerForAdminEndpoint : EndPoint<CreatePassengerForAdminEndpoint.Request, CreatePassengerForAdminEndpoint.Response>() {
    class Request(
        val passengerDto: PassengerDto,
        val adminId: Long
    )

    class Response(
        val passengerId: Long?,
    )
}