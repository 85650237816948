package com.narbase.letsgo.web.login

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vh
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.storage.bidirectional
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.DEFAULT_IMAGE
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.mediumScreen
import com.narbase.letsgo.web.utils.views.pointerCursor
import com.narbase.letsgo.web.utils.views.withLoadingAndError


class LoginPageContent(
        private val loginViewController: LoginViewController
) : Component() {

    private val viewModel = LoginViewModel()

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        loginViewController.onViewCreated()
    }

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        loginViewController.onViewMounted()
    }

    override fun View?.getView() = verticalLayout {
        style {
            backgroundColor = Color.white
            margin = 0.px
            padding = 0.px
            width = matchParent
            height = matchParent
            minHeight = 100.vh
        }

        id = "horizontalLayout"
        scrollable {
            matchParentWidth()
            horizontalLayout {
                style {
                    minHeight = 100.vh
                    width = matchParent
                }
                horizontalFiller()
                loginDialog()
                view { style { width = weightOf(3) } }
                sideImage()

            }
        }
    }

    private fun LinearLayout.loginDialog() = verticalLayout {
        style {
            backgroundColor = Color(255, 255, 255)
            width = wrapContent
            height = wrapContent
            padding = 64.px
            justifyContent = JustifyContent.Center
            mediumScreen {
                alignItems = Alignment.Center
                padding = 32.px
            }
        }
        view {
            style {
                height = 8.vh
            }
        }

        imageView {
            style {
                width = wrapContent
                height = 80.px
                padding = 2.px
                backgroundColor = Color.white
                mediumScreen {
                    height = 80.px
                }
            }

            element.src = DEFAULT_IMAGE

        }

        textView {
            style {
                fontSize = 30.px
                color = AppColors.mainVeryDarkColor
                marginTop = 60.px
                fontWeight = "bold"
                mediumScreen {
                    fontSize = 18.px
                    marginTop = 64.px
                    color = AppColors.text
                    fontWeight = "unset"
                }
            }
            text = "Welcome to CarApp Admin".localized()
        }

        textView {
            style {
                fontSize = 18.px
                color = AppColors.textLight
                marginTop = 20.px
                mediumScreen {
                    fontSize = 18.px
                }
            }
            text = "Login to start managing CarApp".localized()
        }

        verticalLayout {
            style {
                marginTop = 32.px

            }
            form {
                style {
                    width = matchParent
                }
                element.onsubmit = {
                    it.preventDefault()
                }
                element.method = "post"
                horizontalLayout {
                    addRuleSet(LoginStyles.loginFormElementStyle)
                    imageView {
                        addRuleSet(LoginStyles.loginIconStyle)
                        element.src = "/public/img/username.png"
                    }
                    loginViewController.usernameTextInput = textInput {
                        addRuleSet(LoginStyles.loginTextInputStyle)
                        placeholder = "Email".localized()
                        element.name = "name"
                    }

                }
                horizontalLayout {
                    addRuleSet(LoginStyles.loginFormElementStyle)
                    imageView {
                        addRuleSet(LoginStyles.loginIconStyle)
                        element.src = "/public/img/password.png"
                    }
                    loginViewController.passwordTextInput = textInput {
                        addRuleSet(LoginStyles.loginTextInputStyle)
                        placeholder = "Password".localized()
                        element.name = "password"
                        element.type = "password"
                    }

                }
                verticalLayout {
                    style {
                        width = matchParent
                    }
                    isVisible = false

                }
                loginViewController.loginButton = button {
                    id = "LoginButton"
                    addRuleSet(LoginStyles.loginButtonStyle)
                    text = "Login".localized()
                    element.type = "submit"
                }
            }

            loginViewController.loadingImageView = imageView {
                style {
                    marginTop = 18.px
                    width = 40.px
                    height = 40.px
                    alignSelf = Alignment.Center
                }
                isVisible = false
                element.src = "/public/img/loading.gif"
            }

            loginViewController.statusTextView = textView {
                style {
                    marginTop = 8.px
                    width = matchParent
                    height = wrapContent
                    fontSize = 14.px
                    textAlign = bidirectional(TextAlign.Left, TextAlign.Right)
                }
                isVisible = true
            }
        }

    }


    private fun LinearLayout.sideImage() {
        imageView {
            style {
                width = wrapContent
                height = 105.vh
                position = "absolute"
                end = (-50).vh
                top = (-15).vh
                animation = "continuous_rotation 600s linear infinite"
            }

            element.src = "/public/img/login-side.png"
        }
        imageView {
            style {
                width = wrapContent
                height = (40).vh
                position = "absolute"
                start = (-10).vh
                bottom = (-10).vh
                this["scale"] = "-1"
                animation = "continuous_rotation 800s linear infinite"
            }

            element.src = "/public/img/login-side.png"
        }
    }
}

object LoginStyles {
    val loginButtonStyle = classRuleSet {
        color = Color.white
        backgroundColor = AppColors.mainColor
        border = "none"
        width = matchParent
        padding = 8.px
        fontSize = 18.px
        borderRadius = 4.px
        marginTop = 32.px
        pointerCursor()
        hover {
            backgroundColor = AppColors.mainColor
        }
    }

    val loginTextInputStyle = classRuleSet {
        width = weightOf(1, 200.px)
        height = wrapContent
        fontSize = 16.px
        border = "0px solid #ffffff"
        borderWidth = "0px"
        outline = "none"
        padding = 6.px
    }

    val loginIconStyle = classRuleSet {
        alignSelf = Alignment.Center
        width = 24.px
        height = 24.px
        padding = 6.px
    }

    val loginFormElementStyle = classRuleSet {
        alignItems = Alignment.Center
        border = "1px solid ${AppColors.borderColor}"
        borderRadius = 4.px
        marginBottom = 16.px
        width = matchParent
        overflow = "hidden"
    }
}


interface LoginPageNavigator {
    fun onLoggedInSuccessful()
}
