package com.narbase.letsgo.web.events

import com.narbase.letsgo.web.BasePageViewModel
import com.narbase.letsgo.web.views.admin.AdminTabs

class ResetBreadCrumbsAndTabsEvent(val tab: BasePageViewModel.Tabs)
class AdminSelectedTabEvent(val tab: AdminTabs)
class AppendBreadCrumbsAndTabsEvent(val routeDetails: BasePageViewModel.RouteDetails)
class HideBreadCrumbs
class ServerConnectionEvent(val isConnected: Boolean)
class EscapeClickedEvent()
