package com.narbase.letsgo.web.storage

import com.narbase.letsgo.web.common.data.Privilege
import com.narbase.letsgo.web.dto.admin.AdminDto
import com.narbase.letsgo.web.dto.admin.DynamicRoleDto

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/18.
 */
object SessionInfo {
    var currentUser: CurrentUserProfile? = null
    val loggedInUser
        get() = currentUser ?: throw RuntimeException("loggedInUser can only be accessed when user is logged in")
}

class CurrentUserProfile(
        val admin: AdminDto,
        val roles: Array<DynamicRoleDto>,
        val privileges: List<Privilege>
)
