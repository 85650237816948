package com.narbase.letsgo.web.routing.domain.admin.ridepromocodes

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.ridepromocode.RidePromoCodeDto

object GetActivePromoCodesEndpoint : EndPoint<GetActivePromoCodesEndpoint.Request, GetActivePromoCodesEndpoint.Response>() {
    class Request(
        val passenger: Long,
        val pickupLat: Double?,
        val pickupLng: Double?,
        val destLat: Double?,
        val destLng: Double?
    )

    class Response(
        val ridePromo: RidePromoCodeDto?
    )
}