import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.pointerCursor
import com.narbase.letsgo.web.utils.views.singleLine
import com.narbase.letsgo.web.utils.views.withLoadingAndError
import com.narbase.letsgo.web.views.callCenter.RequestRideComponent
import com.narbase.letsgo.web.views.callCenter.RideDetailsDialog
import com.narbase.letsgo.web.views.callCenter.SelectedPassengerViewModel
import com.narbase.letsgo.web.views.callCenter.rating.RateRideDialog
import com.narbase.letsgo.web.views.callCenter.rating.RatingComponent

class SelectedPassengerComponent(
    val extendedPassengerDto: ExtendedPassengerDto,
    val onNextPassengerClicked: () -> Unit
) : Component(
) {
    private val viewModel = SelectedPassengerViewModel()


    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
    }

    override fun View?.getView() = horizontalLayout {
        style {
            width = matchParent
        }

        verticalLayout {
            style {
                width = 30.vw
                borderEnd = "1px solid ${AppColors.separatorLight}"
                padding = 24.px
            }
            theme.bigTextView(this) {
                text = "Passenger details"
            }
            theme.label(this, "Passenger".localized(), false)
            theme.mediumTextView(this) {
                text = extendedPassengerDto.passenger.fullName
            }
            theme.label(this, "Email / Phone".localized(), false)
            theme.mediumTextView(this) {
                text = extendedPassengerDto.passenger.email
            }
            profileGroupView(extendedPassengerDto)
            verticalFiller(15)
            val passengerId = extendedPassengerDto.passenger.id
            if (passengerId != null) {
                viewModel.setUpRideDetails(passengerId)
            }
            verticalLayout {
                style {
                    width = matchParent
                }
                horizontalLayout {
                    style {
                        width = matchParent
                        alignContent = Alignment.End
                    }
                    horizontalFiller()
                    horizontalLayout {
                        style {
                            width = wrapContent
                            alignContent = Alignment.Center
                            color = AppColors.activeColor
                        }
                        withLoadingAndError(viewModel.ridesUiState,
                            onRetryClicked = {
                                if (passengerId != null) {
                                    viewModel.setUpRideDetails(passengerId)
                                }
                            },
                            onLoaded = {
                                clearAllChildren()
                                theme.simpleLabel(this) {
                                    text = "Past rides up-to-date"
                                    style {
                                        color = AppColors.activeColor
                                    }
                                }
                            })
                    }
                }
                verticalLayout {
                    style {
                        width = matchParent
                    }
                    viewModel.ridesUiState.observe {
                        if (it == BasicUiState.Loaded) {
                            clearAllChildren()
                            populatePastRides()
                        }

                    }

                }
            }
        }
        verticalLayout {
            style {
                width = weightOf(1)
            }
            val passengerId = extendedPassengerDto.passenger.id
            if (passengerId != null) {
                mount(
                    RequestRideComponent(
                        extendedPassengerDto.passenger,
                        reloadRides = { viewModel.getRides(passengerId = passengerId) },
                        onRideCreated = {
                            viewModel.clearRideDetailsTimer()
                            onNextPassengerClicked.invoke()
                        }
                    )
                )
            }
        }
    }

    private fun LinearLayout.profileGroupView(extendedPassengerDto: ExtendedPassengerDto) {
        verticalLayout {
            style {
                width = matchParent
            }
            verticalLayout {
                style {
                    width = matchParent
                    isVisible = !extendedPassengerDto.profileGroup.isHiddenFromCallCenter
                }
                theme.label(this, "Profile Group ".localized(), false)
                theme.mediumTextView(this) {
                    text = extendedPassengerDto.profileGroup.name
                }
            }

            val commissionText =
                if (extendedPassengerDto.profileGroup.commission < 0) "Profile Group Discount".localized() else "Profile Group Commission".localized()
            theme.label(this, commissionText, false)
            theme.mediumTextView(this) {
                style {
                    backgroundColor =
                        if (extendedPassengerDto.profileGroup.commission < 0) AppColors.mainColor else AppColors.lightDarkBackground
                    color = Color.white
                    padding = 5.px
                    borderRadius = 5.px
                }
                text =
                    (if (extendedPassengerDto.profileGroup.commission < 0) (-extendedPassengerDto.profileGroup.commission).toString() else
                        extendedPassengerDto.profileGroup.commission.toString()) + if (extendedPassengerDto.profileGroup.isCommissionPercentage) "%" else "" //show currency
            }
        }
    }

    private fun View.populatePastRides() {

        clearAllChildren()
        if (viewModel.allPastRides.isNotEmpty()) {
            theme.label(this, "Ongoing Rides", false)
            viewModel.ongoingRides.forEach { item ->
                rideItemLayout(item)
                verticalFiller(10)
            }
            theme.label(this, "Other Past Rides", false)
            viewModel.otherRides.forEach { item ->
                rideItemLayout(item)
                verticalFiller(10)
            }
        } else {
            horizontalLayout {
                style {
                    width = matchParent
                    justifyContent = JustifyContent.Center
                    padding = 24.px
                }
                textView {
                    text = "No Rides Yet".localized()
                    style {
                        color = AppColors.textLight
                    }
                }
            }
        }

    }

    private fun View.rideItemLayout(item: ExtendedRideDto) = verticalLayout {
        style {
            width = matchParent
            padding = 8.px
            border = "1px solid ${AppColors.borderColor}"
            borderRadius = 4.px
            pointerCursor()
            hover {
                backgroundColor = AppColors.lightBackground
            }
        }
        theme.text(this) {
            textView {
                style { width = matchParent }
                text = item.ride.time.toDate().toDateString()
            }
        }
        horizontalLayout {
            style {
                width = matchParent
                justifyContent = JustifyContent.SpaceBetween
                paddingEnd = 5.px
            }
            theme.text(this) {
                style {
                    singleLine()
                    width = 40.percent
                }
                text = item.ride.pickupAddress
            }
            horizontalFiller()
            theme.text(this) {
                style {
                    singleLine()
                    width = 40.percent
                    textAlign = TextAlign.End
                }
                text = item.ride.destAddress ?: "no destination"
            }
        }
        theme.text(this) {
            text = item.ride.status.enum().dtoName
        }
        horizontalLayout {
            style {
                width = matchParent
                justifyContent = JustifyContent.SpaceBetween
            }
            theme.text(this) {
                text = "Driver: " + (item.driver?.fullName ?: "Not Assigned")
            }
            theme.text(this) {
                text = (item.carType?.nameEn ?: "")
            }
        }
        onClick = {
            val rideId = item.ride.id
            val passengerId = extendedPassengerDto.passenger.id
            if (rideId != null && passengerId != null) {
                RideDetailsDialog(
                    rideId,
                    item,
                    reloadRides = { viewModel.getRides(passengerId = passengerId) },
                    false,
                    onNextPassengerClicked = {
                        viewModel.clearRideDetailsTimer()
                        onNextPassengerClicked.invoke()
                    }
                ).show()
            }
        }
        verticalFiller(8)
        horizontalLayout {
            style {
                isVisible = item.ride.status.enum() == RideStatus.completed
                width = matchParent
                justifyContent = JustifyContent.End
                alignItems = Alignment.Center
            }
            val rating = item.ride.rating
            if (rating != null && rating != 0.0) {
                console.log(item.ride.ratedByCallCenter)
                if (item.ride.ratedByCallCenter != null) {
                    theme.text(this) {
                        text = "Rated by Call Center".localized()
                    }
                }
                horizontalFiller(10)
                mount(RatingComponent(rating))

            } else {
                theme.mainButton(this) {
                    text = "Rate".localized()
                    onClick = {
                        rateRideDialog(item)
                        it.stopPropagation()
                    }
                }

            }
        }
    }

    private fun rateRideDialog(extendedRideDto: ExtendedRideDto) {
        RateRideDialog() { rating, review ->
            extendedRideDto.ride.id?.let { rideId -> viewModel.rateRide(rideId, rating, review) }
            extendedRideDto.passenger.id?.let { id ->
                viewModel.rateRideUiState.observe {
                    viewModel.getRides(id)
                }
            }
        }.show()
    }


}
