package com.narbase.letsgo.web.common.data

import com.narbase.letsgo.web.network.EnumDtoName
import com.narbase.letsgo.web.translations.localized

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] - [2021] Narbase Technologies
 * All Rights Reserved.
 * Created by nezuma
 * On: 3/21/21.
 */
enum class MedicoModules(override val dtoName: String): EnumDtoName {
    Appointments("Appointments"),
    EMR("EMR"),
    Finance("Finance"),
    Lab("Lab"),
    Inventory("Inventory"),
    OnlineServices("OnlineServices"),
    DashboardAndReports("DashboardAndReports"),
}

fun MedicoModules.displayString(): String = when(this){
    MedicoModules.Appointments -> "Appointments".localized()
    MedicoModules.EMR -> "Electronic Medical Record".localized()
    MedicoModules.Finance -> "Finance".localized()
    MedicoModules.OnlineServices -> "Online Services".localized()
    MedicoModules.Inventory -> "Inventory".localized()
    MedicoModules.Lab -> "Lab".localized()
    MedicoModules.DashboardAndReports -> "Dashboard and Reports".localized()
}
