package com.narbase.letsgo.web.views.user.base

import com.narbase.kunafa.core.lifecycle.Observable

class UserProfileBaseViewModel {



    val selectedTab = Observable<UserProfileTabs>()



}