package com.narbase.letsgo.web.dto.flags

data class FlagDto (
        var id: Long?,
        val carTypeId: Int?,
        val maxPerDay: Int,
        val percentage: Double,
        val flagThreshold: Double,
        val profileGroupId: Int?,
        val driverId: Long?,
        val noDestination: Boolean
)