package com.narbase.letsgo.web.theme

import com.narbase.letsgo.web.theme.main.MainTheme


/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/11/08.
 */

val theme = MainTheme

//fun MainTheme.style(view: View): View = with(MainTheme) { view }

