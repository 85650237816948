package com.narbase.letsgo.web.utils

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] - [2021] Narbase Technologies
 * All Rights Reserved.
 * Created by amel
 * On: 5/10/21.
 */

const val DEFAULT_IMAGE = "/public/img/logo.png"