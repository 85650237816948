package com.narbase.letsgo.web.routing.domain.admin.auditlog

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.admin.AuditLogDto
import com.narbase.letsgo.web.dto.admin.ExtendedAuditLogDto

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2023/02/06.
 */
object GetAuditLogEndpoint : EndPoint<GetAuditLogEndpoint.Request, GetAuditLogEndpoint.Response>() {
    class Request(
            val pageNo: Int,
            val pageSize: Int,
            val subjectId: String?
    )

        class Response(
            val list: Array<ExtendedAuditLogDto>,
            val total: Int
    )
}