package com.narbase.letsgo.models.roles

import com.narbase.letsgo.dto.EnumDtoName

//{"privileges":["StaffManagement", "DriversAccess", "DriversManagement", "DynamicRoles", "RidesAccess", "Passengers", "DriverProfileGroups", "CallCenterOperation", "PassengerProfileGroups", "PushMessages", "Pricing", "GlobalConfig", "PromoCodeManagement", "AuditLogAccess"]}
enum class AdminPrivilege(dtoName: String? = null) : EnumDtoName {
    StaffManagement,
    DriversAccess,
    DriversManagement,
    DynamicRoles,
    RidesAccess,
    Passengers,
    DriverProfileGroups,
    CallCenterOperation,
    PassengerProfileGroups,
    PushMessages,
    Pricing,
    GlobalConfig,
    PromoCodeManagement,
    AuditLogAccess,
    ;

    override val dtoName = dtoName ?: this.name


}