package com.narbase.letsgo.web.utils.notifications

import com.narbase.kunafa.core.components.detached
import com.narbase.kunafa.core.components.linearLayout
import com.narbase.laflif.web.utils.eventbus.EventBus
import com.narbase.letsgo.web.common.AppConfig
import com.narbase.letsgo.web.events.ServerConnectionEvent
import com.narbase.letsgo.web.network.ServerCaller
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.utils.views.Audio
import com.narbase.letsgo.web.utils.views.DelayBouncer
import com.narbase.letsgo.web.utils.views.audio
import kotlinx.browser.window
import org.w3c.dom.WebSocket

object NotificationsController {
    var isConnectedToServer = false
    private val notificationDelayBouncer = DelayBouncer<Unit>(300)


    /**
     * Call initializedWebSocket before using
     */
    private var webSocket: WebSocket? = null

    private fun initializedWebSocket(): WebSocket {
        val location = window.location
        val host = location.host
        val wsProtocol = if (location.protocol == "https:") "wss:" else "ws:"
        val baseUrl =
                if (AppConfig.isDev)
                    ServerCaller.BASE_URL.replace("http://", "ws://").replace("https://", "ws://")
                else
                    "$wsProtocol//$host"
//        val baseUrl = ServerCaller.BASE_URL.replace("http://", "ws://").replace("https://", "ws://")
        return WebSocket("${baseUrl}/public/api/user/v1/socket",
                StorageManager.accessToken?.let { arrayOf("my_auth", it) })
    }

    fun connect() {
        if (isConnectedToServer) {
            disconnect()
        }
//        webSocket = initializedWebSocket()
        webSocket?.onclose = {
            isConnectedToServer = false
            EventBus.publish(ServerConnectionEvent(isConnected = false))
            val connectionRetryDuration = 1_000
            window.setTimeout({
                connect()
            }, connectionRetryDuration)
        }
        isConnectedToServer = true
        EventBus.publish(ServerConnectionEvent(isConnected = true))
        setupNotificationTone()
//        val loggedInClientId = SessionInfo.currentUser?.clientId
        webSocket?.onmessage = { messageEvent ->
            messageEvent.data?.toString()?.let { data ->
//                val message = JSON.parse<DataResponse<Message>>(data).data


            }
        }
    }


    var notificationTone: Audio? = null

    private fun setupNotificationTone() {
        if (notificationTone != null) return
        detached.linearLayout {
            notificationTone = audio {
                src = "/public/chime-notification-alert_C_major.wav"
                element.hidden = true
            }
        }
    }


    fun playNotificationTone() {
        if (StorageManager.isNotificationsEnabled) {
            notificationTone?.element?.play()
        }
    }

    fun playNotificationToneWithBouncer() {
        if (StorageManager.isNotificationsEnabled) {
            notificationDelayBouncer.buffer(Unit) {
                notificationTone?.element?.play()
            }
        }
    }

    fun disconnect() {
        webSocket?.onclose = {
            isConnectedToServer = false
            EventBus.publish(ServerConnectionEvent(isConnected = false))
        }
        webSocket?.close()
    }

}
