package com.narbase.letsgo.web.routing.domain.admin.zones

import com.narbase.letsgo.router.EndPoint
object GetPointZoneIdEndpoint : EndPoint<GetPointZoneIdEndpoint.Request, GetPointZoneIdEndpoint.Response>() {
    class Request(
        val lat: Double,
        val lng: Double
    )

    class Response(
        val zoneId: Int?
    )
}