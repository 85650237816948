/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] - [2022] Narbase Technologies
 * All Rights Reserved.
 * Created by shalaga44
 * On: 20/Dec/2022.
 */


package com.narbase.letsgo.web.network

import com.narbase.letsgo.models.RequestType
import com.narbase.letsgo.router.Authentication
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.utils.DataResponse
import kotlinx.browser.window
import org.w3c.xhr.FormData

suspend fun <V : Any, D : Any> EndPoint<V, D>.remoteProcess(dto: V): DataResponse<D> {
    val endPoint = this
    val headers = authHeaders(endPoint)
    return ServerCaller.synchronousPost(
            url = endPoint.path,
            headers = headers,
            body = dto
    )
}


suspend fun <D : Any> EndPoint<RequestType.FormData, D>.remoteProcess(formData: FormData): DataResponse<D> {
    val endPoint = this
    return ServerCaller.synchronousPost(
            url = endPoint.path,
            headers = authHeaders(this),
            body = formData,
            stringify = false,
            setContentType = false,
    )
}

private fun authHeaders(endPoint: EndPoint<*, *>): Map<String, String> {
//    console.log("ServerCaller.accessToken: ${ServerCaller.accessToken}")
    val headers = endPoint.authentication.map {
        when (it) {
            Authentication.JWT -> mapOf("Authorization" to "${ServerCaller.accessToken}")
            else -> TODO("Basic Auth not implemented yet")
        }
    }.flatMap { it.asSequence() }
            .associate { it.key to it.value }
//    console.log("For ${endPoint.path}. Auth: ${endPoint.authentication.joinToString { it.name }}")
//    headers.entries.forEach { console.log("${it.key}:${it.value}") }
    return headers
}
