package com.narbase.letsgo.web.routing.domain.admin.ridepromocodes

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.ridepromocode.RidePromoCodeDto

object DeactivateRidePromoCodeEndpoint :
    EndPoint<DeactivateRidePromoCodeEndpoint.Request, DeactivateRidePromoCodeEndpoint.Response>() {
    class Request(
        val passengerId: Long,
    )

    class Response(
    )
}