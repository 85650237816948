package com.narbase.letsgo.web.dto.passengerprofilegroup

import com.narbase.letsgo.web.dto.cartypes.CarTypeDto


class PassengerProfileGroupDto(
    val id: Int?,
    val name: String,
    val commission: Double,
    val isCommissionPercentage: Boolean,
    val color: String?,
    val toBeReplacedCarTypeId: Int?,
    val toBeUsedCarTypeId: Int?,
    val isHiddenFromCallCenter: Boolean
)

class ExtendedPassengerProfileGroupDto(
    val passengerProfileGroup: PassengerProfileGroupDto,
    val toBeReplacedCarType: CarTypeDto?,
    val toBeUsedCarType: CarTypeDto?
)