package com.narbase.letsgo.web.views.admin.drivers

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.width
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.dto.core.GenderDto
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto
import com.narbase.letsgo.web.dto.currency.CurrencyDto
import com.narbase.letsgo.web.dto.driverprofilegroup.DriverProfileGroupDto
import com.narbase.letsgo.web.dto.drivers.ExtendedDisplayDriverDto
import com.narbase.letsgo.web.dto.drivers.UpsertDriverDto
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.cartypes.GetCarTypesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.currencies.GetCurrenciesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup.GetDriverProfileGroupsEndpoint
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.dateDto
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*
import kotlin.js.Date

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/04/24.
 */
class BasicDriverFrom(private val toEdit: ExtendedDisplayDriverDto?) : Component() {

    private var genderDropList: RemoteDropDownList<GenderDto>? = null
    private var currencyDropList: RemoteDropDownList<CurrencyDto>? = null
    private var selectedCurrency: CurrencyDto? = null

    private var carTypesDropList: RemoteDropDownList<CarTypeDto>? = null
    private var selectedCarType: CarTypeDto? = null
    private var profileGroupDropList: RemoteDropDownList<DriverProfileGroupDto>? = null
    private var selectedProfileGroup: DriverProfileGroupDto? = null
    private var selectedInsuranceDate: Date? = null
    private var selectedLastCarCheck: Date? = null

    private var profileImageUpload: ImageUploader? = null
    private var fullNameTextInput: TextInput? = null
    private var phoneNumberTextInput: TextInput? = null
    private var civilNumberTextInput: TextInput? = null
    private var licenseTextInput: TextInput? = null
    private var vehicleTextInput: TextInput? = null
    private var carModelTextInput: TextInput? = null
    private var carPlateTextInput: TextInput? = null
    private var notesTextInput: TextInput? = null
    private var insuranceDateField: DateInputViewHolder? = null
    private var lastCarCheckDateField: DateInputViewHolder? = null

    private var isMale: Boolean? = null

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        toEdit?.let { loadData(it) }
    }

    private fun loadData(dto: ExtendedDisplayDriverDto) {
        fullNameTextInput?.text = dto.driver.fullName
        phoneNumberTextInput?.text = dto.driver.phone
        civilNumberTextInput?.text = dto.driver.civilNumber ?: ""
        licenseTextInput?.text = dto.driver.licenseNumber
        vehicleTextInput?.text = dto.driver.vehicle
        carModelTextInput?.text = dto.driver.carModel ?: ""
        carPlateTextInput?.text = dto.driver.plate
        notesTextInput?.text = dto.driver.notes ?: ""
        isMale = dto.driver.isMale
        dto.driver.lastCarCheck?.toDate()?.let {
            lastCarCheckDateField?.datePicker?.picker?.setDate(it, false)
        }
        dto.driver.insuranceDate?.toDate()?.let {
            insuranceDateField?.datePicker?.picker?.setDate(it, false)
        }
    }

    override fun View?.getView() = verticalLayout {
        style {
            width = matchParent
        }
//        theme.subTitleFit(this, "Basic info".localized())
        profileImageUpload = imageUploader(toEdit?.driver?.imageUrl)
        verticalFiller(8)
        fullNameTextInput = theme.labeledTextInput(this, "Full name".localized(), isRequired = true)
        rowLayout({
            phoneNumberTextInput = theme.labeledTextInput(this, "Phone number".localized(), isRequired = true) {
                element.type = "number"
            }
        }, {
            genderDropList()
        })
        rowLayout({
            civilNumberTextInput = theme.labeledTextInput(this, "Civil number".localized(), isRequired = true)
        }, {
            licenseTextInput = theme.labeledTextInput(this, "License number".localized(), isRequired = true)
        })

        rowLayout({
            vehicleTextInput = theme.labeledTextInput(this, "Vehicle".localized(), isRequired = true)
        }, {
            carModelTextInput = theme.labeledTextInput(this, "Car model".localized(), isRequired = true)

        }, {
            carPlateTextInput = theme.labeledTextInput(this, "Car plate".localized(), isRequired = true)
        })

        rowLayout({
            insuranceDateField = dateField("Insurance date".localized(), isRequired = true) {
                selectedInsuranceDate = it
            }
        }, {
            lastCarCheckDateField = dateField("Last car check".localized(), isRequired = true) {
                selectedLastCarCheck = it
            }
        })
        rowLayout({ currenciesList() }, { carTypeList() }, { driverProfileGroupList() })
        rowLayout({
            notesTextInput = theme.labeledTextInput(this, "Notes".localized(), isRequired = false)
        })
        verticalFiller(16)
    }

    private fun View.genderDropList() {
        verticalLayout {
            matchParentWidth()
            theme.label(this, "Gender".localized(), isRequired = true)
            val defaultValue =
                toEdit?.driver?.isMale?.let { isMale -> if (isMale) GenderDto.Male else GenderDto.Female }
            genderDropList = setupDropDownList(
                "Gender".localized(),
                getList = { pageNo, _, _ -> if (pageNo == 0) GenderDto.values() else arrayOf() },
                itemToString = { it.name },
                onItemSelected = {
                    isMale = when (it) {
                        GenderDto.Male -> true
                        GenderDto.Female -> false
                        null -> null
                    }
                },
                defaultItem = defaultValue,
                viewWidthFactory = { matchParent }
            )

        }
    }

    private fun View.currenciesList() {
        verticalLayout {
            matchParentWidth()
            theme.label(this, "Currency".localized(), isRequired = true)
            val defaultValue = toEdit?.currency

            currencyDropList = setupDropDownList(
                "Currency".localized(),
                getList = { pageNo, pageSize, searchTerm ->
                    GetCurrenciesEndpoint.remoteProcess(
                        GetCurrenciesEndpoint.Request(
                            pageNo,
                            pageSize,
                            searchTerm
                        )
                    ).data
                        .list
                },
                itemToString = { it.name },
                onItemSelected = {
                    selectedCurrency = it
                },
                defaultItem = defaultValue,
                viewWidthFactory = { matchParent }
            )

        }
    }

    private fun View.carTypeList() {
        verticalLayout {
            matchParentWidth()
            theme.label(this, "Car type".localized(), isRequired = true)
            val defaultValue = toEdit?.carType

            carTypesDropList = setupDropDownList(
                "Car type".localized(),
                getList = { pageNo, pageSize, searchTerm ->
                    GetCarTypesEndpoint.remoteProcess(
                        GetCarTypesEndpoint.Request(
                            pageNo,
                            pageSize,
                            searchTerm
                        )
                    ).data
                        .list
                },
                itemToString = { "${it.nameEn} - ${it.nameAr}" },
                onItemSelected = {
                    selectedCarType = it
                },
                defaultItem = defaultValue,
                viewWidthFactory = { matchParent }
            )

        }
    }

    private fun View.driverProfileGroupList() {
        verticalLayout {
            matchParentWidth()
            theme.label(this, "Profile group".localized(), isRequired = true)
            val defaultValue = toEdit?.driverProfileGroup

            profileGroupDropList = setupDropDownList(
                "Profile group".localized(),
                getList = { pageNo, pageSize, searchTerm ->
                    GetDriverProfileGroupsEndpoint.remoteProcess(
                        GetDriverProfileGroupsEndpoint.Request(
                            pageNo,
                            pageSize,
                            searchTerm
                        )
                    ).data.list.map { it.driverProfileGroup }.toTypedArray()
                },
                itemToString = { it.name },
                onItemSelected = {
                    selectedProfileGroup = it
                },
                defaultItem = defaultValue,
                viewWidthFactory = { matchParent }
            )

        }
    }

    private fun LinearLayout.rowLayout(vararg blocks: View.() -> Unit) {
        horizontalLayout {
            matchParentWidth()
            blocks.forEachIndexed { index, block ->
                if (index != 0) horizontalFiller(8)
                verticalLayout {
                    style {
                        width = weightOf(1)
                    }
                    block()
                }
            }
        }
    }

    private fun View.dateField(title: String, isRequired: Boolean = true, onDateSelected: (Date) -> Unit): DateInputViewHolder {
        val viewHolder = DateInputViewHolder()
        viewHolder.input = theme.labeledTextInput(this, title, isRequired) {
            placeholder = "Select a date"
            viewHolder.datePicker = DatePicker(
                this.element,
                onSelect = { onDateSelected(it) },
                bound = true,
                theme = "light-theme",
                i18n = I18n(
                    months = arrayOf(
                        "January".localized(),
                        "February".localized(),
                        "March".localized(),
                        "April".localized(),
                        "May".localized(),
                        "June".localized(),
                        "July".localized(),
                        "August".localized(),
                        "September".localized(),
                        "October".localized(),
                        "November".localized(),
                        "December".localized()
                    ),
                    weekdays = arrayOf(
                        "Sun".localized(),
                        "Mon".localized(),
                        "Tue".localized(),
                        "Wed".localized(),
                        "Thu".localized(),
                        "Fri".localized(),
                        "Sat".localized()
                    ),
                    weekdaysShort = arrayOf("S", "M", "T", "W", "T", "F", "S")
                )
            )
        }
        return viewHolder
    }

    fun validateAndGetUpdatedDriverDto(): UpsertDriverDto? {
        val imageUrl = profileImageUpload?.imageUrl
        val fullName = validateOrReturn(fullNameTextInput?.text, "Full name") ?: return null
        val phoneNumber = validateOrReturn(phoneNumberTextInput?.text, "Phone") ?: return null
        val civilNumber = validateOrReturn(civilNumberTextInput?.text, "Civil number") ?: return null
        val license = validateOrReturn(licenseTextInput?.text, "License") ?: return null
        val vehicle = validateOrReturn(vehicleTextInput?.text, "Vehicle") ?: return null
        val carModel = validateOrReturn(carModelTextInput?.text, "Car model") ?: return null
        val carPlate = validateOrReturn(carPlateTextInput?.text, "Car plate") ?: return null
        val notes = notesTextInput?.text ?: ""
        val isMale = isMale ?: run {
            SnackBar.showText("Gender is required".localized())
            return null
        }
        val carTypeId = selectedCarType?.id ?: run {
            SnackBar.showText("Car type is required".localized())
            return null
        }
        val currencyId = selectedCurrency?.id ?: run {
            SnackBar.showText("Currency is required".localized())
            return null
        }
        val profileGroupId = selectedProfileGroup?.id ?: run {
            SnackBar.showText("Profile group is required".localized())
            return null
        }

        return UpsertDriverDto(
            toEdit?.driver?.id,
            "$license@car.narbase.com",
            fullName,
            imageUrl,
            isMale,
            phoneNumber,
            vehicle,
            carPlate,
            license,
            profileGroupId,
            civilNumber,
            carModel,
            notes,
            carTypeId,
            currencyId,
            selectedInsuranceDate?.dateDto(),
            selectedLastCarCheck?.dateDto(),
        )
    }

    private fun validateOrReturn(text: String?, itemName: String): String? {
        if (text.isNullOrBlank()) {
            SnackBar.showText("$itemName is required".localized())
            return null
        }
        return text
    }

    class DateInputViewHolder {
        lateinit var input: TextInput
        lateinit var datePicker: DatePicker

    }
}