package com.narbase.almutkhassisa.web.views.googlemaps

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.css.height
import com.narbase.kunafa.core.css.marginBottom
import com.narbase.kunafa.core.css.width
import com.narbase.kunafa.core.dimensions.Dimension
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import kotlin.js.json

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2019/04/03.
 */
class GoogleMap(val heightSize: Dimension = 320.px, val options: dynamic = null) : Component() {
    private var mapDiv: View? = null
    var map: google.Maps.Map? = null
    private var marker: google.Maps.Marker? = null

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        map = google.Maps.Map(mapDiv?.element, options)
    }

    override fun View?.getView(): View {
        val initializedMapDiv = horizontalLayout {
            id = "map-div"
            style {
                width = matchParent
                height = 100.percent
            }
        }
        mapDiv = initializedMapDiv
        return initializedMapDiv
    }

    fun setMarker(lat: Double, lng: Double, title: String) {
        val center = json("lat" to lat, "lng" to lng)
        marker = google.Maps.AdvancedMarkerElement(json("position" to center, "map" to map, "title" to title))
    }

    fun addMarker(lat: Double, lng: Double, options: Array<Pair<String, dynamic>>): google.Maps.Marker {
        val center = json("lat" to lat, "lng" to lng)
        return google.Maps.AdvancedMarkerElement(json("position" to center, "map" to map, *options))
    }

    fun moveToLocation(lat: Double, lng: Double) {
        map?.setCenter(google.Maps.LatLng(lat, lng))
    }

    fun setZoom(zoom: Float) {
        map?.setZoom(zoom)
    }

    fun getZoom() : Float? {
        return map?.getZoom()
    }

    fun clearMarker() {
        marker?.setMap(null)
    }

    fun getCenter() = map?.getCenter()

    fun getBounds() = map?.getBounds()

    fun setOptions(options: dynamic) {
        map?.setOptions(options)
    }

    fun addListener(event: String, callback: () -> Unit) {
        map?.addListener(event, callback)
    }
}