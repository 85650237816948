package com.narbase.letsgo.web.views.admin

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleObserver
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.routing.*
import com.narbase.laflif.web.utils.eventbus.EventBus
import com.narbase.letsgo.web.BasePageViewModel
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.events.HideBreadCrumbs
import com.narbase.letsgo.web.storage.bidirectional
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.colors.gray
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.session.authorizedForAny
import com.narbase.letsgo.web.utils.session.isAuthorized
import com.narbase.letsgo.web.utils.views.SideBar
import com.narbase.letsgo.web.utils.views.matchParentDimensions
import com.narbase.letsgo.web.utils.views.mediumScreen
import com.narbase.letsgo.web.views.admin.auditlog.AuditLogComponent
import com.narbase.letsgo.web.views.admin.drivers.DriversManagementComponent
import com.narbase.letsgo.web.views.admin.roles.RolesManagementComponent
import com.narbase.letsgo.web.views.admin.staff.StaffManagementComponent
import com.narbase.letsgo.web.views.callCenter.CallCenterComponent
import com.narbase.letsgo.web.views.driverProfileGroups.DriverProfileGroupsManagementComponent
import com.narbase.letsgo.web.views.incentives.IncentivesManagementComponent
import com.narbase.letsgo.web.views.passengerProfileGroups.PassengerProfileGroupsManagementComponent
import kotlinx.browser.window

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/18.
 */

class AdminPageComponent : Component() {

    private val tabsToViews = mutableMapOf<AdminTabs, View>()

    private val tabsToComponents = mapOf(
        AdminTabs.Staff to StaffManagementComponent(showCurrent = true),
        AdminTabs.Drivers to DriversManagementComponent(),
//            AdminTabs.InActiveStaff to StaffManagementComponent(showCurrent = false),
        AdminTabs.Roles to RolesManagementComponent(),
        AdminTabs.AuditLog to AuditLogComponent(),
        AdminTabs.CallCenter to CallCenterComponent(),
        AdminTabs.DriverProfileGroups to DriverProfileGroupsManagementComponent(),
        AdminTabs.PassengerProfileGroups to PassengerProfileGroupsManagementComponent(),
        AdminTabs.Incentive to IncentivesManagementComponent()
    )


    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        EventBus.publish(HideBreadCrumbs())
        updateSelectedTabFromUrl()
    }

    private fun updateSelectedTabFromUrl() {
        val selectedTabPath = window.location.pathname.removePrefix(routeDetails.href)
        val tab = tabsToViews.keys.firstOrNull { selectedTabPath.contains(it.routeDetails.href.removePrefix("/")) }
            ?: defaultTab
        setSelectedTab(tab)
    }

    override fun View?.getView(): View = horizontalLayout {
        id = "adminRootView"
        style {
            height = matchParent
            width = matchParent
        }
        horizontalLayout {
            matchParentDimensions()

            sideBar()
            verticalLayout {
                style {
                    height = matchParent
                    width = weightOf(5)
                    alignItems = Alignment.Center
                }

                matchFirst {
                    tabsToComponents.forEach { pair ->
                        routeAdminComponent(pair.key) {
                            pair.value
                        }
                    }

                    redirect(
                        adminRoute(defaultTab),
                        isAbsoluteDestination = true,
                        isExact = false
                    )
                }
            }
            updateSelectedTabFromUrl()

        }
    }

    private fun LinearLayout.sideBar() {
        verticalLayout {
            bind(object : LifecycleObserver {
                override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
                    updateSelectedTabFromUrl()
                }
            })
            style {
                height = matchParent
                width = 240.px
                backgroundColor = AppColors.extraLightBackground
                borderEnd = "1px solid ${gray(0.9)}"
            }

            scrollable {
                style {
                    width = matchParent
                    height = matchParent
                }
                verticalLayout {
                    style {
                        width = matchParent
                        height = wrapContent
                        paddingTop = 8.px
                    }

                    AdminTabs.subLists.forEach { subList ->
                        val allTabsPrivileges = subList.tabs.map { it.privileges.toList() }.flatten()
                        authorizedForAny(allTabsPrivileges) {
                            textView {
                                text = subList.title
                                addRuleSet(SideBar.Styles.mainTabTextStyle)
                            }

                            subList.tabs.forEach { tab ->
                                if (tab.privileges.isEmpty()) {
                                    addMenuItem(tab).let { menuView ->
                                        tabsToViews[tab] = menuView
                                    }
                                } else {
                                    authorizedForAny(tab.privileges.toList()) {
                                        addMenuItem(tab).let { menuView ->
                                            tabsToViews[tab] = menuView
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun LinearLayout.addMenuItem(tab: AdminTabs): LinearLayout {
        val routeDetails = tab.routeDetails
        return verticalLayout {
            style {
                width = matchParent
                borderStart = "4px solid ${Color.transparent}"

            }
            id = routeDetails.title
            val linkPath = "${AdminPageComponent.routeDetails.href}${routeDetails.href}"
            link(linkPath) {
                style {
                    textDecoration = "none"
                    width = matchParent
                }
                verticalLayout {
                    style {
                        width = matchParent
                        position = "relative"
                        border = "0.1px solid ${Color.transparent}"
                        padding = "4px 8px 4px 16px".dimen()
                        color = AppColors.text
                        hover {
                            color = AppColors.texMainDarkColor
                        }
                    }

                    horizontalLayout {
                        style {
                            justifyContent = JustifyContent.Center
                            alignItems = Alignment.Center
                            marginStart = 8.px
                        }

                        horizontalLayout {

                            textView {
                                text = routeDetails.title
                                style {
                                    height = wrapContent
                                    alignSelf = Alignment.Center
                                    textAlign = bidirectional(TextAlign.Left, TextAlign.Right)
                                    fontSize = AppFontSizes.normalText
                                    mediumScreen {
                                        fontSize = AppFontSizes.smallText
                                    }

                                }
                            }
                        }
                    }
                }
            }
            onClick = {
                setSelectedTab(tab)
            }
        }
    }

    private fun setSelectedTab(selectedTab: AdminTabs) {
        tabsToViews.forEach {
            if (it.key != selectedTab) {
                it.value.removeRuleSet(selectedTabRuleSet)
            }
        }
        tabsToViews[selectedTab]?.addRuleSet(selectedTabRuleSet)
    }

    private fun View.routeAdminComponent(tab: AdminTabs, block: (meta: RouteMeta) -> Component) =
        routeComponent(adminRoute(tab.routeDetails.href), isAbsolute = true, block = block)

    private fun adminRoute(tab: AdminTabs) = adminRoute(tab.routeDetails.href)
    private fun adminRoute(url: String) = "${routeDetails.href}$url"

    companion object {
        val routeDetails = BasePageViewModel.RouteDetails("/admin", "Admin".localized())
        private val defaultTab: AdminTabs get() {
            val tab = AdminTabs.subLists.map { it.tabs }.flatten().firstOrNull { isAuthorized(it.privileges.toList()) }
            return tab ?: AdminTabs.Staff
        }
        val selectedTabRuleSet by lazy {
            classRuleSet {
                backgroundColor = Color(235, 223, 223)
                borderStart = "4px solid ${AppColors.mainColor}"
            }
        }
    }
}
