package com.narbase.letsgo.web.routing.domain.admin.cartypes

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto

object GetCarTypesEndpoint : EndPoint<GetCarTypesEndpoint.Request, GetCarTypesEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
        val filter: Filter? = null
    )

    class Response(
        val list: Array<CarTypeDto>,
        val total: Int
    )

    class Filter(
        val zoneId: Int?
    )
}