package com.narbase.letsgo.web.views.incentives

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*

class IncentivesManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = IncentivesManagementViewModel()

    private var listTableBody: View? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getCurrencyIncentiveConfigInfo()
        viewModel.getOrderingIncentives()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.orderingIncentivesRmDto.forEachIndexed { index, item ->
                tableRow {
                    id = item.passengerProfileGroup.name
                    tableCell(item.passengerProfileGroup.name, 1, 14.px)
                    tableCell(3) {
                        verticalLayout {
                            item.orderingIncentives.forEach { incentive ->
                                horizontalLayout {
                                    incentive.orderingIncentive.timings.timings.forEach {
                                        theme.mediumTextView(this) {
                                            style { paddingEnd = 20.px }
                                            text = it.amount.toString() + " " + incentive.currency.name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    onClick = {
                        UpsertGroupOrderingIncentivesDialog(item, onUpdated = {
                            viewModel.getOrderingIncentives()
                        }).show()
                    }
                }

                if (index != viewModel.orderingIncentivesRmDto.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "IncentiveManagementRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }

                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Incentives Management".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()
                    theme.menuButton(this) {
                        text = "Timeout Incentives Settings"
                        onClick = {
                            viewModel.extendedCurrencyIncentiveConfigs.let { config ->
                                TimeoutIncentiveConfigDialog(config, onUpdated = {
                                    viewModel.getCurrencyIncentiveConfigInfo()
                                }).show()
                            }
                        }
                    }
                    horizontalFiller(16)
                    theme.menuButton(this) {
                        text = "Ordering Incentives Settings"
                        onClick = {
                            viewModel.extendedCurrencyIncentiveConfigs.let { config ->
                                OrderingIncentiveConfigDialog(config, onUpdated = {
                                    viewModel.getCurrencyIncentiveConfigInfo()
                                }).show()
                            }
                        }
                    }
//                    horizontalFiller(16)
//                    searchTextInput("Search".localized()) {
//                        viewModel.searchFor(it)
//                    }
                }

                verticalLayout {
                    matchParentDimensions()
                    withLoadingAndError(viewModel.getOrderingIncentivesUiState,
                        onRetryClicked = { viewModel.getOrderingIncentives() },
                        onLoaded = { onListLoaded() })

                    listTableBody = listTable {
                        headerCell("Name".localized(), 1)
                        headerCell("Incentives".localized(), 3)

                    }
                    paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
                }
            }
        }
    }
}