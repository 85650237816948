package com.narbase.letsgo.web.views.templates

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.dto.core.entries.CheckListOptionDto
import com.narbase.letsgo.dto.core.entries.McqOptionDto
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.utils.customRadio
import com.narbase.letsgo.web.utils.views.DelayBouncer
import com.narbase.letsgo.web.utils.views.customViews.Items
import com.narbase.letsgo.web.utils.views.customViews.setupCustomCheckBoxList
import kotlinx.dom.addClass


object EntriesViews {
    fun View?.separatorEntry(id: String = "") = view {
            this.id = id
            style {
                width = matchParent
                height = 1.px
                backgroundColor = Color(AppColors.borderColorHex)
                marginTop = 16.px
                marginBottom = 16.px
            }
        }



    fun View?.entryTitle(title: String, titleStyle: RuleSet?, id: String = "") = textView {
            this.id = id
            addRuleSet(titleStyle ?: EntriesStyles.titleStyle)
            text = title
        }


    fun View.textInputEntry(
            answer: String? = null,
            entryStyle: RuleSet?,
            id: String = "",
            isEditable: Boolean = true,
            onEditAnswer: (text: String) -> Unit
    ): View {
        return view {
            this.id = id
            addRuleSet(entryStyle ?: EntriesStyles.textInputStyle)
            element.innerText = answer ?: ""
            element.contentEditable = if (isEditable) "true" else "false"
            element.oninput = {
                DelayBouncer(400) { text: String ->
                    onEditAnswer(text)
                }.onInputChanged(element.innerText)
            }

            element.addClass("normalBar")
            style {
                height = wrapContent
            }

        }
    }

    fun View?.mcqEntry(
            selectedOption: Int? = null,
            options: List<McqOptionDto>,
            optionStyle: RuleSet?,
            textStyle: RuleSet?,
            groupName: String,
            id: String = "",
            isEditable: Boolean = true,
            onSelection: (answerId: Int) -> Unit
    ) {
        verticalLayout {
            style {
                width = matchParent
            }
            options.forEach { option ->
                val isSelected = option.id == selectedOption
                horizontalLayout {
                    addRuleSet(optionStyle ?: EntriesStyles.McqStyle)
                    style {
                        width = matchParent
                        alignItems = Alignment.Center
                    }
                    customRadio {
                        this.id = "${id}${option.id}"
                        element.disabled = isEditable.not()

                        isChecked = isSelected
                        element.name = groupName
                        onChange = { onSelection(option.id) }
                    }
                    textView {
                        addRuleSet(textStyle ?: EntriesStyles.McqTextStyle)
                        text = option.text
                    }
                }
            }
        }
    }

    fun View.checkListEntry(
            selectedOptions: MutableList<Int> = mutableListOf(),
            options: List<CheckListOptionDto>,
            textStyle: RuleSet?,
            slugId: String = "",
            isEditable: Boolean = true,
            onSelection: (selectedAnswers: Array<Int>) -> Unit
    ) {
        verticalLayout {
            setupCustomCheckBoxList(
                    list = options.map { option ->
                        Items(option, isSelected = selectedOptions.contains(option.id))
                    },
                    itemToString = { it.text },
                    onSelection = {
                        if (selectedOptions.contains(it.id)) {
                            selectedOptions.remove(it.id)
                        } else {
                            selectedOptions.add(it.id)
                        }
                        onSelection(selectedOptions.toTypedArray())
                    },
                    itemTextStyle = textStyle ?: EntriesStyles.CheckBoxTextStyle,
                    isEditable = isEditable,
                    itemId = { "${slugId}${it.id}" }
            )
        }
    }

}

object EntriesStyles {
    val titleStyle = classRuleSet {
        marginTop = 14.px
        color = Color.black
        fontSize = AppFontSizes.subtitle
    }

    val textInputStyle = classRuleSet {
        minWidth = 500.px
        marginTop = 8.px
        padding = 8.px
        color = Color.black
        border = "1px solid ${AppColors.borderColor}"
        borderRadius = 4.px
        overflow = "hidden"
        fontSize = 14.px
        focus {
            border = "1px solid ${AppColors.focusInputBorderColor}"
        }
    }

    val McqStyle = classRuleSet {
        margin = 8.px
        alignItems = Alignment.Center
    }

    val McqTextStyle = classRuleSet {
        marginStart = 10.px
        color = AppColors.black
        fontSize = AppFontSizes.normalText
    }

    val CheckBoxTextStyle = classRuleSet {
        color = Color.black
        fontSize = AppFontSizes.normalText
        marginStart = 10.px
    }
}
