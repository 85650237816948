package com.narbase.letsgo.web.routing.domain.admin.favoritepoints

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.favoritepoints.FavoritePointDto
object GetPassengerFavoritePointsEndpoint : EndPoint<GetPassengerFavoritePointsEndpoint.Request, GetPassengerFavoritePointsEndpoint.Response>() {
    class Request(
        val passengerId: Long,
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
    )

    class Response(
        val list: Array<FavoritePointDto>,
        val total: Int
    )
}