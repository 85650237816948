package com.narbase.letsgo.web.login

import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.network.DtoName
import com.narbase.letsgo.web.storage.StorageManager
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams

data class LoginReponseDto(
        val roles: Array<DtoName<AuthorizedRole>>, val clinicType: ClinicType, val isFirstLogin: Boolean
)

enum class ClinicType {
    Dental {
        override fun toString() = "Dental"
    }
}


val chainSlug: String?
    get() {
        val stored = URLSearchParams(window.location.search).get("chain_slug")
                ?: StorageManager.chainSlug?.takeUnless { it.isBlank() }
        StorageManager.chainSlug = stored
        return stored
    }
