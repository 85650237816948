package com.narbase.letsgo.web.routing.domain.admin.currencyincentiveconfigs

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.config.ExtendedCurrencyIncentiveConfigDto

object UpsertCurrencyIncentiveConfigsEndpoint : EndPoint<UpsertCurrencyIncentiveConfigsEndpoint.Request, UpsertCurrencyIncentiveConfigsEndpoint.Response>() {
    class Request(
        val extendedCurrencyIncentiveConfigDto: List<ExtendedCurrencyIncentiveConfigDto>
    )

    class Response()
}