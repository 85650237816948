package com.narbase.letsgo.web.utils.views.customViews

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.colors.gray
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.PopUpDialog
import com.narbase.letsgo.web.utils.views.popUpDialog

fun View.initWarningPopupDialog() {
    showWarningPopUpDialog = popUpDialog { }
}

private var showWarningPopUpDialog: PopUpDialog? = null
fun showPopupMessage(popupTitle: String,
                     warning: String,
                     image: String? = null,
                     imageSize: Int = 200,
                     positiveText: String,
                     onPositiveClicked: () -> Unit,
                     addCancelButton: Boolean = true,
                     cancelText: String = "Dismiss",
                     onCancelClicked: (() -> Unit)? = null): PopUpDialog? {
    if (showWarningPopUpDialog == null) {
        console.log("Call initWarningPopupDialog() before using showText")
        return showWarningPopUpDialog
    }
    val dialog = showWarningPopUpDialog ?: return null
    theme.showDialog(dialog) { dialogBuilder ->
        verticalLayout {
            style {
                backgroundColor = Color.white
                borderRadius = 12.px
                padding = 16.px
                width = 500.px
                alignSelf = Alignment.Center
                alignItems = Alignment.Center
                justifyContent = JustifyContent.SpaceAround
            }

            imageView {
                style {
                    alignSelf = Alignment.Center
                    width = imageSize.px
                    objectFit = "cover"
                    marginBottom = 16.px
                }

                element.src = image ?: return@imageView
            }


            textView {
                style {
                    marginBottom = 12.px
                    fontWeight = "bold"
                    fontSize = 16.px
                    textAlign = TextAlign.Center
                }
                text = popupTitle
            }

            textView {
                style {
                    marginBottom = 16.px
                    fontSize = 14.px
                    textAlign = TextAlign.Center
                }
                text = warning
            }

            dialogBuilder.bottomBar = {
                horizontalLayout {
                    style {
                        width = matchParent
                        height = wrapContent
                        justifyContent = JustifyContent.End
                    }

                    if (addCancelButton) {
                        theme.outlineButton(this) {
                            id = "dismissWarningButton"
                            text = cancelText.localized()
                            onClick = {
                                onCancelClicked?.invoke()
                                showWarningPopUpDialog?.dismissDialog()
                            }
                        }
                    }

                    horizontalFiller(20)
                    theme.mainButton(this) {
                        id = "confirmButton"
                        text = positiveText
                        onClick = {
                            onPositiveClicked()
                            showWarningPopUpDialog?.dismissDialog()
                        }
                    }
                }

            }
        }
    }

    return showWarningPopUpDialog
}

object Styles {
    val buttonStyle = classRuleSet {
        padding = "4px 12px".dimen()
        border = "1px solid ${AppColors.borderColorHex}"
        borderRadius = 18.px
        backgroundColor = gray(0.97)
        cursor = "pointer"
        fontSize = 14.px
    }
    val confirmButtonStyle = classRuleSet {
        padding = "4px 12px".dimen()
        border = "1px solid ${AppColors.mainColor}"
        borderRadius = 18.px
        backgroundColor = AppColors.mainColor
        color = Color.white
        cursor = "pointer"
        fontSize = 14.px
    }
}
