package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint

object RateRideAdminEndpoint : EndPoint<RateRideAdminEndpoint.Request, RateRideAdminEndpoint.Response>() {
    class Request(
        val rideId: Long,
        val rating: Double,
        val review: String,
    )

    class Response()
}