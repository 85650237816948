package com.narbase.letsgo.web.views.admin.auditlog

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.admin.ExtendedAuditLogDto
import com.narbase.letsgo.web.network.UnknownErrorException
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.auditlog.GetAuditLogEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.DataResponse

class AuditLogViewModel(private val subjectId: String?) {

    var pageNo = 0
    var pageSize = 100
    var total = 0
    var searchTerm: String? = null

    var list: List<ExtendedAuditLogDto> = listOf()
    val getUiState = Observable<BasicUiState>()
    val upsertUiState = Observable<BasicUiState>()
    val deleteUiState = Observable<BasicUiState>()

    fun getList() {
        upsertUiState.value = null
        basicNetworkCall(getUiState) {
            val dto = GetAuditLogEndpoint.Request(pageNo, pageSize, subjectId)
            val response = GetAuditLogEndpoint.remoteProcess(dto)
            if (response.status?.toInt() != DataResponse.BASIC_SUCCESS) {
                throw UnknownErrorException()
            }
            list = response.data.list.toList()
            total = response.data.total
        }

    }


    fun getNextPage() {
        pageNo++
        getList()
    }

    fun getPreviousPage() {
        pageNo--
        getList()
    }

    fun searchFor(searchTerm: String) {
        pageNo = 0
        this.searchTerm = searchTerm
        getList()
    }

}
