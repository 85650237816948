package com.narbase.letsgo.web.views.callCenter.rating

import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.*

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/06/10.
 */
class RateRideDialog(
    private val onRateClicked: (Double, String) -> Unit
) {
    private val popup by lazy { popUpDialog() }
    private val selectedRating: Observable<Double> = Observable()
    private var reviewTextArea : TextArea? = null

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Rate".localized()
                        onClick = {
                            val rating = selectedRating.value
                            val review =  reviewTextArea?.text
                            if (rating == null){
                                SnackBar.showText("Rating is required".localized())
                            } else if (review.isNullOrBlank()){
                                SnackBar.showText("Review is required".localized())
                            } else {
                                onRateClicked.invoke(rating, reviewTextArea?.text ?: "")
                                popup.dismissDialog()
                            }
                        }
                    }

                }
            }

            verticalLayout {
                style {
                    width = matchParent
                    padding = 15.px
                }
                selectedRating.observe {
                    clearAllChildren()
                    val currentRating = selectedRating.value ?: 0.0
                    theme.label(this, "Rating: ".localized(), true)
                    mount(
                        RatingComponent(currentRating){
                            selectRating(it)
                        }
                    )
                    theme.label(this, "Review: ".localized(), true)
                    reviewTextArea = theme.textArea(this){
                        style {
                            width = matchParent
                        }
                        placeholder = "review...".localized()
                    }
                }

            }
        }
    }

    private fun selectRating(newRating: Double) {
        selectedRating.value = newRating
    }
}
