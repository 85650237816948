package com.narbase.letsgo.web.views.admin.drivers

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.core.KmmLong
import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.dto.admin.AdminUpsertDto
import com.narbase.letsgo.web.dto.admin.ExtendedAdminDto
import com.narbase.letsgo.web.dto.drivers.ExtendedDisplayDriverDto
import com.narbase.letsgo.web.dto.drivers.UpsertDriverDto
import com.narbase.letsgo.web.network.UnknownErrorException
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.drivers.GetDriversListEndpoint
import com.narbase.letsgo.web.routing.domain.admin.drivers.UpsertDriverEndpoint
import com.narbase.letsgo.web.routing.domain.admin.staff.GetAdminsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.staff.UpsertAdminsEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.DataResponse

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/28.
 */
class DriversManagementViewModel {

    private var filterList = listOf(AuthorizedRole.Admin, AuthorizedRole.Doctor, AuthorizedRole.Receptionist)
    private var searchTerm = ""
    val uiState = Observable<BasicUiState>()
    val upsertUiState = Observable<BasicUiState>()

    var data: List<ExtendedDisplayDriverDto> = listOf()

    var pageNo = 0
    var pageSize = 20
    var total = 0

    fun getList() {
        upsertUiState.value = null
        basicNetworkCall(uiState) {
            val dto = GetDriversListEndpoint.Request(pageNo, pageSize, searchTerm)

            val response = GetDriversListEndpoint.remoteProcess(dto).data
            data = response.list.toList()
            total = response.total
        }
    }


    fun searchFor(term: String) {
        pageNo = 0
        searchTerm = term
        uiState.value = BasicUiState.Loaded
        getList()
    }

    fun getNextPage() {
        pageNo++
        getList()
    }

    fun getPreviousPage() {
        pageNo--
        getList()
    }

    fun upsertDriver(updateDto: UpsertDriverDto) {
        basicNetworkCall(upsertUiState) {
            val dto = UpsertDriverEndpoint.Request(updateDto)
            val response = UpsertDriverEndpoint.remoteProcess(dto)
            if (response.status != "${DataResponse.BASIC_SUCCESS}") {
                throw UnknownErrorException()
            }
        }

    }

}
