package com.narbase.letsgo.web.views.admin.roles

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.data.Privilege
import com.narbase.letsgo.web.common.data.displayName
import com.narbase.letsgo.web.dto.admin.AdminPrivilegeListDto
import com.narbase.letsgo.web.dto.admin.DynamicRoleDto
import com.narbase.letsgo.web.dto.admin.privileges
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/07/03.
 */
class RolesManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = RolesManagementViewModel()
    private var privilegesDropDownListVm: MultiSelectionDropDownListViewModel<Privilege>? = null

    //    private val upsertDialog = UpsertStaffMemberDialog(viewModel, showCurrent)
    private val popup by lazy { popUpDialog() }

    private var listTableBody: View? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getList()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.data.forEachIndexed { index, item ->
                tableRow {
                    id = item.name
                    tableCell(item.name, 3, 16.px)

                    onClick = {
                        upsertDialog(item)
                    }
                }

                if (index != viewModel.data.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "staffManagementRootView"

        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }
                withLoadingAndError(viewModel.uiState, onRetryClicked = {
                    viewModel.getList()
                }, onLoaded = {
                    onListLoaded()
                })

                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Roles".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()

                    addItemButton()

                }

                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }

                    horizontalFiller()
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                listTableBody = listTable {
                    headerCell("Role".localized(), 3)
                }
                paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
            }
        }
    }


    private fun LinearLayout.addItemButton() {
        textView {
            style {
                color = Color.white
                padding = "2px 12px".dimen()
                backgroundColor = AppColors.mainColor
                borderRadius = 12.px
                pointerCursor()
                hover {
                    backgroundColor = AppColors.mainDarkColor
                }
            }

            onClick = {
                upsertDialog()
            }

            id = "AddRoleButton"
            text = "+ Add new role"
        }
    }

    private var upsertNameField: TextInput? = null
    private fun upsertDialog(dto: DynamicRoleDto? = null) {
        val rolePrivileges = dto?.privileges?.toMutableList() ?: mutableListOf()
        viewModel.setUpsertUiStateToNull()
        theme.showDialog(popup) {

            verticalLayout {
                style {
                    height = wrapContent
                    width = 70.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                textView {
                    style {
                        fontWeight = "bold"
                        marginBottom = 8.px
                        fontSize = 16.px
                    }
                    text = if (dto == null) "Add role".localized() else "Edit role".localized()
                }

                val nameInput = theme.labeledTextInput(this, "Name".localized())

                verticalFiller(8)

                privilegesListLayout(rolePrivileges)

                verticalFiller(8)


                nameInput.text = dto?.name ?: ""


                upsertNameField = nameInput
                val errorText = textView {
                    style {
                        marginBottom = 8.px
                        fontSize = 14.px
                        color = AppColors.redLight
                    }
                    isVisible = false
                    text = "Please enter valid fields values".localized()
                }
                verticalFiller(10)

                it.bottomBar = {
                    horizontalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
                            justifyContent = JustifyContent.End
                            marginTop = 10.px
                        }

                        theme.mainButton(this) {

                            text = "Save".localized()
                            onClick = {
                                onSaveButtonClicked(
                                        dto,
                                        privilegesDropDownListVm?.selectedItems ?: listOf(),
                                        errorText
                                )
                            }

                            withLoadingAndError(viewModel.upsertUiState,
                                    onRetryClicked = {
                                        onSaveButtonClicked(
                                                dto,
                                                privilegesDropDownListVm?.selectedItems ?: listOf(),
                                                errorText
                                        )
                                    },
                                    onLoaded = {
                                        popup.dismissDialog()
                                        viewModel.getList()
                                    }
                            )
                        }

                    }

                }

            }
        }
        upsertNameField?.element?.focus()
    }

    private fun View.privilegesListLayout(rolePrivileges: List<Privilege>) = horizontalLayout {
        style {
            width = matchParent
            height = wrapContent
            marginBottom = 16.px
            alignItems = Alignment.Center
        }

        privilegesDropDownListVm = MultiSelectionDropDownListViewModel(
                itemToId = { it.name },
                getRemoteItems = { _, searchTerm ->
                    val list = Privilege.values()
                    val filteredList = if (searchTerm.isNotBlank()) {
                        list.filter {
                            it.displayName.contains(searchTerm, ignoreCase = true)
                        }.toTypedArray()
                    } else list
                    appendItems(filteredList, endOfList = true)
                }
        )

        setupRemoteMultiSelectionDropDownList(
                name = "Privileges".localized(),
                viewModel = privilegesDropDownListVm!!,
                itemToString = {
                    it.displayName
                },
                showAutoComplete = true,
                rootStyle = classRuleSet {
                    width = weightOf(1)
                },
                defaultItems = rolePrivileges,
                isDisabled = false
        )
    }


    private fun onSaveButtonClicked(
            dto: DynamicRoleDto?,
            privileges: List<Privilege>,
            errorText: TextView
    ) {
        val nameInput = upsertNameField ?: return

        if (nameInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid name".localized()
            return
        }

        makeNotVisible(errorText)
        val name = nameInput.text.trim()

        if (dto == null) {
            viewModel.addItem(DynamicRoleDto(null, null, name, AdminPrivilegeListDto(privileges.map { it.dtoName }.toTypedArray())))
        } else {
            viewModel.editItem(DynamicRoleDto(dto.id, null, name, AdminPrivilegeListDto(privileges.map { it.dtoName }.toTypedArray())))
        }
    }

}