package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.LifecycleObserver
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.storage.Translations
import com.narbase.letsgo.web.utils.BasicUiState

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2019/05/13.
 */
@Suppress("unused", "MemberVisibilityCanBePrivate")
open class NetworkAwareWrapper(
        private val errorTextViewStyle: RuleSet = Styles.errorTextViewStyle,
        private val loadingIndicatorStyle: RuleSet = Styles.loadingIndicatorStyle,
        private val retryOnError: Boolean = true,
        private val hideViewOnError: Boolean = true,
        var getErrorMsg: (() -> String?)? = null
) {

    var onRetryClicked: () -> Unit = { console.log("No retry callback is setup") }
    var view: View? = null
    var parent: View? = null
    var component: Component? = null
    private var componentHeight = 0

    open val loadingIndicator by lazy {
        detached.imageView {
            id = "loading"
            isVisible = false
            element.src = "/public/img/loading.gif"
            addRuleSet(loadingIndicatorStyle)
        }
    }

    open val errorTextView by lazy {
        detached.textView {
            isVisible = false
            addRuleSet(errorTextViewStyle)
            onClick = {
                showLoading()
                if (retryOnError) onRetryClicked()
            }
        }
    }

    fun initialize(view: View, startWithLoading: Boolean = false) {
        this.view = view
        val parent = view.parent ?: return
        this.parent = parent
        parent.mountBefore(errorTextView, view)
        parent.mountAfter(loadingIndicator, view)
        bindParentToSaveItsHeight(parent)
        saveParentHeight()
        view.isVisible = startWithLoading.not()
        loadingIndicator.isVisible = startWithLoading
    }

    private fun bindParentToSaveItsHeight(parent: View) {
        parent.bind(object : LifecycleObserver {
            override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
                super.onViewMounted(lifecycleOwner)
                saveParentHeight()
            }
        })
    }

    fun initialize(component: Component, startWithLoading: Boolean = false) {
        val view = component.rootView
                ?: return console.log("NetworkAwareComponent is called but component is not mounted. Make sure component is mounted first")
        initialize(view, startWithLoading)
    }

    fun showView() {
        hideAll()
        makeVisible(view)
        component?.let { parent?.mountAfter(it, loadingIndicator) }
        saveParentHeight()
    }

    private fun saveParentHeight() {
        componentHeight = parent?.element?.offsetHeight ?: componentHeight
    }

    fun showLoading() {
        hideAll()
        if (componentHeight > 0) {
            loadingIndicator.element.style.maxHeight = "${componentHeight}px"
            loadingIndicator.element.style.maxWidth = "${componentHeight}px"
        }
        makeVisible(loadingIndicator)
    }


    fun showError(text: String = Translations.networkErrorWithRetry) {
        hideAll()
        if (hideViewOnError.not()) {
            makeVisible(view)
        }
        if (retryOnError) errorTextView.text = text else errorTextView.text = Translations.networkError
        makeVisible(errorTextView)
    }

    private fun hideAll() {
        makeNotVisible(view, loadingIndicator, errorTextView)
        component?.let { parent?.unMount(it) }
    }

    fun bind(uiStateObservable: Observable<BasicUiState>, onLoaded: () -> Unit) {
        uiStateObservable.observe { onUiStateUpdated(it, onLoaded) }
    }

    fun onUiStateUpdated(uiState: BasicUiState?, onLoaded: () -> Unit) {
        when (uiState) {
            BasicUiState.Loading -> showLoading()
            BasicUiState.Loaded -> {
                showView()
                try {
                    onLoaded()
                } catch (e: Exception) {
                    console.log(e)
                }
            }
            BasicUiState.Error -> {
                val msg: String? = getErrorMsg?.invoke()
                if (msg != null)
                    showError(msg)
                else
                    showError()
            }
        }
    }


    object Styles {
        val errorTextViewStyle = classRuleSet {
            alignSelf = Alignment.Center
            color = AppColors.redLight
            margin = 18.px
            pointerCursor()
        }

        val loadingIndicatorStyle = classRuleSet {
            width = 40.px
            height = 40.px
            alignSelf = Alignment.Center
            margin = 18.px
        }
        val smallLoadingStyle = classRuleSet {
            width = 20.px
            height = 20.px
            alignSelf = Alignment.Center
        }
    }
}

fun View.withLoadingAndError(
        uiState: Observable<BasicUiState>,
        startWithLoading: Boolean = false,
        errorTextViewStyle: RuleSet = NetworkAwareWrapper.Styles.errorTextViewStyle,
        loadingIndicatorStyle: RuleSet = NetworkAwareWrapper.Styles.loadingIndicatorStyle,
        onRetryClicked: () -> Unit,
        onLoaded: () -> Unit,
        retryOnError: Boolean = true,
        hideViewOnError: Boolean = true,
        getErrorMsg: (() -> String?)? = null

) {
    val wrapper = NetworkAwareWrapper(errorTextViewStyle, loadingIndicatorStyle, retryOnError, hideViewOnError, getErrorMsg)
    wrapper.onRetryClicked = onRetryClicked
    wrapper.initialize(this, startWithLoading)
    wrapper.bind(uiState, onLoaded)
}

fun View.withLoadingAndError(
        uiState: Observable<BasicUiState>,
        startWithLoading: Boolean = false,
        loadingIndicatorSize: Int = 40,
        onRetryClicked: () -> Unit,
        onLoaded: () -> Unit,
        retryOnError: Boolean = true,
        hideViewOnError: Boolean = true,
        getErrorMsg: (() -> String?)? = null

) {
    val loadingIndicatorStyle = classRuleSet {
        width = loadingIndicatorSize.px
        height = loadingIndicatorSize.px
        alignSelf = Alignment.Center
    }

    val wrapper = NetworkAwareWrapper(NetworkAwareWrapper.Styles.errorTextViewStyle, loadingIndicatorStyle, retryOnError, hideViewOnError, getErrorMsg)
    wrapper.onRetryClicked = onRetryClicked
    wrapper.initialize(this, startWithLoading)
    wrapper.bind(uiState, onLoaded)
}

