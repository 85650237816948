package com.narbase.letsgo.web.routing.domain.admin.passengerprofilegroup

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengerprofilegroup.ExtendedPassengerProfileGroupDto

object GetPassengerProfileGroupsEndpoint : EndPoint<GetPassengerProfileGroupsEndpoint.Request, GetPassengerProfileGroupsEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
    )

    class Response(
        val list: Array<ExtendedPassengerProfileGroupDto>,
        val total: Int
    )
}