package com.narbase.letsgo.web.common.data

import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.string.splitCamelCase


typealias Privilege = com.narbase.letsgo.models.roles.AdminPrivilege

val Privilege.displayName: String
    get() = when (this) {
        Privilege.DriversAccess -> "Drivers Access".localized()
        Privilege.DynamicRoles -> "Dynamic Roles Management".localized()
        else -> name.splitCamelCase()

    }

