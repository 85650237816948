package com.narbase.letsgo.web.routing.domain.admin.currencyincentiveconfigs

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.config.ExtendedCurrencyIncentiveConfigDto
object GetCurrencyIncentiveConfigsEndpoint : EndPoint<GetCurrencyIncentiveConfigsEndpoint.Request, GetCurrencyIncentiveConfigsEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
    )

    class Response(
        val list: Array<ExtendedCurrencyIncentiveConfigDto>,
        val total: Int
    )
}
