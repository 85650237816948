package com.narbase.letsgo.web.events

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/08/30.
 */

class OnMouseUpEvent
class OnMouseDownEvent
class OnStorageUpdatedEvent(val key: String?)