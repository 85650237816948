@file:Suppress("JoinDeclarationAndAssignment", "MemberVisibilityCanBePrivate")

package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.appFontFamily
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.utils.PopupZIndex
import com.narbase.letsgo.web.utils.colors.gray
import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.css.ElementCSSInlineStyle
import kotlin.js.Date

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2018] Narbase Technologies
 * All Rights Reserved.
 * Created by ${user}
 * On: ${Date}.
 */
class DatePicker(
        field: HTMLInputElement? = null,
        trigger: HTMLElement? = null,
        container: HTMLElement? = null,
        onSelect: ((Date) -> Unit)? = null,
        bound: Boolean = false,
        theme: String,
        defaultDate: Date = Date(),
        i18n: I18n? = null

) {
    val params: dynamic
    val picker: Pikaday

    init {
        refreshTheme(theme)
        params = object {}
        field?.let { params["field"] = it }
        trigger?.let { params["trigger"] = it }
        onSelect?.let { params["onSelect"] = it }
        params["onOpen"] = {
            updateZIndexForCalendar()
        }
        container?.let { params["container"] = it }
        bound.let { params["bound"] = it }
        theme.let { params["theme"] = it }
        defaultDate.let { params["defaultDate"] = it }
        i18n.let { params["i18n"] = it }
        params["yearRange"] = arrayOf(1900, 2099)
        params["keyboardInput"] = false
        picker = Pikaday(params)
    }

    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    private fun updateZIndexForCalendar() =
            (document.querySelector(":root") as? ElementCSSInlineStyle)?.style?.setProperty(PIKADAY_Z_INDEX_VAR, "${PopupZIndex.getTopIndex()}")

    fun refreshTheme(theme: String?) {
        when (theme) {
            "dark-theme" -> createDarkThemeStyles()
            "light-theme" -> createLightThemeStyles()
        }
    }

    fun createDarkThemeStyles() {
        try {
            stringRuleSet(".dark-theme.pika-single") {
                borderRadius = 4.px
                border = "1px solid ${AppColors.lightDarkBackground}"
                backgroundColor = AppColors.lightDarkBackground
                fontFamily = appFontFamily
                width = matchParent
                zIndex = PopupZIndex.getTopIndex()
                color = AppColors.white
            }
            stringRuleSet(".dark-theme .pika-lendar") {
                width = wrapContent
                float = "none"
                color = AppColors.white
                backgroundColor = AppColors.lightDarkBackground
            }
            stringRuleSet(".dark-theme .pika-next") {
                backgroundImage = "url(/public/img/next.png)"
            }

            stringRuleSet(".dark-theme .pika-prev") {
                backgroundImage = "url(/public/img/next.png)"
                transform = "rotate(180deg)"
            }
            stringRuleSet(".dark-theme.pika-title") {
                borderBottom = "thin solid ${AppColors.lightDarkSeparator}"
                backgroundColor = AppColors.lightDarkBackground
            }
            stringRuleSet(".dark-theme .pika-label") {
                fontWeight = "normal"
                color = AppColors.white
                backgroundColor = AppColors.lightDarkBackground
            }
            stringRuleSet(".dark-theme .pika-table th") {
                fontWeight = "medium"
                color = AppColors.white
                backgroundColor = AppColors.lightDarkBackground
            }
            stringRuleSet(".dark-theme .pika-table abbr") {
                textDecoration = "none"
                color = AppColors.white
                backgroundColor = AppColors.lightDarkBackground
            }
            stringRuleSet(".dark-theme .pika-button") {
                backgroundColor = Color.white
                textAlign = TextAlign.Center
                borderColor = Color("31c1c3")
                width = 20.px
                height = 20.px
                fontSize = AppFontSizes.extraSmallText
                padding = "unset".dimen()
                color = AppColors.white
                backgroundColor = AppColors.lightDarkBackground
            }
            stringRuleSet(".dark-theme .pika-button:hover") {
                backgroundColor = gray(0.97)
                borderRadius = 50.percent
                boxShadow = "0px 0px #31c1c3"
                color = AppColors.black
            }
            stringRuleSet(".dark-theme .is-selected .pika-button") {
                backgroundColor = Color("31c1c3")
                boxShadow = "0px 0px #31c1c3"
                borderRadius = 50.percent
                borderColor = Color.white
            }
            stringRuleSet(".dark-theme .is-selected .pika-button:hover") {
                color = Color.white
            }
            stringRuleSet(".dark-theme .is-today .pika-button") {
                border = "1px solid ${AppColors.white}"
                borderRadius = 50.percent
                color = AppColors.white
            }
        } catch (e: dynamic) {
            console.log(e)
        }
    }

    fun createLightThemeStyles() {
        try {
            stringRuleSet(".pika-single.light-theme") {
                borderRadius = 4.px
                border = "1px solid ${AppColors.borderColor}"
                backgroundColor = AppColors.white
                fontFamily = appFontFamily
//                zIndex = PopupZIndex.getTopIndex()
                color = AppColors.black
                this["z-index"] = "var($PIKADAY_Z_INDEX_VAR)"
//                this["z-index"] = "10050 !important"
            }
            stringRuleSet(".light-theme .pika-lendar") {
                color = AppColors.white
                backgroundColor = AppColors.white
            }
            stringRuleSet(".light-theme .pika-next") {
                backgroundImage = "url(/public/img/bread.png)"
            }

            stringRuleSet(".light-theme .pika-prev") {
                backgroundImage = "url(/public/img/bread.png)"
                transform = "rotate(180deg)"
            }
            stringRuleSet(".light-theme .pika-title") {
                borderBottom = "thin solid ${AppColors.borderColor}"
                backgroundColor = AppColors.white
            }
            stringRuleSet(".light-theme .pika-label") {
                fontWeight = "normal"
                color = AppColors.black
                backgroundColor = AppColors.white
            }
            stringRuleSet(".light-theme .pika-table th") {
                fontWeight = "medium"
                color = AppColors.black
                backgroundColor = AppColors.white
            }
            stringRuleSet(".light-theme .pika-table abbr") {
                textDecoration = "none"
                color = AppColors.black
                backgroundColor = AppColors.white
            }
            stringRuleSet(".light-theme .pika-button") {
                backgroundColor = Color.white
                textAlign = TextAlign.Center
                borderColor = Color("31c1c3")
                width = 20.px
                height = 20.px
                fontSize = AppFontSizes.extraSmallText
                padding = "unset".dimen()
                color = AppColors.black
            }
            stringRuleSet(".light-theme .pika-button:hover") {
                backgroundColor = gray(0.97)
                borderRadius = 50.percent
                boxShadow = "0px 0px #31c1c3"
                color = AppColors.black
            }
            stringRuleSet(".light-theme .is-selected .pika-button") {
                backgroundColor = Color("31c1c3")
                boxShadow = "0px 0px #31c1c3"
                borderRadius = 50.percent
                borderColor = Color.white
                color = Color.white
            }
        } catch (e: dynamic) {
            console.log(e)
        }
    }

    companion object {
        private const val PIKADAY_Z_INDEX_VAR = "--pikaday-z-index"
    }
}

class I18n(
        val previousMonth: String = "Previous Month",
        val nextMonth: String = "Next Month",
        val months: Array<String>,
        val weekdays: Array<String>,
        val weekdaysShort: Array<String>
)

@JsModule("pikaday")
@JsNonModule
external class Pikaday(params: dynamic) {
    fun setDate(date: Date?, preventOnSelect: Boolean): Unit
    fun setMaxDate(date: Date): Unit
    fun setMinDate(date: Date): Unit
}

fun View?.datePicker(fieldElement: HTMLInputElement? = null,
                     containerElement: HTMLElement? = null,
                     theme: String,
                     bound: Boolean = false,
                     onDateSelected: (date: Date) -> Unit
): DatePicker {
    return DatePicker(
            fieldElement,
            onSelect = {
                onDateSelected(it)
            },
            container = containerElement,
            bound = bound,
            theme = theme,
            i18n = I18n(
                    months = arrayOf("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"),
                    weekdays = arrayOf("Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"),
                    weekdaysShort = arrayOf("S", "M", "T", "W", "T", "F", "S")
            )
    )
}


fun View.datePickerCloseIcon(
        onCleared: () -> Unit
) =
        materialIcon("close") {
            style {
                color = AppColors.textDarkGrey
                position = "absolute"
                top = 8.px
                end = 8.px
                pointerCursor()
                hover {
                    color = AppColors.redLight
                }
            }
            isVisible = false
            size = MaterialIcon.md18
            onClick = {
                it.stopPropagation()
                isVisible = false
                onCleared()
            }
        }

