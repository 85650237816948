package com.narbase.letsgo.web.routing.domain.admin.incentives

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.config.ConfigDto
object UpsertConfigEndpoint : EndPoint<UpsertConfigEndpoint.Request, UpsertConfigEndpoint.Response>() {
    class Request(
        val config: ConfigDto
    )

    class Response()
}