package com.narbase.letsgo.web.views.passengerProfileGroups

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto
import com.narbase.letsgo.web.dto.passengerprofilegroup.ExtendedPassengerProfileGroupDto
import com.narbase.letsgo.web.dto.passengerprofilegroup.PassengerProfileGroupDto
import com.narbase.letsgo.web.network.*
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.admin.utils.ColorPicker

class PassengerProfileGroupsManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = PassengerProfileGroupsManagementViewModel()
    private var isCommissionPercentageDropDown: DropDownList<String>? = null
    private var toBeReplacedTypeDropDownList: RemoteDropDownList<CarTypeDto>? = null
    private var toBeUsedTypeDropDownList: RemoteDropDownList<CarTypeDto>? = null
    val isHiddenFromCallCenterSelected: Observable<Boolean> = Observable()


    private val popup by lazy { popUpDialog() }

    private var listTableBody: View? = null

    private val isPercentageArray = arrayOf("Percentage", "Static Value")
    private var newIsCommissionPercentage = false
    private var newToBeReplacedCarType: CarTypeDto? = null
    private var newToBeUsedCarType: CarTypeDto? = null
    private var newColor: String? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getList()
        viewModel.getCarTypesList("")
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.data.forEachIndexed { index, item ->
                tableRow {
                    id = item.passengerProfileGroup.name
                    tableCell(item.passengerProfileGroup.name, 3, 16.px)
                    tableCell(item.passengerProfileGroup.commission.toString(), 3, 16.px)
                    tableCell(
                        item.passengerProfileGroup.isCommissionPercentage.toIsCommissionPercentageString(),
                        3,
                        16.px
                    )
                    tableCell({ weightOf(3) }) {
                        view {
                            style {
                                width = 28.px
                                height = 28.px
                                border = "1px solid ${AppColors.borderColor}"
                                backgroundColor =
                                    item.passengerProfileGroup.color?.let { Color(it) } ?: Color.transparent
                                borderRadius = 4.px
                                margin = 2.px
                            }
                        }
                    }
                    tableCell(
                        item.toBeReplacedCarType?.nameEn ?: "",
                        3,
                        16.px
                    )
                    tableCell(
                        item.toBeUsedCarType?.nameEn ?: "",
                        3,
                        16.px
                    )

                    onClick = {
                        upsertDialog(item)
                    }
                }

                if (index != viewModel.data.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "passengerProfileGroupsManagementRootView"

        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }
                withLoadingAndError(viewModel.carTypesUiState, onRetryClicked = {
                    viewModel.getCarTypesList("")
                }, onLoaded = {
                    viewModel.getList()
                })
                withLoadingAndError(viewModel.uiState, onRetryClicked = {
                    viewModel.getList()
                }, onLoaded = {
                    onListLoaded()
                })


                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Passenger Profile Groups".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()
                    addItemButton()

                }

                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }

                    horizontalFiller()
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                listTableBody = listTable {
                    headerCell("Name".localized(), 3)
                    headerCell("Commission".localized(), 3)
                    headerCell("Commission in".localized(), 3)
                    headerCell("Color".localized(), 3)
                    headerCell("Car Type to be Replaced".localized(), 3)
                    headerCell("Car Type to be Used".localized(), 3)

                }
                paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
            }
        }
    }

    private fun LinearLayout.isHiddenFromCallCenterSwitch(
        isEnabled: Boolean,
        isEnabledSelected: Observable<Boolean>
    ): ToggleSwitch {
        val switch = ToggleSwitch()
        horizontalLayout {
            switch.resetIsSelected()
            switch.isSelected.value = isEnabled
            switch.isSelected.observe {
                if (it != null) {
                    isEnabledSelected.value = it
                }
            }
            theme.label(this, "Hide from call center", false)
            mount(switch)
        }
        return switch
    }



    private fun LinearLayout.addItemButton() {
        theme.mainButton(this) {
            id = "AddGroupButton"
            onClick = {
                upsertDialog()
            }
            text = "+ Add new group"
        }
    }

    private var upsertNameField: TextInput? = null
    private var upsertCommissionField: TextInput? = null
    private fun upsertDialog(dto: ExtendedPassengerProfileGroupDto? = null) {
        viewModel.setUpsertUiStateToNull()
        theme.showDialog(popup) {

            verticalLayout {
                style {
                    height = wrapContent
                    width = 70.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                textView {
                    style {
                        fontWeight = "bold"
                        marginBottom = 8.px
                        fontSize = 16.px
                    }
                    text =
                        if (dto == null) "Add Passenger Profile Group".localized() else "Edit Passenger Profile Group".localized()
                }

                val nameInput = theme.labeledTextInput(this, "Name".localized(), true)
                val commissionInput = theme.labeledTextInput(this, "Commission".localized(), true)
                commissionInput.element.type = "number"
                commissionInput.element.placeholder = "0.0"
                verticalFiller(8)
                theme.label(this, "in:", true)
                isCommissionPercentageDropDown = setupDropDownList(
                    name = "",
                    list = isPercentageArray,
                    itemToString = { it },
                    defaultValue = dto?.passengerProfileGroup?.isCommissionPercentage.toIsCommissionPercentageString(),
                    onItemSelected = {
                        newIsCommissionPercentage = it == isPercentageArray[0]
                    },
                )
                verticalFiller(8)
                theme.label(this, "Color:", false)
                mount(ColorPicker(defaultColor = dto?.passengerProfileGroup?.color?.let { it1 -> Color(it1) }
                    ?: Color.transparent) {
                    newColor = it.toString()
                })
                verticalFiller(8)

                theme.label(this, "Car type to be replaced: ", false)
                toBeReplacedTypeDropDownList = carTypesDropDown(dto?.toBeReplacedCarType) {
                    newToBeReplacedCarType = it
                }
                verticalFiller(8)
                theme.label(this, "Car type to be used: ", false)
                toBeUsedTypeDropDownList = carTypesDropDown(dto?.toBeUsedCarType) { newToBeUsedCarType = it }
                verticalFiller(8)

                isHiddenFromCallCenterSwitch(dto?.passengerProfileGroup?.isHiddenFromCallCenter ?: true, isHiddenFromCallCenterSelected)
                verticalFiller(12)

                nameInput.text = dto?.passengerProfileGroup?.name ?: ""
                commissionInput.text = dto?.passengerProfileGroup?.commission.toString()



                upsertNameField = nameInput
                upsertCommissionField = commissionInput
                val errorText = textView {
                    style {
                        marginBottom = 8.px
                        fontSize = 14.px
                        color = AppColors.redLight
                    }
                    isVisible = false
                    text = "Please enter valid fields values".localized()
                }
                verticalFiller(10)

                it.bottomBar = {
                    horizontalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
                            justifyContent = JustifyContent.End
                            marginTop = 10.px
                        }

                        theme.mainButton(this) {

                            text = "Save".localized()
                            onClick = {
                                onSaveButtonClicked(
                                    dto?.passengerProfileGroup, errorText
                                )
                            }

                            withLoadingAndError(viewModel.upsertUiState, onRetryClicked = {
                                onSaveButtonClicked(
                                    dto?.passengerProfileGroup, errorText
                                )
                            }, onLoaded = {
                                popup.dismissDialog()
                                viewModel.getList()
                            })
                        }
                    }
                }
            }
        }
        upsertNameField?.element?.focus()
    }

    private fun Boolean?.toIsCommissionPercentageString(): String {
        this?.let {
            if (!it) {
                return isPercentageArray[1]
            }
        }
        return isPercentageArray[0]
    }

    private fun View.carTypesDropDown(carType: CarTypeDto?, onItemSelected: (CarTypeDto?) -> Unit) = setupDropDownList(
        "Car type",
        getList = { _, _, searchTerm ->
            try {
                viewModel.getCarTypesList(searchTerm)
                viewModel.carTypes.toTypedArray()
            } catch (t: Throwable) {
                t.printStackTrace()
                arrayOf()
            }
        },
        itemToString = { it.nameEn },
        onItemSelected = { onItemSelected(it) },
        defaultItem = carType,
        viewWidthFactory = { matchParent },
        showAutoComplete = true
    )

    private fun onSaveButtonClicked(
        dto: PassengerProfileGroupDto?, errorText: TextView
    ) {
        val nameInput = upsertNameField ?: return
        val commissionInput = upsertCommissionField ?: return

        val existingNames = viewModel.data.map { it.passengerProfileGroup.name.lowercase() }
        if (nameInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid name".localized()
            return
        }
        if (dto == null) {
            if (nameInput.text.trim().lowercase() in existingNames) {
                makeVisible(errorText)
                errorText.text = "A profile group with this name already exists".localized()
                return
            }

        }
        if (commissionInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid commission".localized()
            return
        }

        makeNotVisible(errorText)
        val name = nameInput.text.trim()
        val commission = commissionInput.text.toDouble()
        val isPercentage = newIsCommissionPercentage
        val toBeReplacedCarTypeId = newToBeReplacedCarType?.id
        val toBeUsedCarTypeId = newToBeUsedCarType?.id
        val isHiddenFromCallCenter = isHiddenFromCallCenterSelected.value ?: true
        viewModel.addItem(
            PassengerProfileGroupDto(
                dto?.id, name, commission, isPercentage, newColor, toBeReplacedCarTypeId, toBeUsedCarTypeId, isHiddenFromCallCenter
            )
        )
    }

}