package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.dto.rides.RideDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.matchParentWidth

class RidePriceDisplayItem(
    val extendedRideDto: ExtendedRideDto,
    val title: String

) : Component(
) {
    override fun View?.getView() = verticalLayout {
        val carType = extendedRideDto.carType
        val price  = extendedRideDto.ride.price
        style {
            width = matchParent
            padding = 5.px
            backgroundColor = AppColors.lightBackground
            margin = 2.px
            justifyContent = JustifyContent.SpaceBetween
        }
        theme.mediumTextView(this, ){text = title}
        verticalFiller(5)
        horizontalLayout {
            theme.text(this) {
                textView {
                    style { width = matchParent }
                    text = carType?.nameEn ?: ""
                }
            }
            horizontalFiller(10)
            theme.text(this) {
                textView {
                    text = carType?.nameAr ?: ""
                }
            }
        }

        horizontalFiller()
        horizontalLayout {
            matchParentWidth()
            style{
                justifyContent = JustifyContent.SpaceBetween
            }
            verticalLayout {
                theme.label(this, "Price Before Discount", false)
                theme.text(this) {
                    text = extendedRideDto.ride.price?.toString() ?: 0.toString()
                }
            }
            verticalLayout {
                theme.label(this, "Balance Discount", false)
                theme.text(this) {
                    text = extendedRideDto.ride.discount.toString()
                }
            }

            verticalLayout {
                theme.label(this, "Promo Discount", false)
                theme.text(this) {
                    text = extendedRideDto.ride.ridePromoDiscount.toString()
                }
            }
            verticalLayout {
                theme.label(this, "Payable Price", false)
                theme.text(this) {
                    text = price?.let { it ->
                        console.log("extendedRideDto.ride.discount" + extendedRideDto.ride.discount)
                        val payablePrice = it  - (extendedRideDto.ride.discount ?: 0.0) - (extendedRideDto.ride.ridePromoDiscount ?: 0.0)
                        "$payablePrice ${extendedRideDto.currency?.name} " }
                        ?: " - "
                }
            }
        }
    }
}