package com.narbase.letsgo.web.views.callCenter.cancelride

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.CancelRideAdminEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.GetRideByIdEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class CancelRideViewModel(
    private val rideId: Long,
) {
    val getRideUiState = Observable<BasicUiState>()
    var extendedRide: ExtendedRideDto? = null

    val cancelUiState = Observable<BasicUiState>()

    fun cancelRide(reason: String) {
        basicNetworkCall(cancelUiState) {
            val dto = CancelRideAdminEndpoint.Request(
                rideId = rideId,
                reason = reason)
            CancelRideAdminEndpoint.remoteProcess(dto)
        }
    }
    fun getRideDetails() {
        basicNetworkCall(getRideUiState) {
            val dto = GetRideByIdEndpoint.Request(rideId)
            val response = GetRideByIdEndpoint.remoteProcess(dto).data
            extendedRide = response.extendedRide ?: extendedRide
        }
    }
}