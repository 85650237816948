package com.narbase.letsgo.web.dto.drivers

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.dto.EnumDtoName
import com.narbase.letsgo.dto.core.DateDto
import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto
import com.narbase.letsgo.web.dto.currency.CurrencyDto
import com.narbase.letsgo.web.dto.driverprofilegroup.DriverProfileGroupDto

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/04/23.
 */
class DriverDto(
    val id: Long? = null,
    val createdOn: DateTimeDto?,
    val email: String,
    val fullName: String,
    val imageUrl: String?,
    val isMale: Boolean,
    val passwordHash: String,
    val phone: String,
    val role: DtoName<CommonDriverRole>,
    val currentRole: DtoName<CommonDriverRole>,
    val isActive: Boolean = false,
    val lastUpdated: DateTimeDto,
    val lastRequest: DateTimeDto?,
    val lat: Double,
    val lng: Double,
    val fcmToken: String? = null,
    val vehicle: String,
    val plate: String,
    val licenseNumber: String,
    val balance: Double,
    val profileGroup: Int,
    val civilNumber: String? = null,
    val carModel: String? = null,
    val notes: String? = null,
    val findable: Boolean? = false,
    val carTypeId: Int?,
    val currentCarTypeId: Int?,
    val zoneId: Int?,
    val currencyId: Int?,
    val destLat: Double?,
    val destLng: Double?,
    val isFlagActive: Boolean,
    val flagPercentage: Double?,
    val flagThreshold: Double,
    val appVersion: String?,
    val deviceVersion: String?,
    val snoozedUntil: DateTimeDto?,
    val blockedUntil: DateTimeDto?,
    val insuranceDate: DateDto?,
    val lastCarCheck: DateDto?,
)
class UpsertDriverDto(
    val id: Long? = null,
    val email: String,
    val fullName: String,
    val imageUrl: String?,
    val isMale: Boolean,
    val phone: String,
    val vehicle: String,
    val plate: String,
    val licenseNumber: String,
    val profileGroup: Int,
    val civilNumber: String? = null,
    val carModel: String? = null,
    val notes: String? = null,
    val carTypeId: Int?,
    val currencyId: Int?,
    val insuranceDate: DateDto?,
    val lastCarCheck: DateDto?,
)
class ExtendedDisplayDriverDto(
    val driver: DriverDto,
    val currency: CurrencyDto?,
    val carType: CarTypeDto?,
    val driverProfileGroup: DriverProfileGroupDto?,
)

@Suppress("EnumEntryName")
enum class CommonDriverRole(dtoName: String? = null) : EnumDtoName {
    economic,
    vip,
    female,
    interstate,
    large,
    inactive,
    delivery,
    ;

    override val dtoName = dtoName ?: this.name
}
