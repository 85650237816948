package com.narbase.letsgo.web.routing.domain.admin.pointofinterest

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.pointsOfIntrest.PointOfInterestDto

object GetNearbyPointsOfInterestEndpoint : EndPoint<GetNearbyPointsOfInterestEndpoint.Request, GetNearbyPointsOfInterestEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
        val centerLat: Double,
        val centerLng: Double
    )

    class Response(
        val list: Array<PointOfInterestDto>,
        val total: Int
    )
}