package com.narbase.letsgo.web.autorefresh

import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.logoutUser
import com.narbase.letsgo.web.utils.views.PopUpDialog
import com.narbase.letsgo.web.utils.views.showWarningDialog
import kotlinx.browser.window
import kotlin.js.Date

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/07/10.
 */
class AutoRefreshScheduler private constructor() {
    private var jobId: Int? = null
    fun start() {
        if (jobId != null) return
        jobId = window.setInterval({ checkAutoRefreshCondition() }, 20_000)
    }

    private fun checkAutoRefreshCondition() {
        val currentDate = Date().getDate()
        if (savedDate != currentDate) {
            showShouldUpdatePage()
        }
    }

    private fun showShouldUpdatePage() {
        PopUpDialog.warningPopUpDialog.dismissDialog()
        showWarningDialog(
                title = "Login needed".localized(),
                message = "Please save your work and login again to get the latest features.".localized(),
                actionButtonText = "Refresh now".localized(),
                dismissButtonText = "Skip for now".localized(),
                actionButtonStyle = null,
                action = {
                    logoutUser()
                    window.location.reload()
                },
                uiState = null,
                onConfirmed = {

                })
    }

    companion object {
        fun start() {
            instance.start()
        }
        private val instance by lazy { AutoRefreshScheduler() }
        val savedDate = Date().getDate()
    }
}