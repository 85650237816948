package com.narbase.letsgo.web.views.incentives

import com.narbase.kunafa.core.components.TextInput
import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.config.CurrencyIncentiveConfigDto
import com.narbase.letsgo.web.dto.config.ExtendedCurrencyIncentiveConfigDto
import com.narbase.letsgo.web.dto.config.TimeoutIncentiveConfigDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.ToggleSwitch
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.utils.views.withLoadingAndError

class TimeoutIncentiveConfigDialog(
    private val extendedCurrencyIncentiveConfigs: List<ExtendedCurrencyIncentiveConfigDto>,
    val onUpdated: () -> Unit
) {
    private val viewModel = IncentiveConfigViewModel()
    private val popup by lazy { popUpDialog() }

    var inputsList : MutableList<TimeoutIncentiveConfigInput> = mutableListOf()

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Save".localized()
                        onClick = {
                            save()
                        }
                    }.withLoadingAndError(viewModel.updateUiState, onLoaded = {
                        popup.dismissDialog()
                        onUpdated.invoke()
                    }, onRetryClicked = {
                        save()
                    })
                }
            }
            horizontalLayout {
                style {
                    width = matchParent
                    paddingEnd = 30.px
                }
                theme.mediumTextView(this) {
                    text = "Timeout Incentive Settings"
                }
            }
            console.log("extendedCurrencyIncentiveConfigs.size " + extendedCurrencyIncentiveConfigs.size)

            extendedCurrencyIncentiveConfigs.forEach {
                currencyTimeoutConfigLayout(it)
            }
        }
    }

    fun LinearLayout.currencyTimeoutConfigLayout(item: ExtendedCurrencyIncentiveConfigDto) =
        verticalLayout {
            style {
                height = wrapContent
                width = 40.vw
                backgroundColor = Color.white
                padding = 20.px
                borderRadius = 8.px
            }
            val timeoutConfig = item.currencyIncentiveConfig.timeoutIncentiveConfig
            val currency = item.currency
            var minInput: TextInput? = null
            var maxInput: TextInput? = null
            var passengerMessageInput: TextInput? = null
            var amountInput: TextInput? = null
            var activationOffsetInMinsInput: TextInput? = null
            val isEnabledSelected: Observable<Boolean> = Observable()
            var errorTextView: TextView? = null
            var errorMessage: Observable<String> = Observable()

            horizontalLayout {
                style {
                    width = matchParent
                    paddingEnd = 30.px
                }
                theme.mediumTextView(this) {
                    text = currency.name
                }
                horizontalFiller()
                isEnabledSwitch(timeoutConfig.isEnabled, isEnabledSelected)
            }

            horizontalLayout {
                style {
                    width = matchParent
                    marginTop = 16.px
                }
                verticalLayout {
                    theme.label(this, "Min", false)
                    minInput = theme.textInput(this) {
                        placeholder = "0.0"
                        type = "number"
                        text = timeoutConfig.minRidePrice.toString()
                    }
                }
                horizontalFiller()
                verticalLayout {
                    theme.label(this, "Max", false)
                    maxInput = theme.textInput(this) {
                        placeholder = "0.0"
                        type = "number"
                        text = timeoutConfig.maxRidePrice.toString()
                    }
                }
            }
            verticalLayout {
                style {
                    width = matchParent
                }
                theme.label(this, "Passenger Message", false)
                passengerMessageInput = theme.textInput(this) {
                    style {
                        width = matchParent
                    }
                    placeholder = ""
                    text = timeoutConfig.passengerMessage ?: ""
                }
            }
            verticalLayout {
                style {
                    width = matchParent
                }
                theme.label(this, "Incentive Amount", false)
                amountInput = theme.textInput(this) {
                    style {
                        width = matchParent
                    }
                    placeholder = "0.0"
                    type = "number"
                    text = timeoutConfig.amount.toString()
                }
            }
            verticalLayout {
                style {
                    width = matchParent
                }
                theme.label(this, "Activation Threshold in Minutes", false)
                activationOffsetInMinsInput = theme.textInput(this) {
                    style {
                        width = matchParent
                    }
                    placeholder = "0"
                    type = "number"
                    text = (timeoutConfig.activationTimeOffsetInSecs / 60).toString()
                }
            }

            verticalLayout {
                errorMessage.observe {
                    clearAllChildren()
                    errorTextView = theme.errorText(this) {
                        text = it ?: ""
                    }
                }
            }
            currency.id?.let {
            inputsList.add(
                TimeoutIncentiveConfigInput(
                    currencyId = it,
                    minInput = minInput,
                    maxInput = maxInput,
                    passengerMessageInput = passengerMessageInput,
                    amountInput = amountInput,
                    activationOffsetInMinsInput = activationOffsetInMinsInput,
                    isEnabledSelected = isEnabledSelected,
                    errorTextView = errorTextView,
                    errorMessage = errorMessage
                )
            ) }

        }

    private fun LinearLayout.isEnabledSwitch(
        isEnabled: Boolean,
        isEnabledSelected: Observable<Boolean>
    ): ToggleSwitch {
        val switch = ToggleSwitch()
        horizontalLayout {
            switch.resetIsSelected()
            switch.isSelected.value = isEnabled
            switch.isSelected.observe {
                if (it != null) {
                    isEnabledSelected.value = it
                }
            }
            theme.label(this, "Enable", false)
            mount(switch)
        }
        return switch
    }

    private fun save() {

        val newConfigs : MutableList<ExtendedCurrencyIncentiveConfigDto> = mutableListOf()
        inputsList.forEach { it.errorMessage.value = "" }
        inputsList.forEach {
            var passengerMessage : String? = null
            if (!it.passengerMessageInput?.text.isNullOrBlank()) {
                passengerMessage = it.passengerMessageInput?.text
            }
            if (it.activationOffsetInMinsInput?.text.isNullOrBlank()) {
                it.errorMessage.value = "Enter an activation time offset "
                return
            }

            val amount = if (!it.amountInput?.text.isNullOrBlank()) it.amountInput?.text?.toDouble() ?: 0.0 else 0.0
            val min = if (!it.minInput?.text.isNullOrBlank()) it.minInput?.text?.toDouble() else null
            val max = if (!it.maxInput?.text.isNullOrBlank()) it.maxInput?.text?.toDouble() else null
            val activationTimeOffsetInSecs =
                if (!it.activationOffsetInMinsInput?.text.isNullOrBlank()) it.activationOffsetInMinsInput?.text?.toInt()
                    ?.times(60) ?: 0 else 0

            val oldConfig = extendedCurrencyIncentiveConfigs.firstOrNull{dto -> it.currencyId == dto.currency.id} ?: return

            val config = ExtendedCurrencyIncentiveConfigDto(
                currencyIncentiveConfig = CurrencyIncentiveConfigDto(
                    id = oldConfig.currencyIncentiveConfig.id,
                    currencyId = it.currencyId,
                    orderingIncentiveConfig = oldConfig.currencyIncentiveConfig.orderingIncentiveConfig,
                    timeoutIncentiveConfig = TimeoutIncentiveConfigDto(
                        amount = amount,
                        minRidePrice = min,
                        maxRidePrice = max,
                        activationTimeOffsetInSecs = activationTimeOffsetInSecs,
                        passengerMessage = passengerMessage,
                        isEnabled = it.isEnabledSelected.value ?: false
                    )
                ),
                currency = oldConfig.currency
            )
            newConfigs.add(config)
        }

        viewModel.updateConfigs(newConfigs)
    }

    class TimeoutIncentiveConfigInput(
        val currencyId: Int,
        var minInput: TextInput? = null,
        var maxInput: TextInput? = null,
        var passengerMessageInput: TextInput? = null,
        var amountInput: TextInput? = null,
        var activationOffsetInMinsInput: TextInput? = null,
        var isEnabledSelected: Observable<Boolean>,
        var errorTextView: TextView? = null,
        var errorMessage: Observable<String> = Observable()
    )

}