package com.narbase.letsgo.web.utils.datetime

import com.narbase.letsgo.dto.core.TimeDto
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.utils.roundToTwoDigits
import kotlin.js.Date

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/03/13.
 */

fun Date.dto(): DateTimeDto = DateTimeDto(this.toDateTimeAtLocalTimeZone().getTime().roundToTwoDigits())

fun Date.stringDate() = toDateTimeAtLocalTimeZone().let { "${getFullYear()}-${(getMonth() + 1).lz()}-${getDate().lz()}" }


/**
 * Formats Date to
 * yyyy-MM-dd*/
fun Date.dateDto() =
        DateDto("${getFullYear()}-${(getMonth() + 1).lz()}-${getDate().lz()}")


/**
 * Formats DateTime from yyyy-MM-dd hh:mm:ss or Date from yyyy-MM-dd
 */
fun String.toDate(): Date {
    val parts = split(' ')
    return if (parts.size > 1) {
        val (date, time) = parts
        val (year, month, day) = date.split('-').map { it.toInt() }
        val (hour, minute, second) = time.split(':').map { it.toInt() }
        Date(year, month - 1, day, hour, minute, second)
    } else {
        val (date) = parts
        val (year, month, day) = date.split('-').map { it.toInt() }
        Date(year, month - 1, day, 0, 0, 0)
    }
}

fun DateTimeDto.toDate(): Date = Date(this.milliSeconds.toString().toLong()).toDateTimeAtClinicTimeZone()
//fun DateTimeDto(double: Double) = DateTimeDto(com.narbase.letsgo.dto.core.commonLongOf(double.toLong()))

private fun Int.lz() = toString().padStart(2, '0')

fun Date.copyDateOnly(year: Int? = null, month: Int? = null, day: Int? = null) = copy(year, month, day, 0, 0, 0)

fun Date.copy(
        year: Int? = null,
        month: Int? = null,
        day: Int? = null,
        hour: Int? = null,
        minute: Int? = null,
        second: Int? = null
) = Date(
        year ?: getFullYear(),
        month ?: getMonth(),
        day ?: getDate(),
        hour ?: getHours(),
        minute ?: getMinutes(),
        second ?: getSeconds()
)

fun Date.getMinuetsOfDay(): Int = getMinutes() + getHours() * 60

typealias DateTimeDto = com.narbase.letsgo.dto.core.DateTimeDto
typealias DateDto = com.narbase.letsgo.dto.core.DateDto

fun DateDto.toDate() = date.toDate()


fun Date.toDateTimeAtLocalTimeZone(): Date { //todo should find better name
    val currentTimeInMilliSeconds = getTime()
    return Date(currentTimeInMilliSeconds + StorageManager.timeDifferenceInMilliSeconds).copy(second = 0)
}

fun Date.toDateTimeAtClinicTimeZone(): Date {
    val options = dateLocaleOptions { /*timeZone = clinicTimeZone*/ }
    val timeWithClinicTimeZoneString = toLocaleString("en-US", options)
    return Date(timeWithClinicTimeZoneString)
}

fun String.toTimeDto(): TimeDto {
    var time = split(':')
    if (time.size == 2) {
        time = "$this:00".split(":")
    }
    val (hours, minutes, seconds) = time.map { it }
    return TimeDto("$hours:$minutes:$seconds")
}


