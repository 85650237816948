package com.narbase.letsgo.web.views.templates

import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.translations.localized

var clientLanguage = StorageManager.language

typealias MultiLingualTextDto = com.narbase.letsgo.dto.core.MultiLingualTextDto

class MissingLanguageException(message: String = "This Multilingual Text is missing the English entry".localized()) :
        Exception(message)
