package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.network.ServerCaller

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2019/06/01.
 */

fun View.initImageViewerPopupDialog() {
    imageViewerPopUpDialog = popUpDialog { }
}

private var imageViewerPopUpDialog: PopUpDialog? = null
private var selectedImageIndex = 0
private var photoLayout: LinearLayout? = null
fun showImagesPopupDialog(imageUrls: Array<String>, indexOfImageToShow: Int) {
    if (imageViewerPopUpDialog == null) {
        console.log("Call initImageViewerPopupDialog() before using showImages")
        return
    }
    imageViewerPopUpDialog?.showDialog {
        verticalLayout {
            style {
                width = 90.vw
                height = 90.vh
                alignItems = Alignment.Center
                justifyContent = JustifyContent.Center
            }
            photoLayout = horizontalLayout {
                style {
                    width = 90.vw
                    backgroundColor = Color.black
                    height = matchParent
                    minHeight = 400.px
                    alignItems = Alignment.End
                    backgroundPosition = "center"
                    backgroundRepeat = "no-repeat"
                    backgroundSize = "contain"
                    margin = "16px 0 0 0".dimen()
                }
                console.log("Images")
                console.log(imageUrls)
                if (imageUrls.isNullOrEmpty()) {
                    element.style.backgroundImage = "url(/public/img/placeholder.jpg)"
                } else {
                    val index = if (imageUrls.indices.contains(indexOfImageToShow)) indexOfImageToShow else 0
                    selectedImageIndex = index
                    val imageUrl = if (imageUrls[index].startsWith("http")) imageUrls[index] else "${ServerCaller.BASE_URL}${imageUrls[index]}"
                    element.style.backgroundImage =  "url(\"$imageUrl\")"
                    console.log(imageUrls[index])
                }

                if ((imageUrls.size) > 1) {

                    textView {
                        text = "< Previous"
                        style {
                            backgroundColor = Color(100, 100, 100, 0.8)
                            padding = "20px".dimen()
                            color = Color.white
                            fontSize = 20.px
                            width = weightOf(1)
                            pointerCursor()
                        }
                        onClick = {
                            val size = imageUrls.size
                            if (selectedImageIndex == 0)
                                selectedImageIndex = size - 1
                            else
                                selectedImageIndex -= 1
                            updatePhotoBackground(imageUrls)
                        }
                    }
                    textView {
                        text = "Next >"
                        style {
                            backgroundColor = Color(100, 100, 100, 0.8)
                            padding = "20px".dimen()
                            color = Color.white
                            fontSize = 20.px
                            width = weightOf(1)
                            textAlign = TextAlign.Right
                            pointerCursor()
                        }
                        onClick = {
                            val size = imageUrls.size
                            if (selectedImageIndex == size - 1)
                                selectedImageIndex = 0
                            else
                                selectedImageIndex += 1
                            updatePhotoBackground(imageUrls)
                        }
                    }

                }
            }

        }
    }
}

private fun updatePhotoBackground(imageUrls: Array<String>) {
    if (selectedImageIndex < 0) return
    if (imageUrls.isEmpty()) return
    val imageUrl = if (imageUrls[selectedImageIndex].startsWith("http")) imageUrls[selectedImageIndex] else "${ServerCaller.BASE_URL}${imageUrls[selectedImageIndex]}"

    photoLayout?.element?.style?.backgroundImage = "url(\"$imageUrl\")"
    console.log("url(\"${imageUrls[selectedImageIndex]}\")")
}
