package com.narbase.letsgo.web.routing.domain.admin.drivers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.driverprofilegroup.ExtendedDriverProfileGroupDto
import com.narbase.letsgo.web.dto.drivers.ExtendedDisplayDriverDto

object GetDriversListEndpoint : EndPoint<GetDriversListEndpoint.Request, GetDriversListEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
    )

    class Response(
        val list: Array<ExtendedDisplayDriverDto>,
        val total: Int
    )
}