package com.narbase.letsgo.dto.core.entries

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.dto.EnumDtoName

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/04/02.
 */

enum class EntryTypes {
    TextInput,
    Mcq,
    CheckList,
    Image,
    Signature,
    Date,
    Group,
    Separator,
    TextView,
    Custom,
    DropDownList,
    RemoteDropDownList,
    CheckDropDownList,
    RemoteCheckDropDownList,
}

class EntryCondition(
        val rule: Operator,
        val action: DtoName<Action>,
        val message: String?,
) {
    /*
     * Rules: Array of ORs?
     *  - If entry_1 isBlank
     *  - If entry_1 is value
     *  - If entry_2 isNot value
     * Action:
     *  - Hide (All entries are shown by default)
     *  - IsRequired
     * Target (List of target entries affected by the condition)? maybe in the future:
     */

    class Operator(
            val type: DtoName<OperatorType>,

            // For operator
            val exp1: Operator?,
            val exp2: Operator?,

            //For expression:
            val value: String?,
            )

    enum class Action(dtoName: String? = null) : EnumDtoName {
//        Hide,
        IsRequired,
        ;

        override val dtoName = dtoName ?: this.name
    }

    enum class OperatorType(dtoName: String? = null) : EnumDtoName {
        // OperatorType
        Equals,
        NotEquals,
        Or,
        And,

        // Simple types

        Entry,
        RegEx,
        Text,
        ;

        override val dtoName = dtoName ?: this.name

    }

}

