package com.narbase.letsgo.web.views.incentives

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.currency.CurrencyDto
import com.narbase.letsgo.web.dto.orderingIncentives.*
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.DateTimeDto
import com.narbase.letsgo.web.utils.datetime.dto
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.datetime.toTimeDto
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*
import kotlin.js.Date

class UpsertGroupOrderingIncentivesDialog(
    private val orderingIncentiveRmDto: OrderingIncentiveRmDto,
    val onUpdated: () -> Unit
) {
    private val viewModel = UpsertGroupOrderingIncentivesViewModel()
    private val popup by lazy { popUpDialog() }

    private val errorMessage = "Fill all fields".localized()

    private var currenciesIncentives: MutableList<CurrencyIncentives> = orderingIncentiveRmDto.orderingIncentives.map {
        CurrencyIncentives(
            currencyId = it.currency.id!!
        )
    }.toMutableList()

    fun show() {
        viewModel.getCurrencies()
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Save".localized()
                        onClick = {
                            save()
                        }
                    }.withLoadingAndError(viewModel.updateUiState, onLoaded = {
                        popup.dismissDialog()
                        onUpdated.invoke()
                    }, onRetryClicked = {
                        save()
                    })
                }
            }
            verticalLayout {
                style {
                    height = wrapContent
                    width = 40.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                        paddingEnd = 30.px
                    }
                    theme.mediumTextView(this) {
                        text = "Passenger Profile Group Incentive"
                    }
                    horizontalFiller()
                }
                verticalFiller(20)
                allIncentiveLayout(orderingIncentiveRmDto.orderingIncentives)
            }
        }
    }

    private fun LinearLayout.allIncentiveLayout(orderingIncentives: Array<ExtendedOrderingIncentiveDto>) =
        verticalLayout {
            style {
                width = matchParent
            }
            val currenciesLayout = verticalLayout {
                style {
                    width = matchParent
                }
                orderingIncentives.forEach {
                    currencyForm(it, it.currency)
                }
                verticalFiller(20)

            }

            withLoadingAndError(viewModel.getCurrenciesUiState,
                onLoaded = {
                    val currencies = viewModel.currencies.filter { it.id !in orderingIncentives.map { it.currency.id } }
                    currencies.forEach { currency ->
                        theme.mediumTextView(this) {
                            style {
                                color = AppColors.activeColor
                                padding = "8px 0px".dimen()
                                pointerCursor()
                            }
                            text = " + " + currency.name + " Settings"
                            onClick = {
                                this.isVisible = false
                                val currencyId = currency.id
                                if (currencyId != null) {
                                    currenciesIncentives.add(
                                        CurrencyIncentives(currencyId, mutableListOf())
                                    )
                                }

                                currenciesLayout.apply {
                                    currencyForm(null, currency)
                                }
                            }
                        }
                    }
                },
                onRetryClicked = {
                    viewModel.getCurrencies()
                }
            )
        }

    private fun LinearLayout.currencyForm(
        extendedOrderingIncentiveDto: ExtendedOrderingIncentiveDto?,
        currency: CurrencyDto
    ) =
        verticalLayout {
            style {
                width = 100.percent
                padding = 20.px
                border = "1px solid ${AppColors.borderColor}"
                alignSelf = Alignment.Center
            }
            val isEnabledSelected : Observable<Boolean> = Observable()
            horizontalLayout {
                style {
                    width = matchParent
                    paddingEnd = 30.px
                }
                theme.mediumTextView(this) {
                    text = currency.name
                }
                horizontalFiller()
                isEnabledSwitch(extendedOrderingIncentiveDto?.orderingIncentive?.isEnabled ?: false, isEnabledSelected)
            }
            val timingsLayout = verticalLayout {
                style {
                    width = matchParent
                }
                if (extendedOrderingIncentiveDto?.orderingIncentive?.timings?.timings.isNullOrEmpty()) {
                    timingLayout(0, null, currency)
                }
                extendedOrderingIncentiveDto?.orderingIncentive?.timings?.timings?.forEachIndexed { index, it ->
                    timingLayout(index, it, currency)
                }

            }

            currenciesIncentives.firstOrNull { it.currencyId == currency.id }.let {
                it?.errorTextView = theme.errorText(this) {
                    text = ""
                }
                it?.isEnabledSelected = isEnabledSelected
            }


            verticalFiller(20)
            theme.unimportantButton(this) {
                style {
                    width = matchParent
                }
                text = "+ Add"
                onClick = {
                    timingsLayout.apply {
                        val i = currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.size
                            ?: 1
                        timingLayout(i, null, currency)
                    }
                }
            }
        }

    private fun LinearLayout.timingLayout(index: Int, timingDto: TimingDto?, currency: CurrencyDto) = horizontalLayout {
        style {
            width = matchParent
        }
        var startInput: TextInput? = null
        var endInput: TextInput? = null
        var amountInput: TextInput? = null

        verticalLayout {
            theme.label(this, "Start Time: ", false)
            startInput = theme.textInput(this) {
                style {
                    width = 110.px

                }
                placeholder = ""
                type = "time"
            }
            if (timingDto != null) {
                startInput?.element?.value = timingDto.startTime.time
            } else {
                if (index > 0) {
                    val previousEnd =
                        currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.get(index - 1)?.endInput?.text
                    if (previousEnd != null) {
                        startInput?.element?.value = previousEnd
                    }

                }
            }

        }
        horizontalFiller()
        verticalLayout {
            theme.label(this, "End Time: ", false)
            endInput = theme.textInput(this) {
                style {
                    width = 110.px
                }
                placeholder = ""
                type = "time"
                onChange = {
                    val endTime = endInput?.text //.removePrefix(":")
                    val insentivesSize =
                        currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.size ?: 1
                    if (index < (insentivesSize - 1)) {
                        val nextStart =
                            currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.get(
                                index + 1
                            )?.startInput
                        if (!nextStart?.text.isNullOrBlank() && endTime != null) {
                            val hours = nextStart?.text?.dateStringToDate()?.getHours()
                            if (nextStart != null && !endInput?.text.isNullOrBlank()) {
                                val endHours = endInput?.text?.dateStringToDate()?.getHours() ?: 0
                                if (hours != null) {
                                    if (hours > endHours) {
                                        currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.get(
                                            index + 1
                                        )?.startInput?.element?.value = endTime
                                    } else if (hours == endHours) {
                                        val mins = nextStart.text.dateStringToDate().getMinutes()
                                        val endMins = endInput?.text?.dateStringToDate()?.getMinutes() ?: 0
                                        if (mins > endMins) {
                                            currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.get(
                                                index + 1
                                            )?.startInput?.element?.value = endTime
                                        }
                                    }

                                } else {
                                    currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.get(
                                        index + 1
                                    )?.startInput?.element?.value = endInput?.text.toString()
                                }
                            }

                        }

                    }
                }
            }
            if (timingDto != null) {
                endInput?.element?.value = timingDto.endTime.time
            }
        }
        horizontalFiller()
        verticalLayout {
            theme.label(this, "Amount: ", false)
            amountInput = theme.textInput(this) {
                style {
                    width = 110.px
                }
                placeholder = ""
                text = timingDto?.amount?.toString() ?: ""
            }
        }
        horizontalFiller()
        verticalLayout {
            style {
                height = matchParent
                justifyContent = JustifyContent.Center
            }
            materialIcon("delete") {
                style {
                    opacity = 0.4
                    pointerCursor()
                    hover {
                        color = Color.red
                    }
                }
                onClick = {
                    this@horizontalLayout.isVisible = false
                    currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.removeAt(index)

                }
            }
        }
        currenciesIncentives.firstOrNull { it.currencyId == currency.id }?.incentiveInputs?.add(
            index, IncentiveInput(
                startInput,
                endInput,
                amountInput
            )
        )
    }

    class CurrencyIncentives(
        val currencyId: Int,
        var incentiveInputs: MutableList<IncentiveInput> = mutableListOf(),
        var errorTextView: TextView? = null,
        var isEnabledSelected: Observable<Boolean>? = null,
        )

    class IncentiveInput(
        var startInput: TextInput? = null,
        var endInput: TextInput? = null,
        var amount: TextInput? = null
    )

    private fun LinearLayout.isEnabledSwitch(
        isEnabled: Boolean,
        isEnabledSelected: Observable<Boolean>
    ): ToggleSwitch {
        val switch = ToggleSwitch()
        horizontalLayout {
            switch.resetIsSelected()
            switch.isSelected.value = isEnabled
            switch.isSelected.observe {
                if (it != null) {
                    isEnabledSelected.value = it
                }
            }
            theme.label(this, "Enable", false)
            mount(switch)
        }
        return switch
    }

    private fun save() {

        val extendedOrderingIncentiveDtos = currenciesIncentives.map { currencyIncentive ->

            val currency = viewModel.currencies.firstOrNull { it.id == currencyIncentive.currencyId }
            if (currency != null) {
                ExtendedOrderingIncentiveDto(
                    orderingIncentive = OrderingIncentiveDto(
                        currencyId = currencyIncentive.currencyId,
                        passengerProfileGroupId = orderingIncentiveRmDto.passengerProfileGroup.id!!,
                        timings = TimingsDto(
                            timings = currencyIncentive.incentiveInputs.map {
                                if (it.startInput?.text.isNullOrBlank() || it.endInput?.text.isNullOrBlank() || it.amount?.text.isNullOrBlank()) {
                                    currencyIncentive.errorTextView?.text = errorMessage
                                    return
                                }
                                val startDate = it.startInput?.text ?: return
                                val endDate = it.endInput?.text ?: return
                                TimingDto(
                                    startTime = startDate.toTimeDto(),
                                    endTime = endDate.toTimeDto(),
                                    amount = it.amount?.text?.toDouble() ?: 0.0
                                )

                            }.toTypedArray(),
                        ),
                        isEnabled = currencyIncentive.isEnabledSelected?.value ?: false,
                        id = null
                    ),
                    currency = currency
                )
            } else {
                return
            }

        }.filter { it.orderingIncentive.timings.timings.isNotEmpty() }

        val dto = OrderingIncentiveRmDto(
            orderingIncentives = extendedOrderingIncentiveDtos.toTypedArray(),
            passengerProfileGroup = orderingIncentiveRmDto.passengerProfileGroup
        )
        viewModel.upsertOrderingIncentive(dto)
    }

    private fun String.dateStringToDate(): Date {
        val time = if (this.length > 5) this else this + ":00"
        return ("0000-00-00 " + time).toDate()
    }

}