package com.narbase.letsgo.web.routing.domain.admin.passengerprofilegroup

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengerprofilegroup.PassengerProfileGroupDto


object UpsertPassengerProfileGroupEndpoint :
    EndPoint<UpsertPassengerProfileGroupEndpoint.Request, UpsertPassengerProfileGroupEndpoint.Response>() {
    class Request(
        val passengerProfileGroupDto: PassengerProfileGroupDto
    )

    class Response()
}