package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto
import com.narbase.letsgo.web.dto.price.ExtendedPriceDataDto
import com.narbase.letsgo.web.dto.price.PriceDataDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.roundToTwoDigits
import com.narbase.letsgo.web.utils.views.pointerCursor

class PriceDisplayItem(
    val carType: CarTypeDto,
    val extendedPriceDataDto: ExtendedPriceDataDto?,
    val onSelected : (() -> Unit)? = null

) : Component(
) {
    override fun View?.getView() = verticalLayout{
        style {
            width = matchParent
            padding = 15.px
            border = "1px solid ${AppColors.borderColor}"
            borderRadius = 4.px
            margin = 2.px
            justifyContent = JustifyContent.SpaceBetween
            onSelected?.let {
                pointerCursor()
                hover {
                    backgroundColor = AppColors.hoverBackground
                }
            }
        }
        horizontalLayout {
            theme.text(this) {
                textView {
                    style { width = matchParent }
                    text = carType.nameEn
                }
            }
            horizontalFiller(10)
            theme.text(this) {
                textView {
                    text = carType.nameAr
                }
            }
        }
        horizontalLayout {
            style {
                width = matchParent
                justifyContent = JustifyContent.SpaceBetween
            }
            extendedPriceDataDto?.let { p ->

                verticalLayout {
                    theme.label(this, "Calculated Price", false)
                    theme.text(this) {
                        text = calculatePriceBefore(p.priceData).toString()
                    }
                }

                verticalLayout {
                    val adjustment = p.priceData.profileGroupAdjustment?.roundToTwoDigits() ?: 0.0
                    val label = if (adjustment < 0) {
                        "Group Discount"
                    } else {
                        "Group Commission"
                    }
                    theme.label(this, label, false)
                    theme.text(this) {

                        if (adjustment < 0) {
                            text = (-adjustment).toString()
                        } else {
                            text = p.priceData.profileGroupAdjustment.toString()
                        }
                    }
                }

                verticalLayout {
                    theme.label(this, "Balance Discount", false)
                    theme.text(this) {
                        text = p.priceData.balanceDiscount.toString()
                    }
                }

                verticalLayout {
                    theme.label(this, "Promo Discount", false)
                    theme.text(this) {
                        text = p.priceData.promoDiscount.toString()
                    }
                }

            }

            theme.mediumTextView(this) {
                style {
                    color = AppColors.completedColor
                }
                text = extendedPriceDataDto?.let { it -> "${it.priceData.payablePrice} ${it.priceData.currencyName} " }
                    ?: " - "
            }
        }
        onClick = {
            onSelected?.invoke()
        }
    }

    private fun calculatePriceBefore(price: PriceDataDto): Double {
        val adjustment = price.profileGroupAdjustment ?: 0.0
        return (price.actualRidePrice - adjustment).roundToTwoDigits()


    }
}