package com.narbase.letsgo.web.utils

import com.narbase.letsgo.web.AppViewController
import com.narbase.letsgo.web.storage.StorageManager

fun logoutUser() {
    StorageManager.accessToken = null
    StorageManager.setUserLoggedIn(false)
//    window.location.reload()
    AppViewController.loginState.value = false
//    showLoading()

}