package com.narbase.letsgo.web.views.callCenter.updateride

import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideChangeabilityStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.callCenter.CallCenterViewModel
import com.narbase.letsgo.web.views.callCenter.RequestRideComponent


class UpdateRideDialog(
    val rideId: Long,
    private val extendedRideDto: ExtendedRideDto,
    val callCenterViewModel: CallCenterViewModel,
    val onLocationsChanged: () -> Unit
) {
    private val popup by lazy {
        popUpDialog()
    }
    private val viewModel = UpdateRideViewModel(rideId, extendedRideDto)
    var pickUp: LocationPointDto = LocationPointDto(
        lat = extendedRideDto.ride.pickupLat,
        lng = extendedRideDto.ride.pickupLng,
        address = extendedRideDto.ride.pickupAddress,
        name = null,
        pointOfInterestDto = null,
        favoritePointDto = null
    )
    var dest: LocationPointDto? = null
    var priceToken: Observable<String?> = Observable()
    var isEditable: Boolean = false
    var payablePrice: Int? = extendedRideDto.ride.price
    var currencyName: String? = null

    fun show() {
        theme.showDialog(popup) {
            style {
                width = 70.vw
            }
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    horizontalLayout {
                        priceToken.observe { token ->
                            clearAllChildren()
                            theme.mainButton(this) {
                                text = "Edit Location".localized()
                                onClick = {
                                    updateRide()
                                }
                                element.disabled = (token == null && dest != null )
                            }.withLoadingAndError(viewModel.updateUiState, onLoaded = {
                                if(viewModel.isLocationChangable == RideChangeabilityStatus.Unchangeable ){
                                    locationCantBeChangedDialog()
                                } else {
                                    popup.dismissDialog()
                                    onLocationsChanged.invoke()
                                }

                            }, onRetryClicked = {
                                updateRide()
                            })
                        }
                    }
                }
            }

            verticalLayout {
                style { width = matchParent }
                clearAllChildren()
                mount(
                    RequestRideComponent(
                        extendedRideDto.passenger,
                        callCenterViewModel,
                        extendedRideDto,
                        saveUpdateLocations = { p, d, token, price, currency ->
                            pickUp = p
                            dest = d
                            priceToken.value = token
                            payablePrice = price
                            currencyName = currency
                        },
                        onRideCreated = { },
                    )
                )

            }
        }
    }

    private fun updateRide() {
        val price = payablePrice
        val token = priceToken.value
        val message =  if(token == null ){
            "This is an Open Ride. \nFrom  ${pickUp.address}.  \n" +
                    "Are you sure you want to make this change?"
        } else {
            "Your new price is: $price $currencyName. \nFrom ${pickUp.address} to ${dest?.address} \nAre you sure you want to make this change?  ".localized()
        }
        showConfirmationDialogWithRemoteAction("Confirm Change Ride Request".localized(),
            message,
            "Confirm".localized(),
            null,
            action = {
                viewModel.updateRideLocations(pickUp, dest, token)
            },
            actionButtonStyle = null,
            uiState = viewModel.updateUiState,
            onConfirmed = {})
    }

    private fun locationCantBeChangedDialog() {
        PopUpDialog.warningPopUpDialog.dismissDialog()
        showWarningDialog(
            title = "Location can't be changed".localized(),
            message = "Pickup and destination are not changeable at this stage  ".localized(),
            actionButtonText = "Continue".localized(),
            dismissButtonText = null,
            actionButtonStyle = null,
            action = {
                console.log("this action called")
                     popup.dismissDialog()
            },
            uiState = null,
            onConfirmed = {
            },
        )
    }
}
