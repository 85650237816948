package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.dimensions.dimen

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/29.
 */

fun String.asDimension() = this.dimen()
