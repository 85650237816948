package com.narbase.letsgo.web.routing.domain.admin.favoritepoints

import com.narbase.letsgo.router.EndPoint

object DeleteFavoritePointEndpoint :
    EndPoint<DeleteFavoritePointEndpoint.Request, DeleteFavoritePointEndpoint.Response>() {
    class Request(
        val favoritePointId: Long
    )

    class Response()
}