package com.narbase.letsgo.web.routing.domain.admin.passengerlocation

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengerlocation.PassengerLocationDto

object GetPassengerLocationEndpoint : EndPoint<GetPassengerLocationEndpoint.Request, GetPassengerLocationEndpoint.Response>() {
    class Request(
        val passengerId: Long,
    )

    class Response(
        val passengerLocation: PassengerLocationDto?,
    )
}