package com.narbase.letsgo.web.views.admin.drivers


import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vh
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.admin.DynamicRoleDto
import com.narbase.letsgo.web.dto.drivers.ExtendedDisplayDriverDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.GetAdminDynamicRolesEndpoint
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.MultiSelectionDropDownListViewModel
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.utils.views.setupRemoteMultiSelectionDropDownList
import com.narbase.letsgo.web.utils.views.withLoadingAndError
import com.narbase.letsgo.web.views.templates.EntriesStyles.textInputStyle

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/20.
 */
class UpsertDriverDialog(private val onUpdated: () -> Unit) {
    private var toEdit: ExtendedDisplayDriverDto? = null
    private val popUp = popUpDialog { }
    private var rolesDropDownListVm: MultiSelectionDropDownListViewModel<DynamicRoleDto>? = null

    private var errorTextView: TextView? = null
    private val viewModel = DriversManagementViewModel()
    private val basicDriverFrom by lazy { BasicDriverFrom(toEdit) }

    private fun upsertDialog() {
        theme.showDialog(popUp) { dialogBuilder ->
            verticalLayout {
                id = "upsertDriverRootView"
                style {
                    height = wrapContent
                    minWidth = 800.px
                    width = matchParent
                    backgroundColor = Color.white
                    borderRadius = 8.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    theme.bigTextView(this) {
                        text = if (toEdit == null) "Add driver".localized() else "Edit driver".localized()
                    }
                    horizontalFiller()

                }
                verticalFiller(16)

                horizontalLayout {
                    style {
                        width = matchParent
                        maxHeight = 60.vh
                    }
                    verticalLayout {
                        style {
                            width = weightOf(1)
                            height = 60.vh
                        }
//                        scrollable {
//                            style {
//                                width = matchParent
//                                maxHeight = 60.vh
//                            }
//
//                        }
                        mount(basicDriverFrom)
                    }

                }

                errorTextView = textView {
                    style {
                        marginBottom = 8.px
                        fontSize = 14.px
                        color = AppColors.redLight
                        padding = 20.px
                    }
                    isVisible = false
                    text = "Please enter valid fields values".localized()
                }

                dialogBuilder.bottomBar = {
                    horizontalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
                            justifyContent = JustifyContent.End
                        }
                        theme.unimportantButton(this) {
                            text = "Dismiss".localized()
                            onClick = {
                                popUp.dismissDialog()
                            }

                        }

                        horizontalFiller(16)

                        val saveButton = theme.mainButton(this) {
                            text = "Save".localized()
                            id = "SaveButton"
                            onClick = {
                                onSaveButtonClicked()
                            }
                        }
                        viewModel.upsertUiState.clearObservers()
                        saveButton.withLoadingAndError(viewModel.upsertUiState,
                            onRetryClicked = {
                                onSaveButtonClicked()
                            },
                            onLoaded = {
                                popUp.dismissDialog()
                                onUpdated()
                            }
                        )
                    }

                }
            }
        }

    }

    private fun onSaveButtonClicked() {
        isDataValid = true
        val updateDto = basicDriverFrom.validateAndGetUpdatedDriverDto() ?: return
        viewModel.upsertDriver(updateDto)
    }

    private var isDataValid = false
    private fun TextInput?.validateAndGetText(): String {
        val text = this?.text ?: ""
        if (text.isBlank()) {
            isDataValid = false
            this?.addErrorStyle()
        } else {
            this?.resetStyle()
        }
        return text
    }


    private fun View.labeledTextInput(localized: String, block: TextInput.() -> Unit = {}): TextInput {
        return theme.labeledTextInput(this, localized, block = block)
    }


    private fun TextInput.handleOnFullNameChanged() {
        resetStyle()
    }


    private fun View.addErrorStyle() {
        removeRuleSet(textInputStyle)
        addRuleSet(textInputErrorStyle)
    }

    private fun View.resetStyle() {
        removeRuleSet(textInputErrorStyle)
        addRuleSet(textInputStyle)
    }


    private fun LinearLayout.dynamicRolesView(defaultRoles: List<DynamicRoleDto>) {
        horizontalLayout {
            style {
                width = matchParent
                height = wrapContent
                marginBottom = 16.px
                alignItems = Alignment.Center
            }

            rolesDropDownListVm = MultiSelectionDropDownListViewModel(
                itemToId = { it.name },
                getRemoteItems = { pageNo, searchTerm ->
                    basicNetworkCall(loadingListState) {
                        val dto = GetAdminDynamicRolesEndpoint.Request(pageNo, 20, searchTerm)
                        val response = GetAdminDynamicRolesEndpoint.remoteProcess(dto)
                        appendItems(response.data.list, endOfList = response.data.list.isEmpty())
                    }
                }
            )

            setupRemoteMultiSelectionDropDownList(
                name = "Roles".localized(),
                viewModel = rolesDropDownListVm!!,
                itemToString = { it.name },
                onSelectedItemsUpdated = { },
                showAutoComplete = true,
                rootStyle = classRuleSet {
                    width = weightOf(1)
                },
                defaultItems = defaultRoles,
                isDisabled = false
            )
        }
    }


    fun add() {
        upsertDialog()
    }

    fun edit(dto: ExtendedDisplayDriverDto) {
        toEdit = dto
        upsertDialog()
    }

    private val textInputErrorStyle = classRuleSet {
        padding = 4.px
        fontSize = 14.px
        padding = "6px 12px".dimen()
        border = "1px solid ${AppColors.redLight}"
        borderRadius = 4.px
    }


}
