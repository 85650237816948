package com.narbase.letsgo.web.utils.views


import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.storage.bidirectionalView
import com.narbase.letsgo.web.translations.localized
import kotlin.math.min

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2018] Narbase Technologies
 * All Rights Reserved.
 * Created by ${user}
 * On: ${Date}.
 */

class PaginationControls(
        private val onNextPage: () -> Unit,
        private val onPreviousPage: () -> Unit,
        val darkTheme: Boolean = false
) {
    var rootView: View? = null
    private var paginationTextView: TextView? = null
    private var beforeIcon: MaterialIcon? = null
    private var afterIcon: MaterialIcon? = null

    private val activeIconStyle = if (darkTheme)
        classRuleSet {
            color = AppColors.white
            hover {
                color = Color.white
                backgroundColor = AppColors.separatorDark
                cursor = "pointer"
            }
        } else
        classRuleSet {
            color = AppColors.text
            hover {
                color = Color.black
                backgroundColor = AppColors.separatorLight
                cursor = "pointer"
            }
        }

    private val inactiveRuleSet = if (darkTheme) classRuleSet {
        color = AppColors.white
        opacity = 0.3
    }
    else classRuleSet {
        color = AppColors.separatorLight
    }

    fun View.setup() {
        rootView = horizontalLayout {
            style {
                height = wrapContent
                alignSelf = Alignment.End
                padding = 8.px
                color = if (darkTheme)
                    AppColors.white
                else AppColors.text
                alignItems = Alignment.Center
            }
            paginationTextView = textView {
                text = "- to - out of -"
                style {
                    marginEnd = 16.px
                    marginStart = 16.px
                }
            }

            beforeIcon = materialIcon("keyboard_arrow_left") {
                bidirectionalView()
                tooltip("Previous")
            }

            afterIcon = materialIcon("keyboard_arrow_right") {
                bidirectionalView()
                tooltip("Next")
            }
        }
    }

    fun update(pageNo: Int, pageSize: Int, count: Int?) {
        update(pageNo, pageSize, count, null)
    }
    fun updateWithoutCount(pageNo: Int, pageSize: Int, isLastPageEmpty: Boolean) {
        update(pageNo, pageSize, null, isLastPageEmpty)
    }

    private fun update(pageNo: Int, pageSize: Int, rawCount: Int?, isLastPageEmpty: Boolean?) {
        val count = rawCount ?: 99999

        val paginationText = StringBuilder().apply {
            val startingIndex = pageSize * pageNo
            append("${startingIndex + if (startingIndex < count) 1 else 0} ")
            append("to".localized())
            append(" ${min(pageSize * (pageNo + 1), count)} ")
            if (rawCount != null) {
                append("out of".localized())
                append(" $count")
            }
        }.toString()
        paginationTextView?.text = paginationText

        val isLastPage = if (rawCount != null) pageSize * (pageNo + 1) >= count else isLastPageEmpty ?: false

        if (pageNo == 0) {
            beforeIcon?.setInactive()
        } else {
            beforeIcon?.setActive(onPreviousPage)
        }

        if (isLastPage) {
            afterIcon?.setInactive()
        } else {
            afterIcon?.setActive(onNextPage)
        }
    }

    private fun MaterialIcon.setInactive() {
        removeRuleSet(activeIconStyle)
        addRuleSet(inactiveRuleSet)
        onClick = {}
    }

    private fun MaterialIcon.setActive(onClickFunction: () -> Unit) {
        removeRuleSet(inactiveRuleSet)
        addRuleSet(activeIconStyle)
        onClick = { onClickFunction() }
    }
}

fun View.setupPaginationControls(
        onNextPage: () -> Unit,
        onPreviousPage: () -> Unit,
        darkTheme: Boolean = false
): PaginationControls {
    return PaginationControls(onNextPage, onPreviousPage, darkTheme).apply {
        setup()
    }
}
