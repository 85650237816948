package com.narbase.letsgo.web.routing.domain.admin.favoritepoints

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.favoritepoints.FavoritePointDto

object CreateFavoritePointEndpoint :
    EndPoint<CreateFavoritePointEndpoint.Request, CreateFavoritePointEndpoint.Response>() {
    class Request(
        val favoritePointDto: FavoritePointDto
    )

    class Response(
        val favoritePointId: Long?
    )
}