package com.narbase.letsgo.web.views.user.base

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.height
import com.narbase.kunafa.core.css.width
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.routing.*
import com.narbase.laflif.web.utils.eventbus.EventBus
import com.narbase.letsgo.web.BasePageViewModel
import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.common.data.Privilege
import com.narbase.letsgo.web.events.HideBreadCrumbs
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.views.SideBar
import com.narbase.letsgo.web.utils.views.matchParentDimensions
import com.narbase.letsgo.web.views.user.profile.UserProfileComponent

class UserProfileBasePage : Component() {
    private val viewModel = UserProfileBaseViewModel()
    private val userProfileComponent = UserProfileComponent(viewModel)


    private var sideBar: SideBar<UserProfileTabs>? = null
    private fun getInitializedSideBar(): SideBar<UserProfileTabs> {
        val staticSideBarTabs = UserProfileTabs.tabs().map { SideBar.SubTab(it, it.routeDetails.title, it.routeDetails.image) }

        val allRoles = AuthorizedRole.values().toList()
        val allPrivileges = Privilege.values().toList()
        val mainReports = Pair(SideBar.MainTab("User profile".localized(), allRoles, allPrivileges), staticSideBarTabs)

        val map = mapOf(mainReports)

        return SideBar(map, onTabSelected = { Router.navigateTo(routeDetails.href + it.routeDetails.href) })
    }

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        EventBus.publish(HideBreadCrumbs())
        viewModel.selectedTab.observe {
            it ?: return@observe
            sideBar?.setTabSelected(SideBar.SubTab(it, it.routeDetails.title))
        }
    }

    override fun View?.getView(): View = horizontalLayout {
        style {
            matchParentDimensions
        }
        sideBar = getInitializedSideBar()
        populateView()
    }

    private fun View.populateView(): View = horizontalLayout {
        val sideBar = sideBar ?: return@horizontalLayout
        style {
            matchParentDimensions
        }

        mount(sideBar)

        verticalLayout {
            style {
                width = weightOf(1)
                height = matchParent
            }
            matchFirst {
                reportRouteComponents()
                redirect(reportsRoute(UserProfileTabs.userProfile), isAbsoluteDestination = true, from = routeDetails.href)
            }
        }
    }

    private fun LinearLayout.reportRouteComponents() {
        routeReportComponent(UserProfileTabs.userProfile) { userProfileComponent }
    }

    private fun View.routeReportComponent(tab: UserProfileTabs, block: (meta: RouteMeta) -> Component) =
            routeComponent(reportsRoute(tab.routeDetails.href), isAbsolute = true, block = block)

    private fun reportsRoute(tab: UserProfileTabs) = reportsRoute(tab.routeDetails.href)
    private fun reportsRoute(url: String) = "${routeDetails.href}$url"

    companion object {
        val routeDetails = BasePageViewModel.RouteDetails("/profile", "Profile".localized())
    }

}