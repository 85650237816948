package com.narbase.letsgo.web.views.callCenter.updateride

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideChangeabilityStatus
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.GetRideByIdEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.UpdateRideLocationsEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import kotlinx.browser.window

class UpdateRideViewModel(
    private val rideId: Long,
    var extendedRide: ExtendedRideDto
) {
    val getRideUiState = Observable<BasicUiState>()
    val updateUiState = Observable<BasicUiState>()
    var isLocationChangable: RideChangeabilityStatus? = RideChangeabilityStatus.Changeable

    fun updateRideLocations(pickup: LocationPointDto, destination: LocationPointDto?, priceToken: String?){

        basicNetworkCall(updateUiState) {
            val dto = UpdateRideLocationsEndpoint.Request(rideId, extendedRide.ride, pickup, destination, priceToken)
            isLocationChangable = UpdateRideLocationsEndpoint.remoteProcess(dto).data.rideChangeabilityStatus.enum()
        }
    }

}