/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] - [2022] Narbase Technologies
 * All Rights Reserved.
 * Created by Shalaga44
 * On: 2022/Jun/20.
 */

package com.narbase.letsgo.web.translations

val frenchMap = mapOf(
    "Current Staff" to "Personnel actuel",
    "Inactive Staff" to "Personnel inactif",
    "Billable Items" to "Articles facturables",
    "Staff Management" to "Gestion du personnel",
    "Billing and Insurance" to "Facturation et assurance",
    "Clinic systems" to "Systèmes cliniques",
    "Phone number" to "Numéro de téléphone",
    "Roles" to "Les rôles",
    "Admins" to "Administrateurs",
    "Receptionists" to "Réceptionnistes",
    "Add staff member" to "Ajouter un membre du personnel",
    "Edit staff member" to "Modifier le membre du personnel",
    "Disable user" to "Désactiver l'utilisateur",
    "Enable user" to "Activer l'utilisateur",
)