package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.models.RidePromoCodeStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.utils.views.withLoadingAndError


class RidePromoCodeRedeemDialog(
    private val viewModel: RidePromoCodeViewModel,
    private val onPromoCodeRedeemed: () -> Unit
) {
    private val popup by lazy { popUpDialog() }
    var statusTextView: TextView? = null
    var input: TextInput? = null

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Redeem".localized()
                        onClick = {
                            redeemPromoCode()
                        }
                    }.withLoadingAndError(viewModel.redeemPromoCodeUiState, onLoaded = {
                        if (viewModel.redeemPromocodeStatus == RidePromoCodeStatus.REDEEMED){
                            onPromoCodeRedeemed.invoke()
                            viewModel.redeemPromoCodeUiState.value = null
                            popup.dismissDialog()
                        } else {
                            statusTextView?.text = viewModel.redeemPromocodeStatus?.statusMessage() ?: ""
                        }
                    }, onRetryClicked = {
                        redeemPromoCode()
                    })

                }
            }

            verticalLayout {
                style {
                    height = wrapContent
                    width = 40.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                viewModel.redeemPromoCodeUiState.observe {
                    clearAllChildren()
                    horizontalLayout {
                        style {
                            width = matchParent
                            marginTop = 16.px
                            justifyContent = JustifyContent.Center
                        }
                        input = theme.textInput(this) {
                            placeholder = "promo code".localized()
                        }
                    }

                    statusTextView = theme.mediumTextView(this) {
                        style {
                            color = Color.red
                        }
                        text = ""
                    }
                }
            }

        }
    }

    private fun redeemPromoCode() {
        val inputText = input?.text

        if (inputText.isNullOrBlank()) {
            statusTextView?.text = "Please enter a promo code".localized()
        } else {
            viewModel.redeem(inputText)
        }
    }

    fun dismiss(){
        popup.dismissDialog()
    }
}