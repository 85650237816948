package com.narbase.letsgo.web.dto.config

import com.narbase.letsgo.web.dto.currency.CurrencyDto

class CurrencyIncentiveConfigDto (
    val id: Long?,
    val currencyId: Int,
    val orderingIncentiveConfig: OrderingIncentiveConfigDto,
    val timeoutIncentiveConfig: TimeoutIncentiveConfigDto
)
class ExtendedCurrencyIncentiveConfigDto (
    val currency: CurrencyDto,
    val currencyIncentiveConfig: CurrencyIncentiveConfigDto
)

