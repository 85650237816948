package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.rides.RideDto

object GetLatestPassengerRideEndpoint : EndPoint<GetLatestPassengerRideEndpoint.Request, GetLatestPassengerRideEndpoint.Response>() {
    class Request(
        val passengerId : Long,
    )

    class Response(
        val ride: RideDto?
    )
}