package com.narbase.letsgo.web.events

import org.w3c.dom.Element

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/06/06.
 */

class ElementFocused(val element: Element?)