package com.narbase.letsgo.web.views.callCenter.cancelride

import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.customRadio
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*


class CancelRideDialog(
    private val rideId: Long,
) {
    private val popup by lazy {
        popUpDialog(
            onDismissed = {}
        )
    }

    val viewModel = CancelRideViewModel(rideId)

    val reasons = arrayOf(
        "Just Trying".localized(),
        "Waited Too Long".localized(),
        "Captain is Far".localized(),
        "Captain asked me to cancel".localized(),
        "Car is not suitable".localized(),
        "I want to change booking info".localized(),
        "Other...".localized()
    )
    var selectReason: String? = null
    var reasonTextArea: TextArea? = null
    var errorTextView: TextView? = null

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Cancel Ride".localized()
                        onClick = {
                            viewModel.getRideDetails()
                        }
                    }.withLoadingAndError(viewModel.getRideUiState, onLoaded = {
                        viewModel.extendedRide?.let { it1 -> cancelRide(it1) }

                        withLoadingAndError(viewModel.cancelUiState, onLoaded = {
                            popup.dismissDialog()
                        }, onRetryClicked = {
                            viewModel.extendedRide?.let { it1 -> cancelRide(it1) }
                        }
                            )
                    }, onRetryClicked = {
                        viewModel.getRideDetails()
                    })

                }
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                }
                theme.bigTextView(this) {
                    text = "Cancel Ride"
                }
                verticalFiller(16)
                theme.label(this, "Reason: ", true)
                verticalLayout {
                    style {
                        width = matchParent
                    }
                    val unCheckRadioButtons: Observable<Boolean> = Observable()
                    unCheckRadioButtons.value = false
                    verticalLayout {
                        unCheckRadioButtons.observe { uncheck ->
                            clearAllChildren()
                            reasons.forEach { reason ->
                                customRadio(text = reason) {
                                    style { }
                                    element.name = "reason"
                                    if (uncheck == true) {
                                        element.setAttribute("checked", "false")
                                    }
                                    onClick = {
                                        setReason(reason)
                                    }
                                }
                            }
                        }
                    }
                    verticalFiller(15)
                    reasonTextArea = theme.textArea(this) {
                        style {
                            width = matchParent
                            padding = 4.px
                        }
                        placeholder = "Enter Reason"
                        onClick = {
                            unCheckRadioButtons.value = true
                            setReason(reasons.last())
                        }
                    }
                    verticalFiller(15)
                    errorTextView = theme.mediumTextView(this) {
                        style {
                            color = Color.red
                        }
                        text = ""
                    }
                }
            }
        }
    }

    fun setReason(reason: String) {
        selectReason = reason
    }

    fun cancelRide(extendedRideDto: ExtendedRideDto) {
        if(extendedRideDto.ride.status.enum() in RideStatus.driverCannotTerminateStatuses()){
            errorTextView?.text = "Ride Cannot be cancelled. Status: " + extendedRideDto.ride.status.enum()
            return
        }
        var reason = selectReason
        if (reason == reasons.last()) {
            if (reasonTextArea?.text.isNullOrBlank()) {
                errorTextView?.text = "Enter another reason"
            } else {
                reason = reasonTextArea?.text
            }
        }

        if (reason != null) {
            errorTextView?.text = ""
            viewModel.cancelRide(reason)
        } else {
            errorTextView?.text = "Select a reason"
        }
    }


}

