package com.narbase.letsgo.web.translations

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2023/04/09.
 */


val englishMap = mapOf(
        "VisitServiceRevenue" to "Visit Service Revenue",
        "TreatmentServiceRevenue" to "Treatment Service Revenue",
        "AdditionalServiceRevenue" to "Additional Service Revenue",
        "InsuranceProcessing" to "Insurance Processing",
        "StaffPayments" to "Staff Payments",
        "TaxesPayments" to "Taxes Payments",
)