package com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.driverprofilegroup.ExtendedDriverProfileGroupDto

object GetDriverProfileGroupsEndpoint : EndPoint<GetDriverProfileGroupsEndpoint.Request, GetDriverProfileGroupsEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String?,
    )

    class Response(
        val list: Array<ExtendedDriverProfileGroupDto>,
        val total: Int
    )
}