package com.narbase.letsgo.web.views.admin.auditlog

import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.utils.Object
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.string.splitCamelCase
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.popUpDialog
import kotlin.js.Json

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/03/03.
 */
class JsonViewerDialog {

    private val popUpDialog by lazy { popUpDialog() }

    var onDismissed: (() -> Unit)? = null
    fun show(title: String, jsonData: String, onDismissed: () -> Unit = {}) {
        this.onDismissed = onDismissed
        theme.showDialog(popUpDialog) {
            getView(title, jsonData)
            it.bottomBar = { bottomBar() }
        }
    }

    private fun View?.getView(title: String, jsonData: String) = verticalLayout {
        style {
            width = 90.vw
            height = wrapContent
        }

        verticalLayout {
            style {
                width = matchParent
                height = matchParent
            }

            theme.bigTextView(this) {
                text = title
            }
            verticalFiller(8)
            try {
                val json = JSON.parse<Json>(jsonData)
                prettyPrintObject(json)
            } catch (t: Throwable) {
                t.printStackTrace()
            }

            verticalFiller(16)
            theme.subTitle(this, "Raw")
            verticalFiller(8)
            horizontalLayout {
                style {
                    width = matchParent
                    border = "1px solid ${AppColors.borderColor}"
                    padding = 8.px
                    borderRadius = 4.px
                }
                textView {
                    style {
                        width = matchParent
                        fontFamily = "courier"
                        fontSize = 12.px
                        color = AppColors.text
                    }
                    text = jsonData
                }
            }

        }
    }

    private fun View.prettyPrintObject(o: Any) {
        val props: Array<Array<Any>> = objectToProperties(o)
        props.forEachIndexed { index, items ->
            val key = items[0]
            val value = items[1]
            horizontalLayout {
                style {
                    width = matchParent
                    padding = 4.px
                    if (index != props.lastIndex) {
                        borderBottom = "1px solid ${AppColors.borderColor}"
                    }
                }
                horizontalLayout {
                    style {
                        width = 120.px
                    }
                    textView {
                        style {
                            width = matchParent
                            color = AppColors.textLight
                            fontSize = 14.px
                        }
                        text = key.toString().splitCamelCase()
                    }
                }

                if (isPrimitive(value)) {
                    textView {
                        style {
                            width = weightOf(1)
                            color = AppColors.text
                            fontSize = 14.px
                        }
                        text = value.toString()
                    }
                } else {
                    verticalLayout {
                        style {
                            width = weightOf(1)
                        }
                        prettyPrintObject(value)
                    }
                }
            }
        }
    }

    private fun objectToProperties(o: Any): Array<Array<Any>> {
        return Object.asDynamic().entries(o) as Array<Array<Any>>

    }

    private fun isPrimitive(o: Any): Boolean {
        return !(jsTypeOf(o) == "object" || jsTypeOf(o) == "function")

    }

    private fun LinearLayout.bottomBar() {
        horizontalLayout {
            style {
                width = matchParent
            }
            horizontalFiller()
            theme.mainButton(this) {
                text = "Dismiss"
                onClick = {
                    dismiss()
                }
            }
        }
    }

    fun dismiss() {
        onDismissed?.invoke()
        popUpDialog.dismissDialog()
    }
}
