package com.narbase.letsgo.web.theme

import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2023/01/30.
 */
fun setupGlobalStyles() {
    setupShakeGlobalStyles()
    createTippyCustomStyles()
}

fun setupShakeGlobalStyles() {
    keyframes("shake") {
        addKeyframeRule("10%, 90%") {
            transform = "translate3d(-1px, 0, 0)"
        }

        addKeyframeRule("20%, 80%") {
            transform = "translate3d(2px, 0, 0)"
        }

        addKeyframeRule("30%, 50%, 70%") {
            transform = "translate3d(-4px, 0, 0)"
        }

        addKeyframeRule("40%, 60%") {
            transform = "translate3d(4px, 0, 0)"
        }
    }
    keyframes("continuous_rotation") {
        from {
            transform = "rotate(0deg)"
        }

        to {
            transform = "rotate(-360deg)"
        }
    }

}

private fun createTippyCustomStyles() {
    try {
        stringRuleSet(".tippy-tooltip.popover-theme") {
            borderRadius = 12.px
            padding = 0.px
            backgroundColor = Color.white
            color = Color.black
            boxShadow = "0px 6px 8px 1px rgba(0,0,0,0.29)"
        }
        stringRuleSet(".tippy-popper[x-placement^=top] .tippy-tooltip.popover-theme .tippy-arrow") {
            borderTopColor = Color.white
        }
        stringRuleSet(".tippy-popper[x-placement^=bottom] .tippy-tooltip.popover-theme .tippy-arrow") {
            borderBottomColor = Color.white
        }
        stringRuleSet(".tippy-popper[x-placement^=left] .tippy-tooltip.popover-theme .tippy-arrow") {
            borderLeftColor = Color.white
        }
        stringRuleSet(".tippy-popper[x-placement^=right] .tippy-tooltip.popover-theme .tippy-arrow") {
            borderRightColor = Color.white
        }
    } catch (e: dynamic) {
        console.log(e)
    }
}
