package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.GetPassengerRidesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.RateRideAdminEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import kotlinx.browser.window

class CallCenterViewModel {

    val ridesUiState = Observable<BasicUiState>()
    var data: ExtendedPassengerDto? = null
    var ongoingRides: List<ExtendedRideDto> = emptyList()
    var otherRides: List<ExtendedRideDto> = emptyList()
    var cancelledRides: List<ExtendedRideDto> = emptyList()
    var allPastRides: List<ExtendedRideDto> = emptyList()
    val rateRideUiState = Observable<BasicUiState>()
    private var getRidesTimerHandlerId: Int? = null

    fun setUpRideDetails(passengerId: Long) {
        getRides(passengerId)
        getRidesTimerHandlerId = window.setInterval(
            {
                getRides(passengerId)
            }, 10000
        )
    }

    fun getRides(passengerId: Long) {

        basicNetworkCall(ridesUiState) {
            val dto = GetPassengerRidesEndpoint.Request(0, 20, passengerId, null)
            val response = GetPassengerRidesEndpoint.remoteProcess(dto).data
            allPastRides = response.list.toList()
            ongoingRides = response.list.toList().filter {
                it.ride.status.enum() in RideStatus.transientStates()
            }
            otherRides = response.list.filter {
                it.ride.status.enum() !in  RideStatus.transientStates()
            }
        }
    }

    fun rateRide(rideId: Long, rating: Double, review: String){
        basicNetworkCall(rateRideUiState) {
            val dto = RateRideAdminEndpoint.Request(rideId = rideId, rating = rating, review = review )
            RateRideAdminEndpoint.remoteProcess(dto).data
        }
    }

    fun clearRideDetailsTimer() {
        getRidesTimerHandlerId?.let { window.clearInterval(it) }
    }

}