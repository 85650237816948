package com.narbase.letsgo.web.routing.domain.admin.ridepromocodes

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.models.RidePromoCodeStatus

object RedeemRidePromoCodeEndpoint :
    EndPoint<RedeemRidePromoCodeEndpoint.Request, RedeemRidePromoCodeEndpoint.Response>() {
    class Request(
        val passengerId: Long,
        val promoCode: String
    )

    class Response(
        val status: DtoName<RidePromoCodeStatus>,
        val amount: Double? = null
    )
}