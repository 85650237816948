//@file:JsQualifier("google.maps")

package com.narbase.almutkhassisa.web.views.googlemaps
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2019/04/03.
 */

@JsName("google")
external object google {
    @JsName("maps")
    object Maps {

        interface Map {
            fun setCenter(latLng: LatLng)
            fun setZoom(zoom: Float)
            fun getCenter(): LatLng
            fun setOptions(options: dynamic)
            fun addListener(event: String, callback: () -> Unit)
            fun getBounds(): MapBounds
            fun getZoom() : Float
        }

        interface MapBounds {
            fun getSouthWest(): LatLng
            fun getNorthEast(): LatLng

        }

        interface Marker {
            fun setMap(map: Map?)

            fun addListener(event: String, callback: () -> Unit)
        }

        interface LatLng {
            fun lat(): Double
            fun lng(): Double
        }

        object event {
        }

        interface PinElement {
            val element : Element
        }
    }
}


inline fun google.Maps.Map(element: Element?, options: dynamic): google.Maps.Map =
        js("new google.maps.Map(element, options)").unsafeCast<google.Maps.Map>()

inline fun google.Maps.Marker(options: dynamic): google.Maps.Marker =
        js("new google.maps.Marker(options)").unsafeCast<google.Maps.Marker>()

inline fun google.Maps.LatLng(lat: Double, lng: Double): google.Maps.LatLng =
        js("new google.maps.LatLng(lat, lng)").unsafeCast<google.Maps.LatLng>()

inline fun google.Maps.event.addListener(marker: google.Maps.Marker, event: String, callback: () -> Unit) =
        js("google.maps.event.addListener(marker, event, callback)")

inline fun google.Maps.AdvancedMarkerElement(options: dynamic): google.Maps.Marker =
    js("new google.maps.marker.AdvancedMarkerElement(options)").unsafeCast<google.Maps.Marker>()

inline fun google.Maps.PinElement(options: dynamic) : google.Maps.PinElement  =
    js("new google.maps.marker.PinElement(options)").unsafeCast<google.Maps.PinElement>()
