package com.narbase.letsgo.web.views.admin.utils

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.PopupZIndex
import com.narbase.letsgo.web.utils.views.pointerCursor
import com.narbase.letsgo.web.utils.views.unSelectable
import disableBlurOptions
import tippy
import kotlin.js.json
import kotlin.random.Random

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/03/06.
 */
class ColorPicker(
        private val title: String = "Color".localized(),
        private val defaultColor: Color = Color.white,
        private val onChange: ((Color) -> Unit)? = null
) : Component() {
    private var tippyInstance: dynamic = null
    private var colorIndicatorView: View? = null
    private var selectionButton: View? = null

    private val tippyOptions by lazy {
        json(
                "interactive" to true,
                "trigger" to "click",
                "theme" to "light-border",
                "placement" to "bottom-start",
                "arrow" to "large",
                "content" to colorSelectionView.element,
                "zIndex" to PopupZIndex.getTopIndex(),
                "distance" to 5,
                "maxWidth" to "none",
                disableBlurOptions
        )
    }

    private val colorSelectionView by lazy {
        detached.verticalLayout {
            style {
                padding = 2.px
            }
            colors.forEach { row ->
                horizontalLayout {
                    style {
                        padding = 2.px
                    }
                    row.forEach { color ->
                        colorOption(color)
                    }
                }
            }
        }
    }

    private fun LinearLayout.colorOption(color: Color) {
        view {
            style {
                width = 16.px
                height = 16.px
                backgroundColor = color
                margin = 2.px
                pointerCursor()
            }
            onClick = {
                colorIndicatorView?.element?.style?.backgroundColor = color.toCss()
                tippyInstance.hide()
                onChange?.invoke(color)
            }
        }
    }

    override fun View?.getView() = verticalLayout {
        style {
            width = matchParent
        }

        selectionButton = horizontalLayout {
            style {
                width = matchParent
                padding = 2.px
                pointerCursor()
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 4.px
                alignItems = Alignment.Center
                hover {
                    backgroundColor = AppColors.extraLightBackground
                }
            }
            tippyInstance = tippy(element, tippyOptions)

            colorIndicatorView = view {
                style {
                    width = 28.px
                    height = 28.px
                    border = "1px solid ${AppColors.borderColor}"
                    backgroundColor = defaultColor
                    borderRadius = 4.px
                    margin = 2.px
                }
            }
            textView {
                text = "Click to change".localized()
                style {
                    width = weightOf(1)
                    unSelectable()
                    padding = 4.px
                    color = AppColors.textDarkerGrey
                }
            }
        }
//        onChange?.let { textInput.onChange = { onChange() } }
    }

    companion object {

        fun getRandomColor(): Color {
            val totalList = colors.flatten()
            val colorIndex = Random.Default.nextInt(0, totalList.size)
            return totalList[colorIndex]
        }

        val colors = listOf(
                listOf(
                        Color("#33cccc"),
                        Color("#279d9d"),
                        Color("#104040"),
                        Color("#eaf437"),
                        Color("#c5cf0b"),
                        Color("#767c07")
                ),
                listOf(
                        Color("#cf4b4b"),
                        Color("#a12b2b"),
                        Color("#4d1414"),
                        Color("#3ca228"),
                        Color("#266719"),
                        Color("#1c4c12")
                ),
                listOf(
                        Color("#b24da3"),
                        Color("#813876"),
                        Color("#451e40"),
                        Color("#4d42bd"),
                        Color("#2d266e"),
                        Color("#18143a")
                ),
                listOf(
                        Color("#6ab775"),
                        Color("#3a7843"),
                        Color("#1c3b21"),
                        Color("#d28515"),
                        Color("#905b0e"),
                        Color("#5b3909")
                ),
                listOf(
                        Color("#ffffff"),
                        Color("#cbcbcb"),
                        Color("#9b9b9b"),
                        Color("#606060"),
                        Color("#2f2f2f"),
                        Color("#000000"))
        )

    }
}
