package com.narbase.letsgo.dto.core.entries

import com.narbase.letsgo.dto.core.DateTimeDto
import kotlin.reflect.KClass


sealed class AnswerDto(val type: String, val entryDto: EntryDto)

class TextViewAnswerDto(entry: TextViewEntryDto) : AnswerDto(EntryTypes.TextView.name, entry)
class SeparatorAnswerDto(entry: SeparatorEntryDto) : AnswerDto(EntryTypes.Separator.name, entry)

class TextInputAnswerDto(val text: String?, entryDto: TextInputEntryDto) :
        AnswerDto(EntryTypes.TextInput.name, entryDto)

class McqAnswerDto(val selectedOption: Int?, entryDto: McqEntryDto) : AnswerDto(EntryTypes.Mcq.name, entryDto)
class CheckListAnswerDto(val selectedOptions: Array<Int>?, entryDto: CheckListEntryDto) :
        AnswerDto(EntryTypes.CheckList.name, entryDto)

class ImageAnswerDto(val imageUrl: String?, entryDto: ImageEntryDto) : AnswerDto(EntryTypes.Image.name, entryDto)
class SignatureAnswerDto(val imageUrl: String?, entryDto: SignatureEntryDto) :
        AnswerDto(EntryTypes.Signature.name, entryDto)

class DateAnswerDto(val date: DateTimeDto?, entryDto: DateEntryDto) : AnswerDto(EntryTypes.Date.name, entryDto)
class GroupAnswerDto(val answers: Array<AnswerDto>?, entryDto: GroupEntryDto) :
        AnswerDto(EntryTypes.Group.name, entryDto)


sealed class EntryDto(val type: String, val id: String?) {
    var conditions: Array<EntryCondition>? = null
}

class TextViewEntryDto(val text: String, id: String?) : EntryDto(EntryTypes.TextView.name, id)
class SeparatorEntryDto(val color: String, id: String?) : EntryDto(EntryTypes.Separator.name, id)

class TextInputEntryDto(
        val text: String,
        id: String?,
        val data: TextInputEntryDataDto? = null
) :
        EntryDto(EntryTypes.TextInput.name, id)

class TextInputEntryDataDto(
        val isRequired: Boolean,
        val match: String?,
)

class McqEntryDto(val text: String, val options: Array<McqOptionDto>, id: String?) :
        EntryDto(EntryTypes.Mcq.name, id)

class McqOptionDto(val text: String, val id: Int)

class SimpleOptionDto(val text: String, val id: String)
class CheckListEntryDto(
        val text: String,
        val options: Array<CheckListOptionDto>, id: String?,
        val data: CheckListEntryDataDto? = null) :
        EntryDto(EntryTypes.CheckList.name, id)

class CheckListEntryDataDto
class CheckListOptionDto(val text: String, val id: Int)
class ImageEntryDto(val text: String, id: String?) : EntryDto(EntryTypes.Image.name, id)
class SignatureEntryDto(val text: String, id: String?) : EntryDto(EntryTypes.Signature.name, id)
class DateEntryDto(val text: String, id: String?) : EntryDto(EntryTypes.Date.name, id)
class GroupEntryDto(val text: String, val entries: Array<EntryDto>, id: String?) :
        EntryDto(EntryTypes.Group.name, id)

class CustomEntryDto(val name: String, val data: String, id: String?) : EntryDto(EntryTypes.Custom.name, id = id)
class CustomAnswerDto(entry: CustomEntryDto, val answer: String? = null) : AnswerDto(EntryTypes.Custom.name, entry)


class DropDownListEntryDto(val text: String, val options: Array<SimpleOptionDto>, id: String?, val showSearch: Boolean?) : EntryDto(EntryTypes.DropDownList.name, id)
class DropDownListAnswerDto(val selectedOptionId: String?, entryDto: DropDownListEntryDto) : AnswerDto(EntryTypes.DropDownList.name, entryDto)

class RemoteDropDownListEntryDto(
        val text: String,
        val endpoint: String, id: String?,
        val showSearch: Boolean?
) : EntryDto(EntryTypes.RemoteDropDownList.name, id)

class RemoteDropDownListAnswerDto(
        val selectedOption: SimpleOptionDto?,
        entryDto: RemoteDropDownListEntryDto,
) : AnswerDto(EntryTypes.RemoteDropDownList.name, entryDto) {
    class Request(val pageNo: Int, val pageSize: Int, val searchTerm: String?)
    class Response(val list: Array<SimpleOptionDto>)

}

class CheckDropDownListEntryDto(
        val text: String,
        val options: Array<SimpleOptionDto>,
        id: String?,
        val showSearch: Boolean?
) : EntryDto(EntryTypes.CheckDropDownList.name, id)

class CheckDropDownListAnswerDto(
        val selectedOptionIds: Array<String>,
        entryDto: CheckDropDownListEntryDto
) : AnswerDto(EntryTypes.CheckDropDownList.name, entryDto)

class RemoteCheckDropDownListEntryDto(
        val text: String,
        val endpoint: String, id: String?,
        val showSearch: Boolean?
) : EntryDto(EntryTypes.RemoteCheckDropDownList.name, id)

class RemoteCheckDropDownListAnswerDto(
        val selectedOptions: Array<SimpleOptionDto>,
        entryDto: RemoteCheckDropDownListEntryDto,
) : AnswerDto(EntryTypes.RemoteCheckDropDownList.name, entryDto) {
    class Request(val pageNo: Int, val pageSize: Int, val searchTerm: String?)
    class Response(val list: Array<SimpleOptionDto>)
}


private val answerDtoClassToName = EntryTypes.values().map {
    val targetClass = when (it) {
        EntryTypes.TextView -> TextViewAnswerDto::class
        EntryTypes.Separator -> SeparatorAnswerDto::class
        EntryTypes.TextInput -> TextInputAnswerDto::class
        EntryTypes.Image -> ImageAnswerDto::class
        EntryTypes.Signature -> SignatureAnswerDto::class
        EntryTypes.Date -> DateAnswerDto::class
        EntryTypes.Mcq -> McqAnswerDto::class
        EntryTypes.CheckList -> CheckListAnswerDto::class
        EntryTypes.Group -> GroupAnswerDto::class
        EntryTypes.Custom -> CustomAnswerDto::class
        EntryTypes.DropDownList -> DropDownListAnswerDto::class
        EntryTypes.RemoteDropDownList -> RemoteDropDownListAnswerDto::class
        EntryTypes.CheckDropDownList -> CheckDropDownListAnswerDto::class
        EntryTypes.RemoteCheckDropDownList -> RemoteCheckDropDownListAnswerDto::class
    }
    targetClass to it.name
}

val answerDtoToTypeMap: Map<KClass<out AnswerDto>, String> = mapOf(*(answerDtoClassToName.toTypedArray()))


private val entryDtoClassToName = EntryTypes.values().map {
    val targetClass = when (it) {
        EntryTypes.TextInput -> TextInputEntryDto::class
        EntryTypes.Mcq -> McqEntryDto::class
        EntryTypes.CheckList -> CheckListEntryDto::class
        EntryTypes.Image -> ImageEntryDto::class
        EntryTypes.Signature -> SignatureEntryDto::class
        EntryTypes.TextView -> TextViewEntryDto::class
        EntryTypes.Date -> DateEntryDto::class
        EntryTypes.Group -> GroupEntryDto::class
        EntryTypes.Separator -> SeparatorEntryDto::class
        EntryTypes.Custom -> CustomEntryDto::class
        EntryTypes.DropDownList -> DropDownListEntryDto::class
        EntryTypes.RemoteDropDownList -> RemoteDropDownListEntryDto::class
        EntryTypes.CheckDropDownList -> CheckDropDownListEntryDto::class
        EntryTypes.RemoteCheckDropDownList -> RemoteCheckDropDownListEntryDto::class
    }
    targetClass to it.name
}

val entryDtoToTypeMap: Map<KClass<out EntryDto>, String> = mapOf(*(entryDtoClassToName.toTypedArray()))