package com.narbase.letsgo.web.views.callCenter.pointselection

import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.views.callCenter.RequestRideViewModel

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/06/05.
 */
class AddFavouritePointDialog(
    private val viewModel: RequestRideViewModel
) {
    private val namePopup by lazy { popUpDialog() }

    fun nameDialog(item: LocationPointDto, onFavorited: (LocationPointDto) -> Unit) {
        theme.showDialog(namePopup) {
            verticalLayout {
                style {
                    height = wrapContent
                    width = 40.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                        justifyContent = JustifyContent.Center
                    }
                    val input = theme.textInput(this) {
                        placeholder = "name"
                    }
                    horizontalFiller(10)
                    theme.mainButton(this) {
                        text = "Favorite"
                        onClick = {
                            val favorite = LocationPointDto(
                                lat = item.lat,
                                lng = item.lng,
                                name = input.text,
                                address = item.address ?: "",
                                pointOfInterestDto = null,
                                favoritePointDto = null
                            )
                            val favoritePointDto = viewModel.addFavoritePoint(favorite)
                            viewModel.createFavotriteUiState.observe { basicUiState ->
                                if (basicUiState == BasicUiState.Loaded) {
                                    if (favoritePointDto != null) {
                                        val locationPoint = LocationPointDto(
                                            lat = item.lat,
                                            lng = item.lng,
                                            name = input.text,
                                            address = item.address ?: "",
                                            pointOfInterestDto = null,
                                            favoritePointDto = favoritePointDto
                                        )
                                        onFavorited.invoke(locationPoint)
                                    }
                                }

                            }
                            namePopup.dismissDialog()
                        }
                    }
                }
            }

        }
    }

}