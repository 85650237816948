package com.narbase.letsgo.web.views.passengerProfileGroups

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto
import com.narbase.letsgo.web.dto.passengerprofilegroup.ExtendedPassengerProfileGroupDto
import com.narbase.letsgo.web.dto.passengerprofilegroup.PassengerProfileGroupDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.cartypes.GetCarTypesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengerprofilegroup.GetPassengerProfileGroupsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengerprofilegroup.UpsertPassengerProfileGroupEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class PassengerProfileGroupsManagementViewModel {

    private var searchTerm = ""
    val uiState = Observable<BasicUiState>()
    val upsertUiState = Observable<BasicUiState>()
    val carTypesUiState = Observable<BasicUiState>()
    var data: List<ExtendedPassengerProfileGroupDto> = listOf()
    var carTypes: List<CarTypeDto> = listOf()

    var pageNo = 0
    var pageSize = 20
    var total = 0

    fun getList() {
        upsertUiState.value = null
        basicNetworkCall(uiState) {
            val dto = GetPassengerProfileGroupsEndpoint.Request(pageNo, pageSize, searchTerm = searchTerm)
            val response = GetPassengerProfileGroupsEndpoint.remoteProcess(dto).data
            data = response.list.toList()
            total = response.total
        }
    }

    fun searchFor(term: String) {
        pageNo = 0
        searchTerm = term
        uiState.value = BasicUiState.Loaded
        getList()
    }

    fun getNextPage() {
        pageNo++
        getList()
    }

    fun getPreviousPage() {
        pageNo--
        getList()
    }

    fun setUpsertUiStateToNull() {
        upsertUiState.value = null
    }

    fun addItem(dto: PassengerProfileGroupDto) {
        basicNetworkCall(upsertUiState) {
            UpsertPassengerProfileGroupEndpoint.remoteProcess(UpsertPassengerProfileGroupEndpoint.Request(dto))
        }
    }

    fun getCarTypesList(carTypeSearchTerm: String) {
        basicNetworkCall(carTypesUiState) {
            val response = GetCarTypesEndpoint.remoteProcess(GetCarTypesEndpoint.Request(0, 100, carTypeSearchTerm))
            carTypes = response.data.list.toList()
        }
    }
}