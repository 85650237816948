package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint

object ReorderRideAdminEndpoint
    : EndPoint<ReorderRideAdminEndpoint.Request, ReorderRideAdminEndpoint.Response>() {
    class Request(
        val passengerId: Long,
        val rideId: Long,
        val priceToken: String?,
    )

    class Response(
        val requestId: Long
    )
}