package com.narbase.letsgo.web

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.data.MedicoModules
import com.narbase.letsgo.web.common.data.Privilege
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.notifications.NotificationsController


class BasePageViewModel {

    val selectedTab = Observable<Tabs>()

    data class RouteDetails(var href: String, val title: String, val image: String? = null)

    enum class Tabs(val title: String, val imageUrl: String, val id: String, val routeDetails: RouteDetails, val privileges: List<Privilege>, val requiredModule: MedicoModules?) {
        Dashboard(
                "Dashboard".localized(),
                "/public/img/main_menu_dashboard.svg",
                "dashboard",
                RouteDetails("/dashboard", "Dashboard".localized()),
                listOf(),
                MedicoModules.DashboardAndReports),
        ;

        open fun getCustomComponent(): Component? = null

        companion object {
            fun mainTabs(): List<Tabs> = listOf(Dashboard)
        }
    }

    fun onViewCreated() {
        NotificationsController.connect()
    }


}
