package com.narbase.letsgo.web.common.data

import com.narbase.letsgo.web.network.EnumDtoName

enum class AuthorizedRole(override val dtoName: String, val displayName: String) : EnumDtoName {
    Receptionist("Receptionist", "Receptionist"),
    Admin("Admin", "Admin"),
    Doctor("Doctor", "Doctor"),
    ChainAdmin("ChainAdmin", "Chain Admin"),
}
