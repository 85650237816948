package com.narbase.letsgo.web.models

import com.narbase.letsgo.dto.EnumDtoName

enum class RideStatus(dtoName: String? = null) : EnumDtoName {
    pending,
    accepted,
    onTheWay,
    arrivedAtPickup,
    passengerOnBoard,
    arrivedAtDestination,
    pendingPayment,
    completed,
    cancelled,
    timedout,
    terminatedByDriver
    ;

    override val dtoName = dtoName ?: this.name

    companion object {

        fun transientStates() = listOf(
            pending,
            accepted,
            onTheWay,
            arrivedAtPickup,
            passengerOnBoard,
            arrivedAtDestination,
            pendingPayment
        )

        fun hiddenDiscountStatuses() = listOf(pending, accepted)

        fun driverCannotTerminateStatuses() =
            listOf(passengerOnBoard, arrivedAtDestination, pendingPayment, completed, cancelled, timedout)

        fun locationsEditableStates() = listOf(
            pending,
            accepted,
            onTheWay,
            arrivedAtPickup
        )


    }
}