package com.narbase.letsgo.web.views.admin.staff

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.network.enum
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.utils.views.customViews.CustomCheckBox
import com.narbase.letsgo.web.utils.views.customViews.customCheckBox

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/19.
 */
class StaffManagementComponent(private val showCurrent: Boolean) : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = StaffManagementViewModel()
    private var adminsCheckbox: CustomCheckBox? = null
    private var doctorsCheckbox: CustomCheckBox? = null
    private var receptionistsCheckbox: CustomCheckBox? = null
    private val upsertDialog = UpsertStaffMemberDialog(viewModel)

    private var listTableBody: View? = null
    private var contentLayout: View? = null

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        contentLayout?.withLoadingAndError(viewModel.uiState, onRetryClicked = {
            viewModel.getStaff()
        }, onLoaded = {
            onListLoaded()
        })
    }

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getStaff()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.data.forEachIndexed { index, item ->
                tableRow {
                    id = item.admin.fullName ?: item.admin.email
                    tableCell(item.admin.fullName ?: "[NO NAME]", 3, 16.px)

                    val roles = item.roles.map { it.name }.sorted()
                    tableCell(roles.joinToString(), 1, 14.px)
                    onClick = {
                        upsertDialog.edit(item)
                    }
                }

                if (index != viewModel.data.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "staffManagementRootView"
        mount(upsertDialog)
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            contentLayout = verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = if (showCurrent) "Current Staff".localized() else "Inactive Staff".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()

                    if (showCurrent) {
                        addStaffButton()
                    }


                }

                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }

                    horizontalFiller()
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                listTableBody = listTable {
                    headerCell("Full name".localized(), 3)
                    headerCell("Roles".localized(), 1)
                }
                paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
            }
        }
    }

    @Suppress("unused") // Will be used when we add filter for dynamic roles
    private fun LinearLayout.rolesFilter() {
        adminsCheckbox = customCheckBox("Admins".localized(), true) {
            updateFilterList()
        }
        view { style { width = 16.px } }
        doctorsCheckbox = customCheckBox("Doctors".localized(), true) {
            updateFilterList()

        }
        view { style { width = 16.px } }
        receptionistsCheckbox = customCheckBox("Receptionists".localized(), true) {
            updateFilterList()

        }
        view { style { width = 16.px } }
    }

    private fun LinearLayout.addStaffButton() {
        textView {
            style {
                color = Color.white
                padding = "2px 12px".dimen()
                backgroundColor = AppColors.mainColor
                borderRadius = 12.px
                pointerCursor()
                hover {
                    backgroundColor = AppColors.mainDarkColor
                }
            }

            onClick = {
                upsertDialog.add()
            }

            id = "AddStaffButton"
            text = "+ Add new staff"
        }
    }

    private fun updateFilterList() {
        val roles = mutableListOf<AuthorizedRole>()
        if (adminsCheckbox?.isSelected == true) roles.add(AuthorizedRole.Admin)
        if (doctorsCheckbox?.isSelected == true) roles.add(AuthorizedRole.Doctor)
        if (receptionistsCheckbox?.isSelected == true) roles.add(AuthorizedRole.Receptionist)
        viewModel.filterStaffBy(roles)
    }


}
