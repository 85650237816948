package com.narbase.letsgo.web.routing.domain.admin.incentives

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.orderingIncentives.OrderingIncentiveRmDto

object GetOrderingIncentivesEndpoint : EndPoint<GetOrderingIncentivesEndpoint.Request, GetOrderingIncentivesEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String

    )

    class Response(
        val list: Array<OrderingIncentiveRmDto>,
        val total: Int
    )
}