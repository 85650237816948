package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.matchParentDimensions
import com.narbase.letsgo.web.utils.views.pointerCursor
import com.narbase.letsgo.web.utils.views.singleLine
import com.narbase.letsgo.web.utils.views.withLoadingAndError
import com.narbase.letsgo.web.views.callCenter.rating.RateRideDialog
import com.narbase.letsgo.web.views.callCenter.rating.RatingComponent
import com.narbase.letsgo.web.views.callCenter.updateride.UpdateRideDialog

class CallCenterComponent : Component() {

    private val viewModel = CallCenterViewModel()

    private val selectedPassengerDto: Observable<ExtendedPassengerDto?> = Observable<ExtendedPassengerDto?>()

    private fun setSelectedPassenger(extendedPassenger: ExtendedPassengerDto?) {
        selectedPassengerDto.value = extendedPassenger
    }

    override fun View?.getView() = view {
        id = "callCenterRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                }
                selectedPassengerDto.observe { extendedPassengerDto ->
                    clearAllChildren()
                    if (extendedPassengerDto == null) {
                        noPassengerSelectedView()
                    } else {
                        selectedPassengerLayout(extendedPassengerDto)
                    }
                }
            }
        }
    }

    private fun View.noPassengerSelectedView() {
        horizontalLayout {
            style {
                width = matchParent
                alignItems = Alignment.Center
                padding = 24.px
            }
            titleView()
            horizontalFiller()
            textView {
                text = "No Passenger Selected".localized()
                style {
                    color = AppColors.textLight
                }
            }
            horizontalFiller()
            theme.mainMenuButton(this) {
                text = "Select Passenger"
                onClick = { selectPassengerDialog() }
            }
        }

    }

    private fun LinearLayout.titleView() {
        textView {
            text = "Call Center".localized()
            style {
                width = wrapContent
                fontSize = 20.px
                fontWeight = "bold"
            }
        }
    }


    private fun View.selectedPassengerLayout(extendedPassengerDto: ExtendedPassengerDto) {
        val passengerId = extendedPassengerDto.passenger.id ?: return
        selectedPassengerHeader()

        horizontalLayout {
            style {
                width = matchParent
            }

            verticalLayout {
                style {
                    width = 30.vw
                    borderEnd = "1px solid ${AppColors.separatorLight}"
                    padding = 24.px
                }
                theme.bigTextView(this) {
                    text = "Passenger details"
                }
                theme.label(this, "Passenger".localized(), false)
                theme.mediumTextView(this) {
                    text = extendedPassengerDto.passenger.fullName
                }
                theme.label(this, "Email / Phone".localized(), false)
                theme.mediumTextView(this) {
                    text = extendedPassengerDto.passenger.email
                }
                profileGroupView(extendedPassengerDto)
                verticalFiller(15)
                viewModel.setUpRideDetails(passengerId)
                verticalLayout {
                    style {
                        width = matchParent
                    }
                    horizontalLayout {
                        style {
                            width = matchParent
                            alignContent = Alignment.End
                        }
                        horizontalFiller()
                        horizontalLayout {
                            style {
                                width = wrapContent
                                alignContent = Alignment.Center
                                color = AppColors.activeColor
                            }
                            withLoadingAndError(viewModel.ridesUiState,
                                onRetryClicked = {
                                    viewModel.setUpRideDetails(passengerId)
                                },
                                onLoaded = {
                                    clearAllChildren()
                                    theme.simpleLabel(this) {
                                        text = "Past rides up-to-date"
                                        style {
                                            color = AppColors.activeColor
                                        }
                                    }
                                })
                        }
                    }
                    verticalLayout{
                        style {
                            width = matchParent
                        }
                        viewModel.ridesUiState.observe {
                            if (it  == BasicUiState.Loaded){
                                clearAllChildren()
                                populatePastRides()
                            }

                        }

                    }
                }
            }
//            horizontalFiller(8)
//            horizontalSeparator()
//            horizontalFiller(8)
            verticalLayout {
                style {
                    width = weightOf(1)
                }
                mount(RequestRideComponent(extendedPassengerDto.passenger, viewModel) {
                    setSelectedPassenger(null)
                    selectPassengerDialog()
                })
            }
        }
    }

    private fun LinearLayout.profileGroupView(extendedPassengerDto: ExtendedPassengerDto) {
        verticalLayout {
            style {
                width = matchParent
            }
            verticalLayout {
                style {
                    width = matchParent
                    isVisible = !extendedPassengerDto.profileGroup.isHiddenFromCallCenter
                }
                theme.label(this, "Profile Group ".localized(), false)
                theme.mediumTextView(this) {
                    text = extendedPassengerDto.profileGroup.name
                }
            }

            val commissionText =
                if (extendedPassengerDto.profileGroup.commission < 0) "Profile Group Discount".localized() else "Profile Group Commission".localized()
            theme.label(this, commissionText, false)
            theme.mediumTextView(this) {
                style {
                    backgroundColor =
                        if (extendedPassengerDto.profileGroup.commission < 0) AppColors.mainColor else AppColors.lightDarkBackground
                    color = Color.white
                    padding = 5.px
                    borderRadius = 5.px
                }
                text =
                    (if (extendedPassengerDto.profileGroup.commission < 0) (-extendedPassengerDto.profileGroup.commission).toString() else
                        extendedPassengerDto.profileGroup.commission.toString()) + if (extendedPassengerDto.profileGroup.isCommissionPercentage) "%" else "" //show currency
            }
        }
    }

    private fun View.selectedPassengerHeader() {
        horizontalLayout {
            style {
                width = matchParent
                alignItems = Alignment.Center
                padding = 24.px
                borderBottom = "1px solid ${AppColors.separatorLight}"
            }

            titleView()

            horizontalFiller(16)
            horizontalFiller()
            theme.mainMenuButton(this) {
                text = "Clear passenger".localized()
                onClick = {
                    setSelectedPassenger(null)
                    viewModel.clearRideDetailsTimer()
                    selectPassengerDialog()
                }
            }

        }
    }

    private fun View.populatePastRides() {

        clearAllChildren()
        if (viewModel.allPastRides.isNotEmpty()) {
            theme.label(this, "Ongoing Rides", false)
            viewModel.ongoingRides.forEach { item ->
                rideItemLayout(item)
                verticalFiller(10)
            }
            theme.label(this, "Other Past Rides", false)
            viewModel.otherRides.forEach { item ->
                rideItemLayout(item)
                verticalFiller(10)
            }
        } else {
            horizontalLayout {
                style {
                    width = matchParent
                    justifyContent = JustifyContent.Center
                    padding = 24.px
                }
                textView {
                    text = "No Rides Yet".localized()
                    style {
                        color = AppColors.textLight
                    }
                }
            }
        }

    }

    private fun View.rideItemLayout(item: ExtendedRideDto) = verticalLayout {
        style {
            width = matchParent
            padding = 8.px
            border = "1px solid ${AppColors.borderColor}"
            borderRadius = 4.px
            pointerCursor()
            hover {
                backgroundColor = AppColors.lightBackground
            }
        }
        theme.text(this) {
            textView {
                style { width = matchParent }
                text = item.ride.time.toDate().toDateString()
            }
        }
        horizontalLayout {
            style {
                width = matchParent
                justifyContent = JustifyContent.SpaceBetween
                paddingEnd = 5.px
            }
            theme.text(this) {
                style{
                    singleLine()
                    width = 40.percent
                }
                    text = item.ride.pickupAddress
            }
            horizontalFiller()
            theme.text(this) {
                style{
                    singleLine()
                    width = 40.percent
                    textAlign = TextAlign.End
                }
                    text = item.ride.destAddress ?: "no destination"
            }
        }
        theme.text(this) {
            text = item.ride.status.enum().dtoName
        }
        horizontalLayout {
            style {
                width = matchParent
                justifyContent = JustifyContent.SpaceBetween
            }
            theme.text(this) {
                text = "Driver: " + (item.driver?.fullName ?: "Not Assigned")
            }
            theme.text(this) {
                text = (item.carType?.nameEn ?: "")
            }
        }
        onClick = {
            val rideId = item.ride.id
            if (rideId != null) {
                RideDetailsDialog(rideId, item, viewModel,false, onNextPassengerClicked = {
                    setSelectedPassenger(null)
                    selectPassengerDialog()
                }).show()
            }
        }
        verticalFiller(8)
        horizontalLayout {
            style {
                isVisible = item.ride.status.enum() == RideStatus.completed
                width = matchParent
                justifyContent = JustifyContent.End
                alignItems = Alignment.Center
            }
            val rating = item.ride.rating
            if (rating != null && rating != 0.0) {
                console.log(item.ride.ratedByCallCenter)
                if (item.ride.ratedByCallCenter != null) {
                    theme.text(this) {
                        text = "Rated by Call Center".localized()
                    }
                }
                horizontalFiller(10)
                mount(RatingComponent(rating))

            } else {
                theme.mainButton(this) {
                    text = "Rate".localized()
                    onClick = {
                        rateRideDialog(item)
                        it.stopPropagation()
                    }
                }

            }
        }


    }


    private fun selectPassengerDialog() {
        PassengerSelectorDialog(::setSelectedPassenger).show()
    }

    private fun rateRideDialog(extendedRideDto: ExtendedRideDto) {
        RateRideDialog() { rating, review ->
            extendedRideDto.ride.id?.let { rideId -> viewModel.rateRide(rideId, rating, review) }
            selectedPassengerDto.value?.passenger?.id?.let { id ->
                viewModel.rateRideUiState.observe {
                    viewModel.getRides(id)
                }
            }
        }.show()
    }
}