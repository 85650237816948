package com.narbase.letsgo.web.utils.uploaders

import com.narbase.letsgo.web.common.AppConfig

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/05/08.
 */


object ServerImageBaseUrl {
    const val oldUrl = "http://car.narbase.com:4567"
    val newUrl by lazy { if (AppConfig.isDev) "http://localhost:4567" else "https://car.narbase.com" }
}

fun String.fixOldBaseUrl(): String {
    if (this.startsWith(ServerImageBaseUrl.oldUrl)) {
        return "${ServerImageBaseUrl.newUrl}${this.removePrefix(ServerImageBaseUrl.oldUrl)}"
    }
    if (this.startsWith("http").not()) {
        return "${ServerImageBaseUrl.newUrl}${this.removePrefix(ServerImageBaseUrl.oldUrl)}"
    }
    return this
}