package com.narbase.letsgo.web.views.startup

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.*
import com.narbase.letsgo.web.utils.datetime.toDateTimeAtClinicTimeZone
import com.narbase.letsgo.web.utils.views.*
import kotlin.js.Date

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/18.
 */
class StartupComponent(private val onReadyToStart: () -> Unit) : Component() {

    private val viewModel = StartupViewModel()
    private var gettingConfigView: View? = null
    private var loadingView: View? = null
    private var errorView: View? = null
    private var permissivePopUpDialog: PopUpDialog? = null


    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        setTimeDifferenceBetweenClinicTimeZoneAndDevice()
        viewModel.uiState.observe { onConfigUiStateChanged(it) }
        viewModel.getConfig()
    }

    private fun onConfigUiStateChanged(uiState: BasicUiState?) {
        setTimeDifferenceBetweenClinicTimeZoneAndDevice()
        uiState ?: return
        makeNotVisible(loadingView, errorView)
        when (uiState) {
            BasicUiState.Loading -> makeVisible(loadingView)
            BasicUiState.Loaded -> {
                onConfigLoaded()
            }

            BasicUiState.Error -> makeVisible(errorView)
        }
    }

    private fun onConfigLoaded() {
        onStartupSuccessful()
    }


    private fun onStartupSuccessful() {
        rootView?.parent?.unMount(this)
        onReadyToStart()
    }

    override fun View?.getView() = verticalLayout {
        permissivePopUpDialog = popUpDialog { }
        style {
            matchParentDimensions
            alignItems = Alignment.Center
            justifyContent = JustifyContent.Center
            backgroundColor = Color.white
        }

        gettingConfigView = verticalLayout {
            style {
                matchParentDimensions
                alignItems = Alignment.Center
                justifyContent = JustifyContent.Center
                backgroundColor = Color.white
            }

            imageView {
                style {
                    width = wrapContent
                    height = 120.px
                    backgroundColor = Color.white
                    mediumScreen {
                        height = 80.px
                    }
                }
                element.src = DEFAULT_IMAGE
            }
            verticalFiller(32)

            loadingView = horizontalLayout {
                imageView {
                    style {
                        width = 18.px
                        height = 18.px
                        alignSelf = Alignment.Center
                    }
                    element.src = "/public/img/loading.gif"
                }

                horizontalFiller(16)
                textView {
                    text = "Starting up..".localized()
                    style {
                        color = AppColors.textLight
                        fontSize = 16.px
                    }
                }
            }
            errorView = verticalLayout {

                textView {
                    text = "Failed to load. Click to retry".localized()
                    style {
                        color = AppColors.redLight
                        pointerCursor()
                    }
                }
                onClick = {
                    viewModel.getConfig()
                }
            }

            textView {
                text = "Logout".localized()
                style {
                    borderRadius = 4.px
                    margin = 32.px
                    padding = 8.px
                    pointerCursor()
                    fontSize = 14.px
                    color = AppColors.text
                    hover {
                        backgroundColor = AppColors.separatorLight
                    }
                }
                onClick = {
                    logoutUser()
                }
            }

            verticalFiller(50)
//            textView {
//                text = "By".localized()
//                style {
//                    color = AppColors.textDarkest
//                    fontSize = 18.px
//                }
//            }
//            verticalFiller(16)

//            imageView {
//                style {
//                    width = wrapContent
//                    height = 32.px
//                    mediumScreen {
//                        height = 24.px
//                    }
//                }
//                element.src = "/public/img/narbase_logo.svg"
//            }
        }
    }

    private fun setTimeDifferenceBetweenClinicTimeZoneAndDevice() {
        val timeWithClinicTimeZone = Date().toDateTimeAtClinicTimeZone()
        val differenceInMilliSeconds = Date().getTime() - timeWithClinicTimeZone.getTime()
        StorageManager.timeDifferenceInMilliSeconds = differenceInMilliSeconds
    }
}
