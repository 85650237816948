package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint

object CancelRideAdminEndpoint
    : EndPoint<CancelRideAdminEndpoint.Request, CancelRideAdminEndpoint.Response>() {
    class Request(
        val rideId: Long,
        val reason: String,
    )

    class Response()
}