package com.narbase.letsgo.web.dto.admin

import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.dto.core.KmmLong
import com.narbase.letsgo.models.roles.AdminPrivilege
import com.narbase.letsgo.web.dto.currency.CurrencyDto

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/03/31.
 */
class AdminDto(
        val id: Long?,
        val fullName: String?,
        val email: String,
        val currencyId: Int?,
        val callCenterId: Long? = null
)
class AdminUpsertDto(
        val id: Long?,
        val password: String?,
        val fullName: String,
        val email: String,
        val currencyId: Int?,
        val callCenterId: Long? = null
)

class ExtendedAdminDto(
        val admin: AdminDto,
        val roles: Array<DynamicRoleDto>,
        val currency: CurrencyDto?,
)

val DynamicRoleDto.privileges: List<AdminPrivilege>
    get() {
        return privilegeList.privileges.mapNotNull { dtoName ->
            AdminPrivilege.values().firstOrNull { it.dtoName == dtoName }
        }
    }
class DynamicRoleDto(
        val id: KmmLong?,
        val createdOn: DateTimeDto?,
        val name: String,
        val privilegeList: AdminPrivilegeListDto
)
class AdminPrivilegeListDto(
        val privileges: Array<String>,
)
