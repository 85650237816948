package com.narbase.letsgo.web.views.incentives

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.currency.CurrencyDto
import com.narbase.letsgo.web.dto.orderingIncentives.OrderingIncentiveRmDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.currencies.GetCurrenciesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.UpsertOrderingIncentivesEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class UpsertGroupOrderingIncentivesViewModel(
) {
    val updateUiState = Observable<BasicUiState>()

    val getCurrenciesUiState = Observable<BasicUiState>()
    var currencies: List<CurrencyDto> = listOf()

    fun upsertOrderingIncentive(orderingIncentiveRmDto: OrderingIncentiveRmDto) {
        basicNetworkCall(updateUiState) {
            val dto = UpsertOrderingIncentivesEndpoint.Request(orderingIncentiveRmDto)
            val response = UpsertOrderingIncentivesEndpoint.remoteProcess(dto).data
        }
    }

    fun getCurrencies() {
        basicNetworkCall(getCurrenciesUiState) {
            currencies = GetCurrenciesEndpoint.remoteProcess(
                GetCurrenciesEndpoint.Request(
                    0,
                    100,
                    ""
                )
            ).data
                .list.toList()
        }

    }
}