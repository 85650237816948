package com.narbase.letsgo.web.common

import com.narbase.kunafa.core.drawable.Color

object AppColors {
    val warning = Color("EC9730")
    val text = Color("555")
    val textLight = Color("888")
    val textVeryLightGrey = Color("cad1d1")
    val textInputBorderColor = Color("cad1d1")
    val textDarkGrey = Color("555")
    val textDarkerGrey = Color("333")
    val textDarkest = Color("222")
    val textOffWhite = Color("DCE1E1")
    val textInactive = Color("AAA")
    val textInactiveTitle = Color("#9F9F9F")
    val lightGreyBackground = Color("4A4A4A")
    val lightBackground = Color("f1f2f2")
    val extraLightBackground = Color("#F7F7F7")
    val blueishGrey = Color("394a60")
    val hoverBackground = Color("F7F7F7")
    val separatorLight = Color("e4e4e4")
    val separatorDark = Color("303d4f")
    val separatorNormal = Color("BCBEC0")
    val greenLight = Color(86, 176, 90)
    val blueLight = Color(85, 104, 217)
    val greenDark = Color(70, 160, 80)
    val redLight = Color(231, 82, 82)
    val redDark = Color("B03635")
    val orange = Color("db870e")
    val mainColor = Color("ED197B")

    val borderColorHex = "#cecece"
    val borderColor = Color("cecece")
    val borderDarkColor = Color("999")
    val focusInputBorderColor = Color("046bf6")
    val subtitle = Color("595959")

    val texMainDarkColor = Color(170, 0, 79)

    val mainDarkColor = Color("0098AC")
    val mainVeryDarkColor = Color("405366")
    val lightDarkBackground = Color("4b5d76")
    //    val lightDarkBackground = Color("5B6F8A")
    val lightDarkSeparator = Color("6e7a8b")
    //    val lightDarkSeparator = Color("7D8EA3")
    val bluishLightGrey = Color("26303d")
    //Footer
    val darkFooter = Color(15, 18, 23)
    val black = Color("#000000")
    val white = Color("#ffffff")
    val doctorOffColor = lightBackground
    val clinicOffColor = Color("#FCF2F3")

    val completedColor = Color("#00DC7D")
    val unconfirmedColor = Color("#a0a0a0")
    val noShowColor = Color("#735F77")
    val plannedColor = Color("#5199ff")
    val cancelledColor = Color("#ee3d48")
    val recommendedColor = Color("#ffaf50")
    val activeColor = Color("#2398AB")
    val existingColor = Color("#5d5d5d")

    // Theme
    val main = mainColor
    val mainDark = mainDarkColor
    val secondaryButtonBackground = Color("#EAEAEA")
    val secondaryButtonBackgroundHover = Color("#CCCCCC")
    val darker = Color(0, 0, 0, 0.05)

}
