package com.narbase.letsgo.web.views.user.profile

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.views.pointerCursor
import kotlinx.coroutines.Deferred

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] - [2021] Narbase Technologies
 * All Rights Reserved.
 * Created by nezuma
 * On: 3/29/21.
 */
class PasswordUpdateComponent(private val onUpdated: () -> Unit) : Component() {
    val viewModel = PasswordUpdateViewModel()
    val errorStyle = classRuleSet {
        color = AppColors.redDark
    }

    private var messageView: TextView? = null
    private var submitButton: TextView? = null
    private var loadingIndicator: ImageView? = null
    private var passwordViews: View? = null

    override fun View?.getView() = verticalLayout {
        passwordViews = verticalLayout {

            passwordView("Old Password".localized()) {
                viewModel.oldPassword = it
            }
            passwordView("New Password".localized()) {
                viewModel.firstNewPassword = it
            }
            passwordView("Repeat New Password".localized()) {
                viewModel.secondNewPassword = it
            }
        }
        messageView = textView {
            isVisible = false
        }
        submitButton = textView {
            text = "Change password".localized()
            style {
                padding = "2px 12px".dimen()
                border = "1px solid ${AppColors.borderColorHex}"
                borderRadius = 12.px
                pointerCursor()
            }
            onClick = {
                viewModel.updatePassword()
            }
        }
        loadingIndicator = imageView {
            id = "loading"
            isVisible = false
            style {
                width = 40.px
                height = 40.px
                alignSelf = Alignment.Center
            }

            element.src = "/public/img/loading.gif"
        }

    }

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        viewModel.uiState.observe {
            makeNotVisible(messageView, loadingIndicator)
            when (it) {
                BasicUiState.Loading -> {
                    makeNotVisible(submitButton)
                    makeVisible(loadingIndicator)
                }

                BasicUiState.Loaded -> {
                    makeVisible(messageView)
                    messageView?.text = "Your password has been updated successfully".localized()
                    messageView?.removeRuleSet(errorStyle)
                    passwordViews?.isVisible = false
                    submitButton?.isVisible = false
                    onUpdated()
                }

                BasicUiState.Error -> {
                    makeVisible(messageView, submitButton)
                    messageView?.text = viewModel.errorMessage
                    messageView?.addRuleSet(errorStyle)
                }
            }
        }

    }

    fun reset() {
        passwordViews?.isVisible = true
        submitButton?.isVisible = true
        makeNotVisible(messageView)

    }

    fun LinearLayout.passwordView(title: String, updateViewModelString: (String) -> Unit) {
        val input = theme.labeledTextInput(this, title) {
            element.type = "password"
        }
        input.onChange = {
            updateViewModelString(input.text)
        }
    }
}

class PasswordUpdateViewModel {
    @Suppress("DeferredIsResult")
    interface UserPasswordApi {
        @JsName("updateUserPassword")
        fun updateUserPassword(oldPassword: String, newPassword: String): Deferred<Boolean>
    }


    val uiState = Observable<BasicUiState>()
    var oldPassword: String = ""
    var firstNewPassword: String = ""
    var secondNewPassword: String = ""
    var errorMessage: String = ""

    class ErrorException(msg: String) : Exception(msg)

    fun updatePassword() {
        basicNetworkCall(uiState) {
            if (validatePasswordsNotEmpty().not()) throw ErrorException("some passwords are empty") //
            if (validateNewPasswordsMatch().not()) throw ErrorException("new passwords don't match")
//            val updateResult = passwordUpdateClient.updateUserPassword(oldPassword, firstNewPassword).await()
//            if (updateResult.not()) {
//                errorMessage = "Old Password is incorrect".localized()
//                throw ErrorException("Old Password is incorrect")
//            }
        }
    }

    fun validatePasswordsNotEmpty(): Boolean {
        var messages = listOf<String>()
        if (oldPassword.isEmpty()) {
            messages += "Old Password is empty".localized()
        }
        if (firstNewPassword.isEmpty()) {
            messages += "First New Password is empty".localized()
        }
        if (secondNewPassword.isEmpty()) {
            messages += "Second New Password is empty".localized()
        }
        return if (messages.isEmpty())
            true
        else {
            errorMessage = messages.joinToString()
            false
        }
    }

    fun validateNewPasswordsMatch(): Boolean =
            if (firstNewPassword != secondNewPassword) {
                errorMessage = "New Passwords don't match".localized()
                false
            } else
                true


}