package com.narbase.letsgo.web.routing

import com.narbase.letsgo.models.roles.AdminPrivilege
import com.narbase.letsgo.router.Authentication
import com.narbase.letsgo.router.IpLockInterceptor
import com.narbase.letsgo.router.Routing
import com.narbase.letsgo.web.routing.AdminRouting.route
import com.narbase.letsgo.web.routing.domain.admin.auditlog.GetAuditLogEndpoint
import com.narbase.letsgo.web.routing.domain.admin.callcenter.RegisterPassengerByCallCenterEndpoint
import com.narbase.letsgo.web.routing.domain.admin.cartypes.GetCarTypesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.currencies.GetCurrenciesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.currencyincentiveconfigs.GetCurrencyIncentiveConfigsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.currencyincentiveconfigs.UpsertCurrencyIncentiveConfigsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup.GetDriverProfileGroupsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup.UpsertDriverProfileGroupEndpoint
import com.narbase.letsgo.web.routing.domain.admin.drivers.GetDriversListEndpoint
import com.narbase.letsgo.web.routing.domain.admin.drivers.UpsertDriverEndpoint
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.DeleteAdminDynamicRoleEndpoint
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.GetAdminDynamicRolesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.UpsertAdminDynamicRolesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.favoritepoints.CreateFavoritePointEndpoint
import com.narbase.letsgo.web.routing.domain.admin.favoritepoints.DeleteFavoritePointEndpoint
import com.narbase.letsgo.web.routing.domain.admin.favoritepoints.GetPassengerFavoritePointsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.geocode.GetGeocodeEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.GetConfigEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.GetOrderingIncentivesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.UpsertConfigEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.UpsertOrderingIncentivesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.locationpoints.GetHistoryPointsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.locationpoints.GetPassengerLocationPointsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.login.AdminLoginEndpoint
import com.narbase.letsgo.web.routing.domain.admin.login.GetAdminProfileDetailsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengerlocation.GetPassengerLocationEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengerprofilegroup.GetPassengerProfileGroupsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengerprofilegroup.UpsertPassengerProfileGroupEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengers.CreatePassengerForAdminEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengers.GetPassengerByPhoneEndpoint
import com.narbase.letsgo.web.routing.domain.admin.pointofinterest.GetAreaPointsOfInterestEndpoint
import com.narbase.letsgo.web.routing.domain.admin.pointofinterest.GetNearbyPointsOfInterestEndpoint
import com.narbase.letsgo.web.routing.domain.admin.price.GetRidePricesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.*
import com.narbase.letsgo.web.routing.domain.admin.ridepromocodes.DeactivateRidePromoCodeEndpoint
import com.narbase.letsgo.web.routing.domain.admin.ridepromocodes.GetActivePromoCodesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.ridepromocodes.RedeemRidePromoCodeEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.GetPassengerRidesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.GetRideByIdEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.RequestRideAdminEndpoint
import com.narbase.letsgo.web.routing.domain.admin.staff.DeactivateAdminEndpoint
import com.narbase.letsgo.web.routing.domain.admin.staff.GetAdminsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.staff.UpsertAdminsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.zones.GetPointZoneIdEndpoint
import com.narbase.letsgo.web.routing.domain.helloworld.HelloWorldEndPoint

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2023/01/15.
 */
object AdminRouting : Routing() {
    override fun init() = api {
        "api_ep" {
            "public" {
                "v1" {
                    "hello_world" {
                        route(HelloWorldEndPoint)
                    }
                }
            }
            "v1" {
                auth(Authentication.JWT)
                "admin" {
                    interceptor(IpLockInterceptor)
                    "login" { route(AdminLoginEndpoint) }
                    "profile" {
                        "details" { route(GetAdminProfileDetailsEndpoint) }
                    }
                    "admins" {
                        require(AdminPrivilege.StaffManagement)
                        "list" { route(GetAdminsEndpoint) }
                        "upsert" { route(UpsertAdminsEndpoint) }
                        "deactivate" { route(DeactivateAdminEndpoint) }
                    }
                    "drivers" {
                        require(AdminPrivilege.DriversAccess)
                        "list" { route(GetDriversListEndpoint) }
                        require(AdminPrivilege.DriversManagement)
                        "privileged" {
                            "upsert" { route(UpsertDriverEndpoint) }
                        }
                    }
                    "dynamic_roles" {
                        "list" { route(GetAdminDynamicRolesEndpoint) }
                        "privileged" {
                            require(AdminPrivilege.DynamicRoles)
                            "upsert" { route(UpsertAdminDynamicRolesEndpoint) }
                            "delete" { route(DeleteAdminDynamicRoleEndpoint) }
                        }
                    }
                    "audit_log" {
                        require(AdminPrivilege.AuditLogAccess)
                        "get_audit_log" { route(GetAuditLogEndpoint) }
                    }
                    "driver_profile_groups" {
                        "list" { route(GetDriverProfileGroupsEndpoint) }
                        "privileged" {
                            require(AdminPrivilege.DriverProfileGroups)
                            "upsert" { route(UpsertDriverProfileGroupEndpoint) }
                        }
                    }
                    "passenger_profile_groups" {
                        "list" { route(GetPassengerProfileGroupsEndpoint) }
                        "privileged" {
                            require(AdminPrivilege.PassengerProfileGroups)
                            "upsert" { route(UpsertPassengerProfileGroupEndpoint) }
                        }
                    }
                    "car_types" {
                        "list" { route(GetCarTypesEndpoint) }
                    }
                    "currencies" {
                        "list" { route(GetCurrenciesEndpoint) }
                    }
                    "passengers" {
                        "get" { route(GetPassengerByPhoneEndpoint) }
                    }
                    "call_center" {
                        "register_passenger" { route(RegisterPassengerByCallCenterEndpoint) }
                    }
                    "rides" {
                        "list" { route(GetPassengerRidesEndpoint) }
                        "request_ride" { route(RequestRideAdminEndpoint)}
                        "details" { route(GetRideByIdEndpoint)}
                        "rate_ride" { route(RateRideAdminEndpoint)}
                        "reorder_ride" { route(ReorderRideAdminEndpoint)}
                        "cancel_ride" { route(CancelRideAdminEndpoint)}
                        "update_ride_locations" { route(UpdateRideLocationsEndpoint)}
                        "get_latest"{ route(GetLatestPassengerRideEndpoint)}
                    }
                    "favorite_points" {
                        "list" { route(GetPassengerFavoritePointsEndpoint) }
                        "create" { route(CreateFavoritePointEndpoint)}
                        "delete" { route(DeleteFavoritePointEndpoint)}
                    }
                    "points_of_interest" {
                        "near_by" { route(GetNearbyPointsOfInterestEndpoint) }
                        "area" { route(GetAreaPointsOfInterestEndpoint) }
                    }
                    "prices" {
                        "by_car_types" { route(GetRidePricesEndpoint) }
                    }
                    "zone" {
                        "get_id" { route(GetPointZoneIdEndpoint) }
                    }
                    "location_points"{
                        "list" { route(GetPassengerLocationPointsEndpoint)}
                        "history_points" { route(GetHistoryPointsEndpoint)}
                    }
                    "call_center"{
                        "create"{ route(CreatePassengerForAdminEndpoint)}
                    }
                    "promo"{
                        "get_active"{ route(GetActivePromoCodesEndpoint)}
                        "deactivate"{ route(DeactivateRidePromoCodeEndpoint)}
                        "redeem"{ route(RedeemRidePromoCodeEndpoint)}
                    }
                    "incentives"{
                        "list"{ route(GetOrderingIncentivesEndpoint)}
                        "upsert"{ route(UpsertOrderingIncentivesEndpoint)}
                        "get_currency_incentive_configs"{ route(GetCurrencyIncentiveConfigsEndpoint)}
                        "upsert_currency_incentive_configs"{ route(UpsertCurrencyIncentiveConfigsEndpoint)}
                    }
                    "config"{
                        "get_config"{ route(GetConfigEndpoint)}
                        "upsert_config"{ route(UpsertConfigEndpoint)}
                    }
                    "geocode" { route(GetGeocodeEndpoint)}
                    "recent_passenger_location" { route(GetPassengerLocationEndpoint)}
                }
            }
        }
    }
}