package com.narbase.letsgo.web.views.admin.staff


import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vh
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.admin.AdminUpsertDto
import com.narbase.letsgo.web.dto.admin.DynamicRoleDto
import com.narbase.letsgo.web.dto.admin.ExtendedAdminDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.GetAdminDynamicRolesEndpoint
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.ScrollableView
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.templates.EntriesStyles.textInputStyle

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/20.
 */
class UpsertStaffMemberDialog(val viewModel: StaffManagementViewModel) : Component() {
    private val popUp = popUpDialog { }
    private var popupScrollable: ScrollableView? = null
    private var rolesDropDownListVm: MultiSelectionDropDownListViewModel<DynamicRoleDto>? = null

    private var errorTextView: TextView? = null

    private var fullNameTextInput: TextInput? = null
    private var emailTextInput: TextInput? = null
    private var passwordTextInput: TextInput? = null


    override fun View?.getView() = view {
        style {
            width = 0.px
            height = 0.px
        }
    }


    private fun upsertDialog(staffDto: ExtendedAdminDto? = null) {
        theme.showDialog(popUp) { dialogBuilder ->
            verticalLayout {
                id = "upsertMemberRootView"
                style {
                    height = wrapContent
                    minWidth = 800.px
                    width = matchParent
                    backgroundColor = Color.white
                    borderRadius = 8.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        style {
                            fontWeight = "bold"
                            padding = 20.px
                            fontSize = 16.px
                        }
                        text = if (staffDto == null) "Add staff member".localized() else "Edit staff member".localized()
                    }

                    horizontalFiller()

                }

                verticalLayout {
                    style {
                        width = matchParent
                        maxHeight = 60.vh
                    }

                    popupScrollable = scrollable {
                        style {
                            width = matchParent
                            maxHeight = 60.vh
                        }
                        verticalLayout {
                            style {
                                width = matchParent
                                height = wrapContent
                                padding = 20.px
                            }
                            fullNameAndPreferredName()
                            emailAndPassword()

                            dynamicRolesView(staffDto?.roles?.toList() ?: listOf())


                        }

                    }
                }

                errorTextView = textView {
                    style {
                        marginBottom = 8.px
                        fontSize = 14.px
                        color = AppColors.redLight
                        padding = 20.px
                    }
                    isVisible = false
                    text = "Please enter valid fields values".localized()
                }

                dialogBuilder.bottomBar = {
                    horizontalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
                            justifyContent = JustifyContent.End
                        }

                        val saveButton = theme.mainButton(this) {
                            text = "Save".localized()
                            id = "SaveButton"
                            onClick = {
                                onSaveButtonClicked(staffDto)
                            }
                        }
                        viewModel.upsertUiState.clearObservers()
                        saveButton.withLoadingAndError(viewModel.upsertUiState,
                                onRetryClicked = {
                                    onSaveButtonClicked(staffDto)
                                },
                                onLoaded = {
                                    popUp.dismissDialog()
                                    viewModel.getStaff()
                                }
                        )
                    }

                }
            }
        }
        fullNameTextInput?.element?.focus()

    }

    private fun onSaveButtonClicked(staffDto: ExtendedAdminDto? = null) {
        isDataValid = true
        val email = emailTextInput.validateAndGetText().trim()
        val password = if (staffDto == null) passwordTextInput.validateAndGetText() else passwordTextInput?.text ?: ""

        val fullName = fullNameTextInput.validateAndGetText()

        errorTextView?.isVisible = isDataValid.not()
        if (isDataValid.not()) return
        val adminDto = AdminUpsertDto(
                staffDto?.admin?.id,
                password,
                fullName,
                email,
                null
        )
        val dynamicRoleIds = rolesDropDownListVm?.selectedItems?.mapNotNull { it.id }?.toTypedArray() ?: arrayOf()

        if (staffDto == null) {
            viewModel.addStaffMember(adminDto, dynamicRoleIds)
        } else {
            viewModel.editStaffMember(adminDto, dynamicRoleIds)
        }

    }

    private var isDataValid = false
    private fun TextInput?.validateAndGetText(): String {
        val text = this?.text ?: ""
        if (text.isBlank()) {
            isDataValid = false
            this?.addErrorStyle()
        } else {
            this?.resetStyle()
        }
        return text
    }

    private fun View.fullNameAndPreferredName() {
        horizontalLayout {
            style {
                width = matchParent
                marginBottom = 12.px
            }
            verticalLayout {
                style {
                    width = weightOf(3)
                }
                fullNameTextInput = labeledTextInput("Full name".localized())
                fullNameTextInput?.element?.oninput = {
                    fullNameTextInput?.handleOnFullNameChanged()
                }
                fullNameTextInput?.id = "FullNameInput"
            }
        }

    }

    private fun View.labeledTextInput(localized: String, block: TextInput.() -> Unit = {}): TextInput {
        return theme.labeledTextInput(this, localized, block = block)
    }

    private fun View.emailAndPassword() {
        horizontalLayout {
            style {
                width = matchParent
                marginBottom = 12.px
            }
            verticalLayout {
                style {
                    width = weightOf(1)
                }
                emailTextInput = labeledTextInput("Email".localized())
                emailTextInput?.id = "EmailInput"
            }
            verticalLayout {
                style {
                    width = weightOf(1)
                    marginStart = 12.px
                }
                passwordTextInput =
                        labeledTextInput("Password".localized()) {
                            element.type = "password"
                            element.oninput = {
                                passwordTextInput?.resetStyle()
                            }
                        }
                passwordTextInput?.id = "PasswordInput"
            }
        }

    }

    private fun TextInput.handleOnFullNameChanged() {
        resetStyle()
    }


    private fun View.addErrorStyle() {
        removeRuleSet(textInputStyle)
        addRuleSet(textInputErrorStyle)
    }

    private fun View.resetStyle() {
        removeRuleSet(textInputErrorStyle)
        addRuleSet(textInputStyle)
    }


    private fun LinearLayout.dynamicRolesView(defaultRoles: List<DynamicRoleDto>) {
        horizontalLayout {
            style {
                width = matchParent
                height = wrapContent
                marginBottom = 16.px
                alignItems = Alignment.Center
            }

            rolesDropDownListVm = MultiSelectionDropDownListViewModel(
                    itemToId = { it.name },
                    getRemoteItems = { pageNo, searchTerm ->
                        basicNetworkCall(loadingListState) {
                            val dto = GetAdminDynamicRolesEndpoint.Request(pageNo, 20, searchTerm)
                            val response = GetAdminDynamicRolesEndpoint.remoteProcess(dto)
                            appendItems(response.data.list, endOfList = response.data.list.isEmpty())
                        }
                    }
            )

            setupRemoteMultiSelectionDropDownList(
                    name = "Roles".localized(),
                    viewModel = rolesDropDownListVm!!,
                    itemToString = { it.name },
                    onSelectedItemsUpdated = { },
                    showAutoComplete = true,
                    rootStyle = classRuleSet {
                        width = weightOf(1)
                    },
                    defaultItems = defaultRoles,
                    isDisabled = false
            )
        }
    }


    fun add() {
        upsertDialog()
    }

    fun edit(dto: ExtendedAdminDto) {
        upsertDialog(dto)
        fullNameTextInput?.text = dto.admin.fullName ?: ""

        emailTextInput?.text = dto.admin.email
        passwordTextInput?.placeholder = "(Enter new password or keep empty)"
        passwordTextInput?.text = ""
    }

    private val textInputErrorStyle = classRuleSet {
        padding = 4.px
        fontSize = 14.px
        padding = "6px 12px".dimen()
        border = "1px solid ${AppColors.redLight}"
        borderRadius = 4.px
    }


}
