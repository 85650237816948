package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto

object GetRideByIdEndpoint : EndPoint<GetRideByIdEndpoint.Request, GetRideByIdEndpoint.Response>() {
    class Request(
        val rideId : Long,
    )

    class Response(
        val extendedRide: ExtendedRideDto?,
    )
}