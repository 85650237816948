package com.narbase.letsgo.web.dto.drivertargets

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.web.models.PrizeType

class TargetDto (
    val id: Long?,
    val driverProfileGroupId : Int,
    val periodInMinutes :Int,
    val amount : Double?,
    val budget : Double?,
    val noOfRides: Int ,
    val prizeType : DtoName<PrizeType> ,
    val results: TargetResultsDto?,
    val starTime : DateTimeDto,
    val endTime: DateTimeDto,
    val isAutoRepeat: Boolean,
    val isRepeatOf: Long?,
    val allowMultiAchievements: Boolean,
    val isAmountHidden: Boolean,
    val messages : MessagesDto

)


class TargetResultsDto(
    val noOfWinners: Int,
    val amountPerWin: Double,
)

class MessagesDto(
    val newTargetMessage: String?,
    val prizeMessage: String?,
)