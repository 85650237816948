package com.narbase.letsgo.web.storage

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/03/26.
 */
class AppSettings() {
    companion object {
        val default: AppSettings
            get() {
                return AppSettings()
            }
    }
}