package com.narbase.letsgo.web.dto.passengers

import com.narbase.letsgo.dto.core.DateTimeDto

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/04/28.
 */
class PassengerDto (
    val id: Long?,
    val email: String,
    val optionalEmail: String?,
    val fullName: String,
    val isMale: Boolean,
    val passwordHash: String,
    val phone: String,
    val fcmToken: String?,
    val isAndroid: Boolean = true,
    val balance: Double,
    val profileGroupId: Int,
    val isVerified: Boolean = false,
    val verificationCode: String? = null,
    val points: Int,
    val notes: String? = null,
    val activeRidePromoCodeId: Long?,
    val lastZoneId: Int?,
    val createdOn: DateTimeDto?,
    val isCallCenter: Boolean,
    val byCallCenter: Long?
)


class RegisterPassengerRequestDto(
    val name: String,
    val optionalEmail: String?,
    val phone: String,
    val isMale: Boolean
)
