package com.narbase.letsgo.web.dto.favoritepoints

import com.narbase.letsgo.web.dto.pointsOfIntrest.PointOfInterestDto

class FavoritePointDto(
    val id: Long?,
    val passengerId: Long,
    val lat: Double,
    val lng: Double,
    val fullAddress: String,
    val name: String
)

fun FavoritePointDto.getFullAddress() = fullAddress