package com.narbase.letsgo.web.utils

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.LinearDimension
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.utils.views.pointerCursor
import kotlinx.dom.addClass

class CustomRadioButton(
        private val radioText: String,
        private val idString: String? = null,
        private val customStyle: CustomStyle,
        val block: Radio.() -> Unit) : Component() {
    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        createCustomStyles()
    }

    var radio: Radio? = null
    var textView: TextView? = null
    var checkMarkLayout: LinearLayout? = null

    override fun View?.getView(): View = horizontalLayout {
        style {
            pointerCursor()
            padding = customStyle.padding
            alignItems = Alignment.Center
        }
        onClick = {
            radio?.element?.click()
        }
        idString?.let {
            element.id = it
        }
        element.addClass("hindRadio")
        radio = radio {
            block()
            style {
                display = "none"
            }
        }
        checkMarkLayout = horizontalLayout {
            element.addClass("checkMark")
            style {
                content = ""
                opacity = 1.0
                width = 14.px
                height = 14.px
                borderRadius = 50.percent
                borderColor = awesomeGreenColor
                borderWidth = "2px"
                borderStyle = "solid"
                transition = ".4s"
                backgroundClip = "padding-box"
            }
        }
        if (radioText.isNotBlank()) {
            textView = textView {
                style {
                    marginStart = 8.px
                    fontSize = AppFontSizes.smallText
                    color = customStyle.color

                }
                text = radioText
            }
        }
    }

    fun createCustomStyles() {

        try {
            stringRuleSet(".hindRadio input:checked ~ .checkMark") {
                //                removeRuleSet(whiteBackground)
                backgroundColor = awesomeGreenColor
//                removeRuleSet(opaqueBorder)
                borderColor = transparentAwesomeGreenColor(0.5)
            }
        } catch (e: dynamic) {
            console.log(e)
        }
    }

    class CustomStyle(
            var padding: LinearDimension = 0.px,
            var color: Color = AppColors.text
    )
}

fun View.customRadio(
        text: String = "",
        id: String? = null,
        customStyle: (CustomRadioButton.CustomStyle.() -> Unit)? = null,
        block: Radio.() -> Unit
): CustomRadioButton {
    return CustomRadioButton(
            text,
            id,
            CustomRadioButton.CustomStyle().apply { customStyle?.invoke(this) },
            block
    ).also { mount(it) }
}

val awesomeGreenColor = Color("31c3be")
fun transparentAwesomeGreenColor(alpha: Double) = Color("31".toInt(16), "c3".toInt(16), "be".toInt(16), alpha)
