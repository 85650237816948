package com.narbase.letsgo.web.dto.locationpoint

import com.narbase.letsgo.web.dto.favoritepoints.FavoritePointDto
import com.narbase.letsgo.web.dto.pointsOfIntrest.PointOfInterestDto

class LocationPointDto (
    val lat: Double,
    val lng: Double,
    val address: String?,
    val name: String?,
    val pointOfInterestDto: PointOfInterestDto?,
    val favoritePointDto: FavoritePointDto?
)
