package com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.driverprofilegroup.ExtendedDriverProfileGroupDto

object UpsertDriverProfileGroupEndpoint :
    EndPoint<UpsertDriverProfileGroupEndpoint.Request, UpsertDriverProfileGroupEndpoint.Response>() {
    class Request(
        val extendedDriverProfileGroupDto: ExtendedDriverProfileGroupDto
    )

    class Response(
    )
}