package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.GetRideByIdEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import kotlinx.browser.window

class RideDetailsViewModel(
    private val rideId: Long,
    var extendedRide: ExtendedRideDto?
) {
    val getRideUiState = Observable<BasicUiState>()
    private var getRidesTimerHandlerId: Int? = null

    fun setUpRideDetails() {
        getRideDetails()
        getRidesTimerHandlerId = window.setInterval(
            {
                getRideDetails()
            }, 5000
        )
    }

    fun getRideDetails() {
        val extendedRideId = rideId
        basicNetworkCall(getRideUiState) {
            val dto = GetRideByIdEndpoint.Request(extendedRideId)
            val response = GetRideByIdEndpoint.remoteProcess(dto).data
            extendedRide = response.extendedRide ?: extendedRide
        }
    }

    fun clearRideDetailsTimer() {
        getRidesTimerHandlerId?.let { window.clearInterval(it) }
    }
}