package com.narbase.letsgo.web.storage

import com.narbase.letsgo.models.Language


class TranslationsStrings(private val en: String, private val ar: String, private val ur: String) {
    constructor(en: String) : this(en, en, en)

    fun getString(): String =when (StorageManager.language){
        Language.Fr,Language.En -> en
        Language.Ar -> ar
    }

}

object Translations {
    val networkErrorWithRetry =
            TranslationsStrings("Network Error! Click to retry", "مشكلة اتصال! اضغط لإعادة المحاولة", "Network Error! Click to retry").getString()
    val contactUs =
            TranslationsStrings("Contact us", "اتصل بنا", "Contact us").getString()
    val logout =
            TranslationsStrings("Logout", "تسجيل خروج", "Logout").getString()
    val noChatThreads =
            TranslationsStrings("You have no chat threads", "لا توجد أي محادثات سابقة", "You have no chat threads").getString()
    val points =
            TranslationsStrings("Points", "نقاط", "Points").getString()
    val accountInformation =
            TranslationsStrings("Account Information", "معلومات الحساب", "Account Information").getString()
    val noEmail =
            TranslationsStrings("No email", "لا يوجد بريد الكتروني", "No email").getString()
    val login =
            TranslationsStrings("Login", "تسجيل دخول", "Login").getString()
    val register =
            TranslationsStrings("Register", "حساب جديد", "Register").getString()
    val email =
            TranslationsStrings("Email", "بريد الكتروني", "Email").getString()
    val password =
            TranslationsStrings("Password", "كلمة المرور", "Password").getString()
    val confirmPassword =
            TranslationsStrings("Confirm Password", "أكد كلمة المرور", "Confirm Password").getString()
    val dontHaveAccount =
            TranslationsStrings("Don't have an account?", "ليس لديك حساب؟", "Don't have an account?").getString()
    val clickToRegister =
            TranslationsStrings("Click to register", "اضغط للتسجيل", "Click to register").getString()
    val clickToRetry =
            TranslationsStrings("Click to Try Again", "اضغط لإعادة المحاولة", "Click to Try Again").getString()
    val networkError =
            TranslationsStrings("Network Error", "مشكلة اتصال", "Network Error").getString()
    val someDataMissing =
            TranslationsStrings("Some Data Missing", "بعض البيانات مفقودة", "Some Data Missing").getString()
    val invalidGoogleIdToken =
            TranslationsStrings("Invalid Google Id Token").getString()
    val invalidFacebookToken =
            TranslationsStrings("Invalid facebookUserAccessToken").getString()
    val fullName =
            TranslationsStrings("Full Name", "الاسم الكامل", "Full Name").getString()
    val phone =
            TranslationsStrings("Phone", "رقم الهاتف", "Phone").getString()
    val alreadyHaveAccount =
            TranslationsStrings("Already have an account?", "لديك حساب؟", "Already have an account?").getString()
    val clickToLogin =
            TranslationsStrings("Click to Login", "اضغط لتسجيل الدخول", "Click to Login").getString()
    val allFieldsMandatory =
            TranslationsStrings("All The Above fields are mandatory", "كل الحقول أعلاه ضرورية", "All The Above fields are mandatory").getString()
    val passwordsDiffer =
            TranslationsStrings("Passwords Don't Match", "كلمتا السر مختلفتان", "Passwords Don't Match").getString()
    val authenticationFailure =
            TranslationsStrings("Authentication Failure").getString()
    val signInWithFacebook =
            TranslationsStrings("Sign in with Facebook", "سجل دخولك عبر الفيسبوك", "Sign in with Facebook").getString()
    val wrongEmailOrPassword = TranslationsStrings("There is no account with these credentials").getString()
}
