package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.dto.ridepromocode.RidePromoCodeDto
import com.narbase.letsgo.web.models.RidePromoCodeStatus
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.ridepromocodes.DeactivateRidePromoCodeEndpoint
import com.narbase.letsgo.web.routing.domain.admin.ridepromocodes.GetActivePromoCodesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.ridepromocodes.RedeemRidePromoCodeEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class RidePromoCodeViewModel (
    val passengerId : Long
) {
    val getActivePromoCodeUiState = Observable<BasicUiState>()
    var activePromoCode: RidePromoCodeDto? = null
    var redeemPromoCodeUiState = Observable<BasicUiState>()
    var redeemPromocodeStatus : RidePromoCodeStatus? = null

    fun getActivePromoCode(pickupLat: Double?, pickupLng: Double?, destLat: Double?, destLng: Double?){
        basicNetworkCall(getActivePromoCodeUiState) {
            val response = GetActivePromoCodesEndpoint.remoteProcess(
                GetActivePromoCodesEndpoint.Request(
                    passengerId,
                    pickupLat,
                    pickupLng,
                    destLat,
                    destLng
                )
            )
            activePromoCode = response.data.ridePromo
        }

    }

    fun deactivate(){
        basicNetworkCall(getActivePromoCodeUiState) {
            DeactivateRidePromoCodeEndpoint.remoteProcess(
                DeactivateRidePromoCodeEndpoint.Request(
                    passengerId,
                )
            )
            activePromoCode = null
        }
    }

    fun redeem(code: String){
        basicNetworkCall(redeemPromoCodeUiState) {
            val response = RedeemRidePromoCodeEndpoint.remoteProcess(
                RedeemRidePromoCodeEndpoint.Request(
                    passengerId,
                    code
                )
            )
            redeemPromocodeStatus = response.data.status.enum()
        }
    }
}