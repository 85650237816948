package com.narbase.letsgo.web.models

import com.narbase.letsgo.dto.EnumDtoName

enum class RideChangeabilityStatus(dtoName: String? = null) : EnumDtoName {
    Changeable,
    Unchangeable,
    ;

    override val dtoName = dtoName ?: this.name
}