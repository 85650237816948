package com.narbase.letsgo.web.views.startup

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.admin.privileges
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.login.GetAdminProfileDetailsEndpoint
import com.narbase.letsgo.web.storage.CurrentUserProfile
import com.narbase.letsgo.web.storage.SessionInfo
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.utils.BasicUiState
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/18.
 */

class StartupViewModel {
    val uiState = Observable<BasicUiState>()


    fun getConfig() {
        if (StorageManager.isUserLoggedIn().not()) {
            SessionInfo.currentUser = null
            uiState.value = BasicUiState.Loaded
            return
        }
        basicNetworkCall(uiState) {

            val getUserProfileJob = async {

                val response = GetAdminProfileDetailsEndpoint.remoteProcess(GetAdminProfileDetailsEndpoint.Request()).data
                val currentUserProfile = CurrentUserProfile(
                        response.admin,
                        response.roles,
                        response.roles.map { it.privileges }.flatten().distinct(),
                )

                SessionInfo.currentUser = currentUserProfile
            }
            awaitAll(getUserProfileJob)
        }
    }

}
