package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus


object GetPassengerRidesEndpoint : EndPoint<GetPassengerRidesEndpoint.Request, GetPassengerRidesEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val passengerId : Long,
        val filter: FilterDto?,
    )

    class Response(
        val list: Array<ExtendedRideDto>,
        val total: Int,
    )

    class FilterDto(
        val statuses: Array<String>
    )
}