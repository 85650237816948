package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.visit
import kotlinx.browser.document
import org.w3c.dom.HTMLAudioElement

class Audio(parent: LinearLayout? = null) : LinearLayout(parent) {
    override val element: HTMLAudioElement = document.createElement("audio") as HTMLAudioElement
    var src
        get() = element.src
        set(value) {
            element.src = value
        }
    var controls
        get() = element.controls
        set(value) {
            element.controls = value
        }
}

fun LinearLayout.audio(block: Audio.() -> Unit): Audio = Audio(this).visit(null, block)
