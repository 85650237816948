package com.narbase.letsgo.web.views.driverProfileGroups

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.driverprofilegroup.DriverProfileGroupDto
import com.narbase.letsgo.web.dto.driverprofilegroup.ExtendedDriverProfileGroupDto
import com.narbase.letsgo.web.dto.flags.FlagDto
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.utils.views.customViews.CustomCheckBox
import com.narbase.letsgo.web.utils.views.customViews.customCheckBox

class DriverProfileGroupsManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = DriverProfileGroupsManagementViewModel()
    private var isCommissionPercentageDropDown: DropDownList<String>? = null

    private val popup by lazy { popUpDialog() }

    private var listTableBody: View? = null

    private val isPercentageArray = arrayOf("Percentage", "Static Value")
    private var newIsCommissionPercentage = false

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getList()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.data.forEachIndexed { index, item ->
                tableRow {
                    id = item.driverProfileGroup.name
                    tableCell(item.driverProfileGroup.name, 3, 16.px)
                    tableCell(item.driverProfileGroup.commission.toString(), 3, 16.px)
                    tableCell(item.driverProfileGroup.isCommissionPercentage.toIsCommissionPercentageString(), 3, 16.px)
                    tableCell(item.flag.maxPerDay.toString(), 3, 16.px)
                    tableCell(item.flag.percentage.toString() + " %", 3, 16.px)



                    onClick = {
                        upsertDialog(item)
                    }
                }

                if (index != viewModel.data.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "driverProfileGroupsManagementRootView"

        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }
                withLoadingAndError(viewModel.uiState, onRetryClicked = {
                    viewModel.getList()
                }, onLoaded = {
                    onListLoaded()
                })

                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Driver Profile Groups".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()

                    addItemButton()

                }

                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }

                    horizontalFiller()
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                listTableBody = listTable {
                    headerCell("Name".localized(), 3)
                    headerCell("Commission".localized(), 3)
                    headerCell("Commission in".localized(), 3)
                    headerCell("Max flags per day".localized(), 3)
                    headerCell("Flag percentage".localized(), 3)
                }
                paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
            }
        }
    }


    private fun LinearLayout.addItemButton() {
        theme.mainButton(this) {
            id = "AddGroupButton"
            onClick = {
                upsertDialog()
            }
            text = "+ Add new group"
        }
    }

    private var upsertNameField: TextInput? = null
    private var upsertCommissionField: TextInput? = null
    private var upsertMaxPerDayField: TextInput? = null
    private var upsertPercentageField: TextInput? = null
    private var upsertNoDestinationCheckbox: CustomCheckBox? = null
    private fun upsertDialog(dto: ExtendedDriverProfileGroupDto? = null) {
        viewModel.setUpsertUiStateToNull()
        theme.showDialog(popup) {

            verticalLayout {
                style {
                    height = wrapContent
                    width = 70.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                textView {
                    style {
                        fontWeight = "bold"
                        marginBottom = 8.px
                        fontSize = 16.px
                    }
                    text = if (dto == null) "Add group".localized() else "Edit group".localized()
                }

                val nameInput = theme.labeledTextInput(this, "Name".localized(), true)
                val commissionInput = theme.labeledTextInput(this, "Commission".localized(), true)
                commissionInput.element.type = "number"
                commissionInput.element.placeholder = "0.0"
                verticalFiller(8)
                isCommissionPercentageDropDown = setupDropDownList(
                    name = "",
                    list = isPercentageArray,
                    itemToString = { it },
                    defaultValue = dto?.driverProfileGroup?.isCommissionPercentage.toIsCommissionPercentageString(),
                    onItemSelected = {
                        newIsCommissionPercentage = it == isPercentageArray[0]
                    },
                )
                verticalFiller(8)
                val maxPerDayInput = theme.labeledTextInput(this, "Max flags per Day".localized(), true)
                val percentageInput = theme.labeledTextInput(this, "Percentage".localized(), true)
                maxPerDayInput.element.type = "number"
                percentageInput.element.type = "number"
                maxPerDayInput.element.placeholder = "0"
                percentageInput.element.placeholder = "0.0"
                verticalFiller(8)
                upsertNoDestinationCheckbox = customCheckBox(
                    "Flag priority only (No destination)", isSelected = dto?.flag?.noDestination ?: false
                )

                verticalFiller(12)

                nameInput.text = dto?.driverProfileGroup?.name ?: ""
                commissionInput.text = dto?.driverProfileGroup?.commission.toString()
                maxPerDayInput.text = dto?.flag?.maxPerDay?.toString() ?: ""
                percentageInput.text = dto?.flag?.percentage?.toString() ?: ""


                upsertNameField = nameInput
                upsertCommissionField = commissionInput
                upsertMaxPerDayField = maxPerDayInput
                upsertPercentageField = percentageInput
                val errorText = textView {
                    style {
                        marginBottom = 8.px
                        fontSize = 14.px
                        color = AppColors.redLight
                    }
                    isVisible = false
                    text = "Please enter valid fields values".localized()
                }
                verticalFiller(10)

                it.bottomBar = {
                    horizontalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
                            justifyContent = JustifyContent.End
                            marginTop = 10.px
                        }

                        theme.mainButton(this) {

                            text = "Save".localized()
                            onClick = {
                                onSaveButtonClicked(
                                    dto, errorText
                                )
                            }

                            withLoadingAndError(viewModel.upsertUiState, onRetryClicked = {
                                onSaveButtonClicked(
                                    dto, errorText
                                )
                            }, onLoaded = {
                                popup.dismissDialog()
                                viewModel.getList()
                            })
                        }

                    }

                }

            }
        }
        upsertNameField?.element?.focus()
    }

    private fun Boolean?.toIsCommissionPercentageString(): String {
        this?.let {
            if (!it) {
                return isPercentageArray[1]
            }
        }
        return isPercentageArray[0]
    }

    private fun onSaveButtonClicked(
        dto: ExtendedDriverProfileGroupDto?, errorText: TextView
    ) {
        val nameInput = upsertNameField ?: return
        val commissionInput = upsertCommissionField ?: return
//        val isPercentageCheckBox = upsertIsComPercentageCheckbox ?: return
        val maxPerDayInput = upsertMaxPerDayField ?: return
        val percentageInput = upsertPercentageField ?: return
        val noDestinationCheckBox = upsertNoDestinationCheckbox ?: return

        if (nameInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid name".localized()
            return
        }
        if (commissionInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid commission".localized()
            return
        }
        if (maxPerDayInput.text.trim().isBlank() || maxPerDayInput.text.trim().toIntOrNull() == null) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid number for max flags per day".localized()
            return
        }
        if (percentageInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid number for flag percentage".localized()
            return
        }

        makeNotVisible(errorText)
        val name = nameInput.text.trim()
        val commission = commissionInput.text.toDouble()
        val isPercentage = newIsCommissionPercentage
        val maxPerDay = maxPerDayInput.text.toInt()
        val percentage = percentageInput.text.toDouble()
        val noDestination = noDestinationCheckBox.isSelected
        if (dto == null) {
            viewModel.addItem(
                ExtendedDriverProfileGroupDto(
                    DriverProfileGroupDto(null, name, commission, isPercentage), FlagDto(
                        id = null,
                        carTypeId = null,
                        maxPerDay = maxPerDay,
                        percentage = percentage,
                        flagThreshold = 0.0,
                        profileGroupId = null,
                        driverId = null,
                        noDestination = noDestination,
                    )
                )
            )
        } else {
            viewModel.editItem(
                ExtendedDriverProfileGroupDto(
                    DriverProfileGroupDto(dto.driverProfileGroup.id, name, commission, isPercentage), FlagDto(
                        id = dto.flag.id,
                        carTypeId = dto.flag.carTypeId,
                        maxPerDay = maxPerDay,
                        percentage = percentage,
                        flagThreshold = 0.0,
                        profileGroupId = null,
                        driverId = null,
                        noDestination = noDestination,
                    )
                )
            )
        }
    }

}