package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.components.button
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2019/06/01.
 */


fun showOfflineDeleteConfirmationDialog(message: String, action: () -> Unit) {
    showRemoteDeleteConfirmationDialog(message, null, action, {})
}

fun showRemoteConfirmationDialog(message: String, actionButtonText: String, dismissButtonText: String? = null, uiState: Observable<BasicUiState>?, action: () -> Unit, onConfirmed: () -> Unit) {
    showConfirmationDialogWithRemoteAction(null, message, actionButtonText, dismissButtonText,
            actionButtonStyle = {
                color = Color.white
                backgroundColor = AppColors.redDark
                padding = "8px 12px".dimen()
                fontSize = 16.px
                borderRadius = 4.px
                pointerCursor()
                border = "none"
                hover {
                    backgroundColor = AppColors.redLight
                }
                disabled {
                    backgroundColor = AppColors.textInactive
                    cursor = "not-allowed"
                }

            },
            uiState = uiState,
            action = action,
            onConfirmed = onConfirmed
    )
}

fun showRemoteDeleteConfirmationDialog(message: String, uiState: Observable<BasicUiState>?, action: () -> Unit, onDeleted: () -> Unit) {
    showConfirmationDialogWithRemoteAction("Delete confirmation".localized(), message, "Delete".localized(),
            actionButtonStyle = {
                color = Color.white
                backgroundColor = AppColors.redLight
                padding = "8px 12px".dimen()
                fontSize = 16.px
                borderRadius = 4.px
                pointerCursor()
                border = "none"
                hover {
                    backgroundColor = AppColors.redDark
                }
                disabled {
                    backgroundColor = AppColors.textInactive
                    cursor = "not-allowed"
                }
            },
            uiState = uiState,
            action = action,
            onConfirmed = onDeleted
    )
}

fun showOfflineNegativeDialog(
        title: String,
        message: String,
        mainButtonText: String,
        action: () -> Unit) {
    showRemoteNegativeDialog(title, message, mainButtonText, null, action, {})
}

fun showRemoteNegativeDialog(
        title: String,
        message: String,
        mainButtonText: String,
        uiState: Observable<BasicUiState>?,
        action: () -> Unit,
        onDeleted: () -> Unit,
) {
    showConfirmationDialogWithRemoteAction(title, message, mainButtonText,
            actionButtonStyle = {
                color = Color.white
                backgroundColor = AppColors.redLight
                padding = "8px 12px".dimen()
                fontSize = 16.px
                borderRadius = 4.px
                pointerCursor()
                border = "none"
                hover {
                    backgroundColor = AppColors.redDark
                }
                disabled {
                    backgroundColor = AppColors.textInactive
                    cursor = "not-allowed"
                }
            },
            uiState = uiState,
            action = action,
            onConfirmed = onDeleted
    )
}

fun showOfflineConfirmationDialog(title: String?, message: String, actionButtonText: String? = null, dismissButtonText: String? = null, actionButtonStyle: (RuleSet.() -> Unit)? = null, onConfirmed: () -> Unit) {
    showConfirmationDialogWithRemoteAction(title, message, actionButtonText, dismissButtonText, actionButtonStyle, null, 91, { onConfirmed() }, { })
}

fun showConfirmationDialogWithRemoteAction(
        title: String?,
        message: String,
        actionButtonText: String? = null,
        dismissButtonText: String? = null,
        actionButtonStyle: (RuleSet.() -> Unit)?,
        uiState: Observable<BasicUiState>?,
        popUpZIndex: Int = 300,
        action: () -> Unit,
        onConfirmed: () -> Unit
) {
    uiState?.value = null
    theme.showDialog(PopUpDialog.confirmationPopUpDialog) {
        verticalLayout {
            addClass("confirmationDialogRootView")
            style {
                height = wrapContent
                minWidth = 300.px
                backgroundColor = Color.white
                padding = 16.px
                borderRadius = 8.px
            }
            title?.let {
                theme.bigTextView(this) {
                    text = title
                }
            }
            verticalFiller(16)
            textView {
                style {
                    fontSize = 16.px
                }
                text = message
            }

            it.bottomBar = {
                horizontalLayout {
                    style {
                        width = matchParent
                        height = wrapContent
                        justifyContent = JustifyContent.End
                        alignItems = Alignment.Center
                    }

                    theme.unimportantButton(this) {
                        id = "confirmationDialogDismissButton"
                        addClass("confirmationDialogDismiss")
                        text = dismissButtonText ?: "Dismiss".localized()
                        onClick = { PopUpDialog.confirmationPopUpDialog.dismissDialog() }
                    }
                    val showMainButton = actionButtonText?.isNotBlank() == true
                    if (showMainButton) {
                        horizontalFiller(16)
                    }
                    val mainButton = if (actionButtonStyle == null) theme.mainButton(this) {
                    } else button {
                        style { actionButtonStyle() }
                    }
                    mainButton.apply {
                        isVisible = showMainButton
                        id = "confirmationDialogActionButton"
                        text = actionButtonText
                        onClick = {
                            action()
                            if (uiState == null) {
                                PopUpDialog.confirmationPopUpDialog.dismissDialog()
                            }
                        }
                    }
                    uiState?.let {
                        withLoadingAndError(uiState,
                                onRetryClicked = action,
                                onLoaded = {
                                    PopUpDialog.confirmationPopUpDialog.dismissDialog()
                                    onConfirmed()
                                }
                        )
                    }
                }

            }
        }
    }
}

fun showWarningDialog(
        message: String,
) = showWarningDialog(null, message, null, null, null, null, {}, {})

fun showWarningDialog(
        title: String?,
        message: String,
        actionButtonText: String? = null,
        dismissButtonText: String? = null,
        actionButtonStyle: (RuleSet.() -> Unit)? = null,
        uiState: Observable<BasicUiState>? = null,
        action: () -> Unit = {},
        onConfirmed: () -> Unit = {}
) {
    uiState?.value = null
    theme.showDialog(PopUpDialog.warningPopUpDialog, isDismissible = false) {
        verticalLayout {
            addClass("warningDialogRootView")
            style {
                height = wrapContent
                minWidth = 300.px
                backgroundColor = Color.white
                padding = 16.px
                borderRadius = 8.px
            }

            title?.let {
                theme.bigTextView(this) {
                    text = title
                }
                verticalFiller(16)
            }
            horizontalLayout {

                materialIcon("warning") {
                    style {
                        fontSize = 18.px
                        color = AppColors.orange
                        padding = 4.px
                    }

                }
                textView {
                    style {
                        fontSize = 14.px
                        color = AppColors.orange
                        padding = 4.px
                    }
                    text = message
                }
            }

            it.bottomBar = {
                horizontalLayout {
                    style {
                        width = matchParent
                        height = wrapContent
                        justifyContent = JustifyContent.End
                        alignItems = Alignment.Center
                    }

                    theme.unimportantButton(this) {
                        id = "confirmationDialogDismissButton"
                        addClass("confirmationDialogDismiss")
                        text = dismissButtonText ?: "Dismiss".localized()
                        onClick = { PopUpDialog.warningPopUpDialog.dismissDialog() }
                    }
                    val showMainButton = actionButtonText?.isNotBlank() == true
                    if (showMainButton) {
                        horizontalFiller(16)
                    }
                    val mainButton = if (actionButtonStyle == null) theme.mainButton(this) {
                    } else button {
                        style { actionButtonStyle() }
                    }
                    mainButton.apply {
                        isVisible = showMainButton
                        id = "confirmationDialogActionButton"
                        text = actionButtonText
                        onClick = {
                            action()
                            if (uiState == null) {
                                PopUpDialog.warningPopUpDialog.dismissDialog()
                            }
                        }
                    }
                    uiState?.let {
                        withLoadingAndError(uiState,
                                onRetryClicked = action,
                                onLoaded = {
                                    PopUpDialog.warningPopUpDialog.dismissDialog()
                                    onConfirmed()
                                }
                        )
                    }
                }

            }
        }
    }
}
