package com.narbase.letsgo.web.views.user.profile

import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.common.data.Privilege
import com.narbase.letsgo.web.network.DtoName

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/30.
 */

object GetUserProfileDto {
    class ResponseDto(val profile: UserProfileDto)
}

object UpdateUserProfileDto {
    class RequestDto(val title: String?,
                     val preferredName: String,
                     val fullName: String,
                     val callingCode: String,
                     val localPhone: String)

    class ResponseDto
}

class UserProfileDto(
        val clientId: String,
        val staffId: String,
        val doctorId: String?,
        val title: String,
        val fullName: String,
        val userName: String,
        val preferredName: String?,
        val practice: Unit?,
        val callingCode: String,
        val localPhone: String,
        val roles: Array<DtoName<AuthorizedRole>>,
        val privileges: Array<DtoName<Privilege>>
)