package com.narbase.letsgo.web.routing.domain.admin.incentives

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.config.ConfigDto

object GetConfigEndpoint : EndPoint<GetConfigEndpoint.Request, GetConfigEndpoint.Response>() {
    class Request()

    class Response(
        val data: ConfigDto?
    )
}