package com.narbase.letsgo.web.dto.orderingIncentives

import com.narbase.letsgo.web.dto.currency.CurrencyDto
import com.narbase.letsgo.web.dto.passengerprofilegroup.PassengerProfileGroupDto

class OrderingIncentiveRmDto(
    val orderingIncentives: Array<ExtendedOrderingIncentiveDto>,
    val passengerProfileGroup: PassengerProfileGroupDto,
)

class ExtendedOrderingIncentiveDto(
    val orderingIncentive: OrderingIncentiveDto,
    val currency: CurrencyDto,
    )