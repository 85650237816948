package com.narbase.letsgo.web.routing.domain.admin.drivers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.drivers.UpsertDriverDto

object UpsertDriverEndpoint : EndPoint<UpsertDriverEndpoint.Request, UpsertDriverEndpoint.Response>() {
    class Request(
        val item: UpsertDriverDto
    )

    class Response
}