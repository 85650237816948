package com.narbase.letsgo.web.views.admin.drivers

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.DEFAULT_IMAGE
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.formatDateAndTime
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.uploaders.fixOldBaseUrl
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.utils.views.customViews.CustomCheckBox
import com.narbase.letsgo.web.utils.views.customViews.customCheckBox

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/19.
 */
class DriversManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = DriversManagementViewModel()
    private var adminsCheckbox: CustomCheckBox? = null
    private var doctorsCheckbox: CustomCheckBox? = null
    private var receptionistsCheckbox: CustomCheckBox? = null

    private var listTableBody: View? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getList()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.data.forEachIndexed { index, item ->
                tableRow {
                    id = item.driver.fullName
                    tableCell({ 32.px }) {
                        imageView {
                            style {
                                width = wrapContent
                                height = 24.px
                                backgroundColor = Color.white
                                borderRadius = 50.percent
                            }
                            element.src = item.driver.imageUrl?.fixOldBaseUrl() ?: DEFAULT_IMAGE
                        }

                    }
                    tableCell(item.driver.fullName, 2, 14.px)
                    tableCell(item.driver.phone, 1, 14.px)
                    tableCell(item.driver.email, 1, 14.px)
                    tableCell(item.driver.vehicle, 1, 14.px)
                    tableCell(item.driver.carModel ?: "-", 1, 14.px)
                    tableCell(item.driver.licenseNumber, 1, 14.px)
                    tableCell(item.driver.createdOn?.toDate()?.formatDateAndTime() ?: "", 1, 14.px)
                    onClick = {
                        UpsertDriverDialog(onUpdated = { viewModel.getList() }).edit(item)
                    }
                }

                if (index != viewModel.data.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "staffManagementRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }

                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Drivers Management".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()

                    addDriverButton()

                }

                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }

                    horizontalFiller()
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                verticalLayout {
                    matchParentDimensions()
                    withLoadingAndError(viewModel.uiState,
                        onRetryClicked = { viewModel.getList() },
                        onLoaded = { onListLoaded() })

                    listTableBody = listTable {
                        headerCell("".localized(), { 32.px })
                        headerCell("Full name".localized(), 2)
                        headerCell("Phone".localized(), 1)
                        headerCell("Email".localized(), 1)
                        headerCell("Vehicle".localized(), 1)
                        headerCell("Model".localized(), 1)
                        headerCell("LicenseNumber".localized(), 1)
                        headerCell("Created on".localized(), 1)
                    }
                    paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
                }
            }
        }
    }

    @Suppress("unused") // Will be used when we add filter for dynamic roles
    private fun LinearLayout.rolesFilter() {
        adminsCheckbox = customCheckBox("Admins".localized(), true) {
            updateFilterList()
        }
        view { style { width = 16.px } }
        doctorsCheckbox = customCheckBox("Doctors".localized(), true) {
            updateFilterList()

        }
        view { style { width = 16.px } }
        receptionistsCheckbox = customCheckBox("Receptionists".localized(), true) {
            updateFilterList()

        }
        view { style { width = 16.px } }
    }

    private fun LinearLayout.addDriverButton() {
        textView {
            style {
                color = Color.white
                padding = "2px 12px".dimen()
                backgroundColor = AppColors.mainColor
                borderRadius = 12.px
                pointerCursor()
                hover {
                    backgroundColor = AppColors.mainDarkColor
                }
            }

            onClick = {
                UpsertDriverDialog(onUpdated = { viewModel.getList() }).add()
            }

            id = "AddDriverButton"
            text = "+ Add new driver"
        }
    }

    private fun updateFilterList() {
        val roles = mutableListOf<AuthorizedRole>()
        if (adminsCheckbox?.isSelected == true) roles.add(AuthorizedRole.Admin)
        if (doctorsCheckbox?.isSelected == true) roles.add(AuthorizedRole.Doctor)
        if (receptionistsCheckbox?.isSelected == true) roles.add(AuthorizedRole.Receptionist)
//        viewModel.filterStaffBy(roles)
    }


}
