package com.narbase.letsgo.web.storage

import com.narbase.letsgo.models.Language
import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.common.data.MedicoModules
import com.narbase.letsgo.web.network.dto
import kotlinx.browser.window
import org.w3c.dom.get


/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2013] - [2018] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 12/14/17.
 */
object StorageManager {

    private const val LOGGED_IN = "LOGGED_IN"
    const val ACCESS_TOKEN = "ACCESS_TOKEN"
    private const val IS_FIRST_LOGIN = "IS_FIRST_LOGIN"
    private const val CLINIC_SLUG = "CLINIC_SLUG"
    private const val CLINIC_CHAIN_SLUG = "CLINIC_CHAIN_SLUG"

    private const val SKIP_TIPS = "SKIP_TIPS"
    private const val TIPS_DISPLAYED = "TIPS_DISPLAYED"

    private const val LANGUAGE = "LANGUAGE"

    private const val CLINIC_DETAILS = "CLINIC_DETAILS"
    private const val CLINIC_AND_CHAIN_INFO = "CLINIC_AND_CHAIN_INFO"

    private const val APPOINTMENT_MINIMUM_TIME = "APPOINTMENT_MINIMUM_TIME"
    private const val REMAINING_DAYS = "REMAINING_DAYS"
    private const val PERMISSIVE_REMAINING_DAYS = "PERMISSIVE_REMAINING_DAYS"
    private const val ENABLED_MODULES = "ENABLED_MODULES"
    private const val AUTOFILL_INSURANCE_DISTRIBUTION = "AUTOFILL_INSURANCE_DISTRIBUTION"
    private const val NOTIFICATIONS_ENABLED = "NOTIFICATIONS_ENABLED"
    private const val TIME_DIFFERENCE = "TIME_DIFFERENCE"

    private val itemsToClearOnLogout = arrayOf(SKIP_TIPS)
    fun clear() {
        itemsToClearOnLogout.forEach {
            window.localStorage.removeItem(it)
        }
    }

    fun setUserLoggedIn(isLoggedIn: Boolean) {
        window.localStorage.setItem(LOGGED_IN, if (isLoggedIn) "true" else "false")
    }


    fun setRemainingDays(days: Int) {
        window.localStorage.setItem(REMAINING_DAYS, days.toString())
    }

    fun getRemainingDays(): Int {
        return window.localStorage[REMAINING_DAYS]?.toInt() ?: 0
    }

    fun setPermissiveRemainingDays(days: Int) {
        window.localStorage.setItem(PERMISSIVE_REMAINING_DAYS, days.toString())
    }

    fun getPermissiveRemainingDays(): Int {
        return window.localStorage[PERMISSIVE_REMAINING_DAYS]?.toInt() ?: 0
    }

    fun isUserLoggedIn(): Boolean {
        return window.localStorage[LOGGED_IN] == "true"
    }

    fun isTipDisplayedBefore(tipTitle: String): Boolean {
        val tips = JSON.parse<Array<String>>(window.localStorage[TIPS_DISPLAYED] ?: "[]")
        return tips.contains(tipTitle)
    }

    fun setTipDisplayed(tipTitle: String) {
        val tips = JSON.parse<Array<String>>(window.localStorage[TIPS_DISPLAYED] ?: "[]")
        val newTips = arrayOf(*tips, tipTitle)
        window.localStorage.setItem(TIPS_DISPLAYED, JSON.stringify(newTips))
    }

    fun clearDisplayedTips() {
        window.localStorage.setItem(TIPS_DISPLAYED, JSON.stringify(arrayOf<String>()))
    }

    var clinicSettings: AppSettings?
        get() {
            return JSON.parse(
                    window.localStorage.getItem(CLINIC_DETAILS) ?: JSON.stringify(AppSettings.default))
        }
        set(value) {
            window.localStorage.setItem(CLINIC_DETAILS, JSON.stringify(value))
        }


    var accessToken: String?
        get() = window.localStorage[ACCESS_TOKEN]
        set(value) {
            window.localStorage.setItem(ACCESS_TOKEN, value ?: "")
        }

    var isFirstLogin: Boolean
        get() = window.localStorage[IS_FIRST_LOGIN] == "true"
        set(value) {
            window.localStorage.setItem(IS_FIRST_LOGIN, if (value) "true" else "false")
        }

    var shouldSkipTips: Boolean
        get() = window.localStorage[SKIP_TIPS] == "true"
        set(value) {
            window.localStorage.setItem(SKIP_TIPS, if (value) "true" else "false")
        }


    var chainSlug: String?
        get() = window.localStorage[CLINIC_CHAIN_SLUG]
        set(value) {
            window.localStorage.setItem(CLINIC_CHAIN_SLUG, value ?: "")
        }

    var language: Language
        get() = Language.valueOf(window.localStorage[LANGUAGE] ?: Language.En.name)
        set(value) {
            window.localStorage.setItem(LANGUAGE, value.name)
        }

    var enabledModules: Array<MedicoModules>
        get() = JSON.parse<Array<String>>(
                window.localStorage.getItem(ENABLED_MODULES)
                        ?: JSON.stringify(arrayOf<String>())
        ).mapNotNull { module -> MedicoModules.values().firstOrNull { it.dtoName == module } }
                .toTypedArray()
        set(value) {
            window.localStorage.setItem(ENABLED_MODULES, JSON.stringify(value.map { it.dtoName }))
        }

    var isNotificationsEnabled: Boolean
        get() {
            val saved = window.localStorage[NOTIFICATIONS_ENABLED]
            if (saved != null) return saved == "true"
            return true
        }
        set(value) {
            window.localStorage.setItem(NOTIFICATIONS_ENABLED, if (value) "true" else "false")
        }

    var timeDifferenceInMilliSeconds: Double
        get() = window.localStorage[TIME_DIFFERENCE]?.toDouble() ?: 0.0
        set(value) {
            window.localStorage.setItem(TIME_DIFFERENCE, value.toString())
        }


}
