package com.narbase.letsgo.web.routing.domain.admin.locationpoints

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto

object GetPassengerLocationPointsEndpoint : EndPoint<GetPassengerLocationPointsEndpoint.Request, GetPassengerLocationPointsEndpoint.Response>() {
    class Request(
        val passengerId : Long,
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String,
        val historyLimit: Int,
        val lat: Double?,
        val lng: Double?,
    )

    class Response(
        val list: Array<LocationPointDto>,
        val total: Int
    )
}