package com.narbase.letsgo.web.views.user.base

import com.narbase.letsgo.web.BasePageViewModel
import com.narbase.letsgo.web.translations.localized

class UserProfileTabs(val routeDetails: BasePageViewModel.RouteDetails) {
    ;

    companion object {

        val userProfile = UserProfileTabs(BasePageViewModel.RouteDetails("/user_profile", "User Profile".localized()))

        fun tabs(): List<UserProfileTabs> = listOf(userProfile)
    }
}
