package com.narbase.letsgo.web.views.admin.roles

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.admin.DynamicRoleDto
import com.narbase.letsgo.web.network.UnknownErrorException
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.GetAdminDynamicRolesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.dynamicroles.UpsertAdminDynamicRolesEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.DataResponse

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/07/03.
 */
class RolesManagementViewModel {

    private var searchTerm = ""
    val uiState = Observable<BasicUiState>()
    val upsertUiState = Observable<BasicUiState>()
    var data: List<DynamicRoleDto> = listOf()

    var pageNo = 0
    var pageSize = 20
    var total = 0

    fun getList() {
        upsertUiState.value = null
        basicNetworkCall(uiState) {
            val dto = GetAdminDynamicRolesEndpoint.Request(pageNo, pageSize, searchTerm = searchTerm)
            val response = GetAdminDynamicRolesEndpoint.remoteProcess(dto).data
            data = response.list.toList()
            total = response.total
        }
    }

    fun searchFor(term: String) {
        pageNo = 0
        searchTerm = term
        uiState.value = BasicUiState.Loaded
        getList()
    }

    fun getNextPage() {
        pageNo++
        getList()
    }

    fun getPreviousPage() {
        pageNo--
        getList()
    }

    fun setUpsertUiStateToNull() {
        upsertUiState.value = null
    }

    fun addItem(dto: DynamicRoleDto) {
        basicNetworkCall(upsertUiState) {
            val response = UpsertAdminDynamicRolesEndpoint.remoteProcess(UpsertAdminDynamicRolesEndpoint.Request(dto))
            if (response.status != "${DataResponse.BASIC_SUCCESS}") {
                throw UnknownErrorException()
            }
        }

    }

    fun editItem(dto: DynamicRoleDto) {
        basicNetworkCall(upsertUiState) {
            val response = UpsertAdminDynamicRolesEndpoint.remoteProcess(UpsertAdminDynamicRolesEndpoint.Request(dto))
            if (response.status != "${DataResponse.BASIC_SUCCESS}") {
                throw UnknownErrorException()
            }
        }

    }
}
