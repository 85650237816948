package com.narbase.letsgo.web.views.incentives

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.config.ConfigDto
import com.narbase.letsgo.web.dto.config.ExtendedCurrencyIncentiveConfigDto
import com.narbase.letsgo.web.dto.orderingIncentives.OrderingIncentiveRmDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.currencyincentiveconfigs.GetCurrencyIncentiveConfigsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.GetOrderingIncentivesEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class IncentivesManagementViewModel {
    var searchTerm = ""
    val uiState = Observable<BasicUiState>()
    val updateUiState = Observable<BasicUiState>()

    val getOrderingIncentivesUiState = Observable<BasicUiState>()
    var orderingIncentivesRmDto : List<OrderingIncentiveRmDto> = listOf()

    var extendedCurrencyIncentiveConfigs: List<ExtendedCurrencyIncentiveConfigDto> = listOf()

    var pageNo = 0
    var pageSize = 20
    var total = 0
    fun getCurrencyIncentiveConfigInfo() {
        basicNetworkCall(updateUiState) {
            val dto = GetCurrencyIncentiveConfigsEndpoint.Request(0, 30)
            val response = GetCurrencyIncentiveConfigsEndpoint.remoteProcess(dto)
            extendedCurrencyIncentiveConfigs = response.data.list.toList()
        }
    }

    fun getOrderingIncentives(){
        basicNetworkCall(getOrderingIncentivesUiState) {
            val dto = GetOrderingIncentivesEndpoint.Request(0, 100, searchTerm = "")
            val response = GetOrderingIncentivesEndpoint.remoteProcess(dto).data
            orderingIncentivesRmDto = response.list.toList()
            total = response.total
        }
    }

    fun searchFor(term: String) {
        pageNo = 0
        searchTerm = term
        uiState.value = BasicUiState.Loaded
        getOrderingIncentives()
    }

    fun getNextPage() {
        pageNo++
        getOrderingIncentives()
    }

    fun getPreviousPage() {
        pageNo--
        getOrderingIncentives()
    }
}