package com.narbase.letsgo.web.utils.session

import com.narbase.letsgo.web.common.data.MedicoModules
import com.narbase.letsgo.web.common.data.Privilege
import com.narbase.letsgo.web.storage.SessionInfo
import com.narbase.letsgo.web.storage.StorageManager

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/23.
 */

inline fun authorized(vararg privileges: Privilege, block: () -> Unit) {
    authorizedForAny(privileges.toList(), null, block)
}

inline fun authorizedForAny(privileges: List<Privilege>, requiredModule: MedicoModules? = null,
                            block: () -> Unit) {
    if (isAuthorized(privileges, requiredModule)) {
        block()
    }
}

fun isAuthorized(privileges: List<Privilege> = listOf(), requiredModule: MedicoModules? = null): Boolean {
    val authorizedByPrivilege = SessionInfo.loggedInUser.privileges.any { it in privileges }
    val enabledModules = StorageManager.enabledModules
    val requiredModuleEnabled = (requiredModule == null) || (enabledModules.any { it.dtoName == requiredModule.dtoName })
    return authorizedByPrivilege && requiredModuleEnabled
}
fun doesNotHave(privilege: Privilege, block: () -> Unit) {
    val doesNotHaveRole = SessionInfo.loggedInUser.privileges.none { it == privilege }
    if (doesNotHaveRole) {
        block()
    }
}
