package com.narbase.letsgo.web.views.callCenter.rating

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.view
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.utils.views.materialIcon
import com.narbase.letsgo.web.utils.views.pointerCursor

class RatingComponent(
    private val defaultValue: Double = 0.0,
    private val onStarClicked: ((Double) -> Unit)? = null,

    )  : Component() {

    override fun View?.getView() =

        horizontalLayout {
            style {
                width = wrapContent
                justifyContent = JustifyContent.Center
                alignSelf = Alignment.Center
            }
            (1..5).forEach { i ->

                materialIcon(
                    if (i <= defaultValue) {
                        "star"
                    } else {
                        "star_border"
                    }
                ) {
                    style {
                        color = Color("#FDDA0D")
                        width = 30.px
                    }
                    if (onStarClicked != null){
                        style{
                            pointerCursor()
                        }
                        onClick = {
                            onStarClicked.invoke(i.toDouble())
                        }

                    }

                }

            }

        }
}