package com.narbase.letsgo.web.views.driverProfileGroups

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.driverprofilegroup.DriverProfileGroupDto
import com.narbase.letsgo.web.dto.driverprofilegroup.ExtendedDriverProfileGroupDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup.GetDriverProfileGroupsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup.UpsertDriverProfileGroupEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class DriverProfileGroupsManagementViewModel {

    private var searchTerm = ""
    val uiState = Observable<BasicUiState>()
    val upsertUiState = Observable<BasicUiState>()
    var data: List<ExtendedDriverProfileGroupDto> = listOf()

    var pageNo = 0
    var pageSize = 20
    var total = 0

    fun getList() {
        upsertUiState.value = null
        basicNetworkCall(uiState) {
            val dto = GetDriverProfileGroupsEndpoint.Request(pageNo, pageSize, searchTerm = searchTerm)
            val response = GetDriverProfileGroupsEndpoint.remoteProcess(dto).data
            data = response.list.toList()
            total = response.total
        }
    }

    fun searchFor(term: String) {
        pageNo = 0
        searchTerm = term
        uiState.value = BasicUiState.Loaded
        getList()
    }

    fun getNextPage() {
        pageNo++
        getList()
    }

    fun getPreviousPage() {
        pageNo--
        getList()
    }

    fun setUpsertUiStateToNull() {
        upsertUiState.value = null
    }

    fun addItem(dto: ExtendedDriverProfileGroupDto) {
        basicNetworkCall(upsertUiState) {
            UpsertDriverProfileGroupEndpoint.remoteProcess(UpsertDriverProfileGroupEndpoint.Request(dto))
        }
    }

    fun editItem(dto: ExtendedDriverProfileGroupDto) {
        basicNetworkCall(upsertUiState) {
            UpsertDriverProfileGroupEndpoint.remoteProcess(UpsertDriverProfileGroupEndpoint.Request(dto))
        }
    }
}
