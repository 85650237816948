package com.narbase.letsgo.web.models

import com.narbase.letsgo.dto.EnumDtoName

enum class RidePromoCodeStatus(dtoName: String? = null) : EnumDtoName {
    REDEEMED,
    EXPIRED,
    MAXED_USERS_EXCEEDED,
    ALREADY_USED,
    NOT_ELIGIBLE,
    NOT_FOUND,
    CANNOT_BE_REDEEMED
    ;

    override val dtoName = dtoName ?: this.name

    fun statusMessage() : String = when (this) {
        REDEEMED -> "successfully redeemed"
        EXPIRED -> "This code is expired"
        MAXED_USERS_EXCEEDED -> "max users exceeded for this code"
        ALREADY_USED -> "This code has already been used"
        NOT_ELIGIBLE -> "You are not eligible for this promo"
        NOT_FOUND -> "Promo code not found"
        CANNOT_BE_REDEEMED -> "Cannot be redeemed"
    }

}
fun toRidePromoCodeStatus(status: String): RidePromoCodeStatus = when (status) {
    "0" -> RidePromoCodeStatus.REDEEMED
    "1" -> RidePromoCodeStatus.EXPIRED
    "2" -> RidePromoCodeStatus.MAXED_USERS_EXCEEDED
    "4" -> RidePromoCodeStatus.ALREADY_USED
    "5" -> RidePromoCodeStatus.NOT_ELIGIBLE
    "3" -> RidePromoCodeStatus.NOT_FOUND
    else -> RidePromoCodeStatus.CANNOT_BE_REDEEMED
}