package com.narbase.letsgo.web

import com.narbase.kunafa.core.components.Page
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.page
import com.narbase.kunafa.core.components.view
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.px
import com.narbase.laflif.web.utils.eventbus.EventBus
import com.narbase.letsgo.models.Direction
import com.narbase.letsgo.web.autorefresh.AutoRefreshScheduler
import com.narbase.letsgo.web.events.ElementFocused
import com.narbase.letsgo.web.events.OnMouseDownEvent
import com.narbase.letsgo.web.events.OnMouseUpEvent
import com.narbase.letsgo.web.events.OnStorageUpdatedEvent
import com.narbase.letsgo.web.login.LoginPageContent
import com.narbase.letsgo.web.login.LoginViewController
import com.narbase.letsgo.web.routing.AdminRouting
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.theme.setupGlobalStyles
import com.narbase.letsgo.web.utils.views.PageSlide
import com.narbase.letsgo.web.utils.views.PopUpDialog
import com.narbase.letsgo.web.utils.views.SnackBar
import com.narbase.letsgo.web.utils.views.customViews.initWarningPopupDialog
import com.narbase.letsgo.web.utils.views.initImageViewerPopupDialog
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.StorageEvent

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/02/17.
 */


const val version = "1.0.0"

fun main() {
    requireAssetsForWebpack()
    Main().setup()
}

class Main {
    init {
        AdminRouting.init()
    }

    fun setup() {

        page {
            Page.title = "CarApp Admin"
            Page.useRtl = (StorageManager.language.direction == Direction.RTL)

            document.body?.style?.overflowY = "hidden"
            document.body?.style?.overflowX = "hidden"
            document.body?.style?.width = "100vw"
            document.body?.style?.height = "100vh"

            PageSlide.pageSlidesRootView = view {
                style {
                    width = 0.px
                    height = 0.px
                }
            }
            PopUpDialog.popUpRootView = view {
                style {
                    width = 0.px
                    height = 0.px
                }
            }


            element.spellcheck = true
            setupGlobalStyles()
            initWarningPopupDialog()
            initImageViewerPopupDialog()
            setupEventListeners()
            window.onmouseup = { EventBus.publish(OnMouseUpEvent()) }
            window.onmousedown = { EventBus.publish(OnMouseDownEvent()) }
            SnackBar.setup()
            mountApp()
            AutoRefreshScheduler.start()
        }
    }

    private fun setupEventListeners() {
        document.addEventListener("focusin", {
            EventBus.publish(ElementFocused(document.activeElement))
        })


        window.addEventListener("storage", {
            val event = it as StorageEvent
            if (event.key == StorageManager.ACCESS_TOKEN) {
                window.setTimeout({ window.location.reload() }, 700)
            }
            EventBus.publish(OnStorageUpdatedEvent(event.key))

        })
    }


    private fun universalRules() {

        stringRuleSet("*") {
            outline = "none"
        }
        stringRuleSet("html, body, textarea, button, input") {
            fontFamily = appFontFamily
        }
        stringRuleSet(":not(.normalBar)::-webkit-scrollbar") {
            display = "none"
            this["-ms-overflow-style"] = "none"
        }
        stringRuleSet("textarea::-webkit-scrollbar") {
            display = "initial"
        }
    }

    private fun View.mountApp() {
        val appViewController = AppViewController()
        val loginPage = LoginPageContent(LoginViewController(appViewController))

        mount(AppComponent(appViewController, loginPage))
    }
}

private fun requireAssetsForWebpack() {
    js("require('material-design-icons-iconfont/dist/material-design-icons.css')")
    js("require('typeface-open-sans')")
    js("require('tippy.js/index.css')")
    js("require('tippy.js/themes/light-border.css')")
    js("require('pikaday/css/pikaday.css')")
    js("require('flatpickr/dist/flatpickr.min.css')")
    js("require('viewerjs/dist/viewer.css')")



//    js("require('typeface-scheherazade')")
//    js("require('typeface-cairo')")

}
