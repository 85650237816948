package com.narbase.letsgo.web.dto.orderingIncentives

import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.dto.core.TimeDto

class OrderingIncentiveDto (
    val id: Long?,
    val passengerProfileGroupId: Int,
    val currencyId: Int,
    val timings: TimingsDto,
    val isEnabled: Boolean
)

class TimingsDto(
    val timings: Array<TimingDto>
)

class TimingDto(
    val startTime: TimeDto,
    val endTime: TimeDto,
    val amount: Double
)