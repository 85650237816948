package com.narbase.letsgo.web.login

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.login.AdminLoginEndpoint
import com.narbase.letsgo.web.routing.domain.helloworld.HelloWorldEndPoint
import com.narbase.letsgo.web.utils.BasicUiState

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/03/31.
 */
class LoginViewModel {

    val loginUiState = Observable<BasicUiState>()

    fun login() {
        basicNetworkCall(loginUiState) {
            val response = AdminLoginEndpoint.remoteProcess(AdminLoginEndpoint.Request())
            console.log(response.data.data)
        }
    }

}