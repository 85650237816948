package com.narbase.letsgo.web.routing.domain.admin.price

import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.price.ExtendedPriceDataDto

object GetRidePricesEndpoint : EndPoint<GetRidePricesEndpoint.Request, GetRidePricesEndpoint.Response>() {
    class Request(
        val passengerId : Long,
        val time: DateTimeDto?,
        val pickupLat: Double,
        val pickupLng: Double,
        val destLat: Double,
        val destLng: Double,
        val carTypeIds: Array<Int>? = null,
        val existingRidePromoInfo: ExistingRidePromoInfo? = null
    )

    class Response(
        val prices: Array<ExtendedPriceDataDto>,
    )

    class ExistingRidePromoInfo(
        val rideId: Long,
        val ridePromoId: Long?,
        val ridePromoDiscount: Double?
    )
}