package com.narbase.letsgo.web.routing.domain.admin.callcenter

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.passengers.RegisterPassengerRequestDto

object RegisterPassengerByCallCenterEndpoint : EndPoint<RegisterPassengerByCallCenterEndpoint.Request, RegisterPassengerByCallCenterEndpoint.Response>() {
    class Request(
        val details: RegisterPassengerRequestDto,
    )

    class Response(
        val extendedPassengerDto: ExtendedPassengerDto?,
    )
}