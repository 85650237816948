package com.narbase.letsgo.web.routing.domain.admin.geocode

import com.narbase.letsgo.router.EndPoint

object GetGeocodeEndpoint : EndPoint<GetGeocodeEndpoint.Request, GetGeocodeEndpoint.Response>() {
    class Request(
        val lat: Double,
        val lng: Double,
        val onIdle: Boolean?
    )

    class Response(
        val address: String
    )
}