package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.dto.rides.NewAdminRideRequestDto

object RequestRideAdminEndpoint : EndPoint<RequestRideAdminEndpoint.Request, RequestRideAdminEndpoint.Response>() {
    class Request(
        val request: NewAdminRideRequestDto
    )

    class Response(
        val requestId: Long,
        val bookingKey: String,
        val extendedRideDto: ExtendedRideDto
    )
}