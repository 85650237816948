package com.narbase.letsgo.web.views.callCenter.pointselection

import com.narbase.almutkhassisa.web.views.googlemaps.*
import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.passengers.PassengerDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.*
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.callCenter.CallCenterViewModel
import com.narbase.letsgo.web.views.callCenter.RequestRideComponent
import com.narbase.letsgo.web.views.callCenter.RequestRideViewModel
import kotlin.js.json

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/06/03.
 */
class PointSelectorDialog(
    passengerDto: PassengerDto,
    private val selectPickup: LocationPointDto?,
    private val selectDest: LocationPointDto?,
    private val centerLocationPoint : LocationPointDto?

    ) {
    private val popup by lazy { popUpDialog() }
    private val viewModel = RequestRideViewModel(passengerDto)
    private val location: Observable<LocationPointDto?> = Observable()
    private val pointOfInterestMarkers: MutableList<google.Maps.Marker> = mutableListOf()
    private val historyMarkers: MutableList<google.Maps.Marker> = mutableListOf()

    private val centerLat = centerLocationPoint?.lat ?: 18.0735
    private val centerLng = centerLocationPoint?.lng ?: -15.9582
    private val mapComponent by lazy {
        GoogleMap(
            640.px, json(
                "zoom" to 16,
                "center" to json("lat" to centerLat, "lng" to centerLng),
                "styles" to mapDarkStyle,
                "scrollwheel" to true,
                "mapId" to "Demo_MAP_ID"   //fixme: can it remain as this?
            )
        )
    }

    fun pickLocationDialog(
        isPickup: Boolean,
        onSaved: (isOpenRide: Boolean, LocationPointDto) -> Unit,
        onBackSelected: (() -> Unit)? = null
    ) {
        viewModel.getLocationPoints(0, "", mapComponent.getCenter()?.lat(), mapComponent.getCenter()?.lng())
        viewModel.getRecentPassengerLocation()
        viewModel.getHistoryPoints()
        viewModel.getFavoritePoints()
        theme.showDialog(popup) {
            verticalLayout {
                style {
                    height = wrapContent
                    width = 80.vw
                }
                theme.bigTextView(this) {
                    text = if (isPickup) "Pickup Address".localized() else "Destination".localized()
                }

                horizontalLayout {
                    style {
                        width = matchParent
                        height = 60.vh
                        backgroundColor = Color.white
                        justifyContent = JustifyContent.SpaceBetween
                    }
                    searchList()
                    horizontalSeparator()


                    view {
                        style {
                            width = weightOf(1)
                            height = matchParent
                            position = "relative"
                            alignSelf = Alignment.Center
                        }
                        mount(mapComponent)
                        if (isPickup) {
                            selectPickup?.let {
                                mapComponent.moveToLocation(it.lat, it.lng)
                                mapComponent.setZoom(onMapSelectedZoomLevel)
                            }
                        } else {
                            selectDest?.let {
                                mapComponent.moveToLocation(it.lat, it.lng)
                                mapComponent.setZoom(onMapSelectedZoomLevel)
                            }
                        }

                        imageView {
                            element.src = "/public/img/marker.png"
                            style {
                                width = 40.px
                                alignSelf = Alignment.Center
                                bottom = 50.percent
                                this["left"] = "calc(50% - 20px)"
                                zIndex = PopupZIndex.getTopIndex()
                                position = "absolute"
                            }
                        }
                    }

                    viewModel.pointsUiState.observe {
                        pointOfInterestMarkers.forEach { marker ->
                            marker.setMap(null)
                        }
                        pointOfInterestMarkers.clear()
                        viewModel.pointsOfInterest.forEach {
                            val glyphLabel = glyphWithLabel(it.name, AppColors.mainColor).element
                            val marker = mapComponent.addMarker(
                                it.lat, it.lng, arrayOf(
                                    "title" to it.name,
                                    "content" to google.Maps.PinElement(
                                        RequestRideComponent.Style.nearbyPoints(
                                            glyphLabel
                                        )
                                    ).element
                                )
                            )
                            pointOfInterestMarkers.add(marker)
                            marker.addListener("click") {
                                mapComponent.moveToLocation(it.lat, it.lng)
                                location.value = LocationPointDto(
                                    lat = it.lat,
                                    lng = it.lng,
                                    name = it.name,
                                    address = it.address,
                                    favoritePointDto = null,
                                    pointOfInterestDto = it
                                )
                                mapComponent.setZoom(onMapSelectedZoomLevel)
                                getAreaPointsByBounds()
                            }
                        }
                    }
                    viewModel.historyPointsUiState.observe {
                        viewModel.favotiesUiState.observe {
                            viewModel.historyPoints = viewModel.historyPoints.filter {
                                Pair(
                                    it.lng,
                                    it.lat
                                ) !in viewModel.favoritePoints.map { fav -> Pair(fav.lng, fav.lat) }
                            }
                            historyMarkers.forEach { marker ->
                                marker.setMap(null)
                            }
                            historyMarkers.clear()
                            viewModel.historyPoints.forEach {
                                val marker = mapComponent.addMarker(
                                    it.lat, it.lng,
                                    arrayOf(
                                        "title" to it.address,
                                        "content" to google.Maps.PinElement(
                                            RequestRideComponent.Style.historyPoints(
                                                null
                                            )
                                        ).element
                                    )
                                )
                                historyMarkers.add(marker)
                                marker.addListener("click") {
                                    mapComponent.moveToLocation(it.lat, it.lng)
                                    location.value = LocationPointDto(
                                        lat = it.lat,
                                        lng = it.lng,
                                        name = it.name,
                                        address = it.address,
                                        favoritePointDto = null,
                                        pointOfInterestDto = null
                                    )
                                    mapComponent.setZoom(onMapSelectedZoomLevel)
                                    getAreaPointsByBounds()
                                }

                            }
                        }
                    }

                    viewModel.favotiesUiState.observe {

                        viewModel.favoritePoints.forEach {
                            val glyph = glyphWithLabel(it.name, AppColors.activeColor).element
                            val marker = mapComponent.addMarker(
                                it.lat, it.lng,
                                arrayOf(
                                    "title" to it.name,
                                    "content" to google.Maps.PinElement(RequestRideComponent.Style.favoritePoints(glyph)).element
                                )
                            )
                            marker.addListener("click") {
                                mapComponent.moveToLocation(it.lat, it.lng)
                                location.value = LocationPointDto(
                                    lat = it.lat,
                                    lng = it.lng,
                                    name = it.name,
                                    address = it.fullAddress,
                                    favoritePointDto = it,
                                    pointOfInterestDto = null
                                )
                                mapComponent.setZoom(onMapSelectedZoomLevel)
                                getAreaPointsByBounds()

                            }
                        }

                    }


                    mapComponent.addListener("idle") {
                        var address: String? = null
                        val newLocation = mapComponent.getCenter()?.let { center ->
                            LocationPointDto(
                                lat = center.lat(),
                                lng = center.lng(),
                                name = null,
                                address = address,
                                pointOfInterestDto = null,
                                favoritePointDto = null
                            )
                        }
                        location.value = newLocation
                        getAreaPointsByBounds()
                    }
                    verticalFiller(10)
                    it.bottomBar = {
                        horizontalLayout {

                            location.observe { loc ->
                                clearAllChildren()
                                style {
                                    width = matchParent
                                    height = wrapContent
                                    justifyContent = JustifyContent.End
                                    marginTop = 10.px
                                }

                                theme.outlineButton(this) {
                                    isVisible = loc != null && loc.favoritePointDto == null
                                    text = "Add to Favorites".localized()
                                    onClick = {
                                        loc?.let { loc ->
                                            AddFavouritePointDialog(viewModel).nameDialog(loc, onFavorited = {
                                                viewModel.getLocationPoints(
                                                    0,
                                                    null,
                                                    mapComponent.getCenter()?.lat(),
                                                    mapComponent.getCenter()?.lng()
                                                )
                                                viewModel.getFavoritePoints()
                                            })
                                        }
                                    }
                                }
                                horizontalFiller(20)
                                theme.outlineButton(this) {
                                    isVisible = !isPickup
                                    text = "Select & Back".localized()
                                    onClick = {
                                        if (onBackSelected != null) {
                                            val selectedLocation = location.value
                                                ?: mapComponent.getCenter()?.toLocationPointDto()
                                            selectedLocation?.let { it1 -> onSaved(false, it1) }
                                            popup.dismissDialog()
                                            onBackSelected()
                                        }
                                    }
                                }
                                var isOpenRide: Boolean? = null

                                horizontalLayout {
                                    style {
                                        height = wrapContent
                                        justifyContent = JustifyContent.End
                                    }
                                    theme.mainButton(this) {
                                        isVisible = isPickup
                                        text = "Open Ride".localized()
                                        onClick = {
                                            isOpenRide = true
                                            getGeocodeAddress()
                                        }
                                    }
                                    horizontalFiller(20)
                                    theme.mainButton(this) {
                                        text = "Select".localized()
                                        onClick = {
                                            isOpenRide = false
                                            getGeocodeAddress()
                                        }
                                    }
                                }.withLoadingAndError(viewModel.getAddressUiState, onLoaded = {
                                    if (isOpenRide == true) {
                                        val selectedLocation = location.value
                                            ?: mapComponent.getCenter()?.toLocationPointDto()
                                        if (selectedLocation != null) {
                                            onSaved(true, selectedLocation)
                                            popup.dismissDialog()
                                        } else {
                                            SnackBar.showText("Failed to get map center.".localized())
                                        }
                                    } else if (isOpenRide == false) {
                                        val selectedLocation = location.value
                                            ?: mapComponent.getCenter()?.toLocationPointDto()

                                        if (selectedLocation != null) {
                                            onSaved(false, selectedLocation)
                                            popup.dismissDialog()
                                        } else {
                                            SnackBar.showText("Failed to get map center.".localized())
                                        }
                                    }

                                }, onRetryClicked = {
                                    getGeocodeAddress()
                                })

                            }
                        }
                    }
                }
            }
        }
    }

    private fun getGeocodeAddress() {
        val selectedLocation = location.value
            ?: mapComponent.getCenter()?.toLocationPointDto()
        if (selectedLocation != null) {
            if (selectedLocation.address.isNullOrBlank()) {
                viewModel.getGeocodeAddress(selectedLocation.lat, selectedLocation.lng, onAddressFound = { add ->
                    viewModel.getAddressUiState.observe {
                        val nLocation = selectedLocation.let { new ->
                            LocationPointDto(
                                lat = new.lat,
                                lng = new.lng,
                                name = new.name,
                                address = add,
                                pointOfInterestDto = null,
                                favoritePointDto = null
                            )
                        }
                        location.value = nLocation
                    }
                })
            } else {
                viewModel.getAddressUiState.value = BasicUiState.Loaded
            }

        }

    }

    private fun LinearLayout.searchList() {
        verticalLayout {
            style {
                height = matchParent
                width = 40.percent
            }
            horizontalLayout {
                style {
                    width = matchParent
                    padding = 8.px
                    justifyContent = JustifyContent.Center
                }
                fullWidthSearchTextInput("Search".localized()) {
                    viewModel.getLocationPoints(
                        0,
                        it,
                        mapComponent.getCenter()?.lat(),
                        mapComponent.getCenter()?.lng()
                    )
                }
            }
            horizontalLayout {
                style {
                    width = matchParent
                    alignContent = Alignment.Center
                    justifyContent = JustifyContent.Center
                }
                withLoadingAndError(viewModel.passengerLocationUiState, onLoaded = {
                    val passengerLocation = viewModel.passengerLocationDto
                    if (passengerLocation != null) {
                        location.observe {
                            clearAllChildren()
                            val locationPoint = LocationPointDto(
                                lat = passengerLocation.lat,
                                lng = passengerLocation.lng,
                                name = "Current Passenger Location".localized(),
                                address = null,
                                pointOfInterestDto = null,
                                favoritePointDto = null
                            )
                            locationItemLayout(locationPoint, it, isCurrent = true) {
                                location.value = locationPoint
                                mapComponent.moveToLocation(locationPoint.lat, locationPoint.lng)
                                mapComponent.setZoom(onMapSelectedZoomLevel)
                                getAreaPointsByBounds()
                            }
                        }
                    } else {
                        theme.mediumTextView(this) {
                            style {
                                alignSelf = Alignment.Center
                                padding = 10.px
                            }
                            text = "No recent passenger location recorded"
                        }

                    }

                }, onRetryClicked = { viewModel.getRecentPassengerLocation() })

            }
//            verticalFiller(10)
            verticalLayout {
                style {
                    width = matchParent
                    height = weightOf(1)
                }

                scrollable {

                    verticalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
//                            padding = 16.px
                        }
                        withLoadingAndError(viewModel.locationPointsUiState, onRetryClicked = {
                            viewModel.getLocationPoints(
                                0, "", mapComponent.getCenter()?.lat(),
                                mapComponent.getCenter()?.lng()
                            )
                        }, onLoaded = {
                            clearAllChildren()
                            location.observe {
                                clearAllChildren()
                                viewModel.locationPoints.forEach { locationPoint ->
                                    locationItemLayout(locationPoint, it) {
                                        location.value = locationPoint
                                        mapComponent.moveToLocation(locationPoint.lat, locationPoint.lng)
                                        mapComponent.setZoom(onMapSelectedZoomLevel)
                                        getAreaPointsByBounds()
                                    }
                                }
                            }

                        })
                    }
                }
            }
        }
    }

    private fun google.Maps.LatLng.toLocationPointDto(): LocationPointDto {
        return LocationPointDto(
            lat = lat(),
            lng = lng(),
            name = null,
            address = null,
            pointOfInterestDto = null,
            favoritePointDto = null
        )
    }

    private fun View.locationItemLayout(
        locationPoint: LocationPointDto,
        selectedLocation: LocationPointDto?,
        isCurrent: Boolean = false,
        onSelect: () -> Unit
    ) = horizontalLayout {
        val changableItem: Observable<LocationPointDto?> = Observable()
        changableItem.value = locationPoint
        style {
            width = matchParent
            padding = 8.px
            color = AppColors.text
            backgroundColor = Color.white
            fontSize = 14.px
            cursor = "pointer"
            hover {
                backgroundColor = AppColors.separatorLight
            }
            if (selectedLocation?.lat == locationPoint.lat && selectedLocation.lng == locationPoint.lng) {
                backgroundColor = AppColors.separatorLight
            }
        }

        onClick = {
            if (selectedLocation?.lat == locationPoint.lat && selectedLocation.lng == locationPoint.lng) {
                addRuleSet(classRuleSet {
                    backgroundColor = AppColors.separatorLight
                })
            } else {
                removeRuleSet(
                    classRuleSet {
                        backgroundColor = AppColors.separatorLight
                    })
            }
            onSelect.invoke()
        }
        changableItem.observe { item ->

            clearAllChildren()
            var isFavorited = item?.favoritePointDto != null
            materialIcon(item!!.toMaterialIcon(isCurrent)).addRuleSet(
                classRuleSet {
                    alignSelf = Alignment.Center
                    weightOf(1)
                }
            )
            verticalLayout {
                style {
                    width = weightOf(2)
                    padding = 5.px
                    alignSelf = Alignment.Center
                }
                if (item.favoritePointDto != null || item.pointOfInterestDto != null) {
                    textView {
                        style {
                            width = matchParent
                            height = wrapContent
                            color = AppColors.textDarkest
                            fontSize = 14.px
                        }
                        text = item.name ?: ""
                    }
                }
                textView {
                    style {
                        width = matchParent
                        height = wrapContent
                        color = AppColors.textDarkest
                        fontSize = 14.px
                    }
                    text = item.address ?: item.name ?: "no address"
                }
            }

            val isFavorite = if (item.favoritePointDto != null) "star" else "star_border"
            val iconName = Observable<String>()
            view {
                isVisible = !isCurrent
                iconName.observe {
                    clearAllChildren()
                    materialIcon(it ?: isFavorite).addRuleSet(
                        classRuleSet {
                            alignSelf = Alignment.Center
                            weightOf(1)
                            color = Color("#FDDA0D")
                            zIndex = PopupZIndex.getTopIndex()
                        }
                    )

                }

                onClick = {
                    iconName.value = if (!isFavorited) "star" else "star_border"
                    if (!isFavorited) {
                        AddFavouritePointDialog(viewModel).nameDialog(item, onFavorited = { locationPoint ->
                            isFavorited = true
                            changableItem.value = locationPoint
                            iconName.value = "star"
                            viewModel.getFavoritePoints()

                        })
                        it.stopPropagation()
                    } else if (isFavorited) {
                        viewModel.deleteFavoritePoint(item.favoritePointDto?.id!!)
                        isFavorited = false

                        it.stopPropagation()
                    }
                }
            }
        }
    }

    private fun getAreaPointsByBounds() {
        val zoom = mapComponent.getZoom()
        if (zoom != null && zoom < 12f) {
            pointOfInterestMarkers.forEach { marker ->
                marker.setMap(null)
            }
            pointOfInterestMarkers.clear()
            return
        }
        if (mapComponent.getBounds()?.getNorthEast() != null) {
            if (mapComponent.getBounds()?.getSouthWest() != null) {
                viewModel.getAreaPointsOfInterest(
                    mapComponent.getBounds()?.getNorthEast()!!.lat(),
                    mapComponent.getBounds()?.getNorthEast()!!.lng(),
                    mapComponent.getBounds()?.getSouthWest()!!.lat(),
                    mapComponent.getBounds()?.getSouthWest()!!.lng(),
                    ""
                )
            }
        }

    }

    private fun LocationPointDto.toMaterialIcon(isCurrent: Boolean): String {
        return if (isCurrent) {
            "my_location"
        } else if (this.pointOfInterestDto == null && this.favoritePointDto == null) {
            "history"
        } else if (this.favoritePointDto != null) {
            "star"
        } else {
            "pin_drop"
        }
    }

    companion object {
        private const val onMapSelectedZoomLevel = 19f
    }

}

fun View.glyphWithLabel(name: String, textColor: Color) = verticalLayout {
    style {
        alignItems = Alignment.Center
    }
    view {
        style {
            width = 10.px
            height = 10.px
            borderRadius = 50.percent
            backgroundColor = Color.white
            position = "relative"
            top = 10.px
        }
    }
    textView {
        text = name
        style {
            color = textColor
            position = "relative"
            top = 30.px
            fontSize = AppFontSizes.normalText
        }
    }
}