package com.narbase.letsgo.web.login

import com.narbase.kunafa.core.components.Button
import com.narbase.kunafa.core.components.ImageView
import com.narbase.kunafa.core.components.TextInput
import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.css.classRuleSet
import com.narbase.kunafa.core.css.color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.network.ServerCaller
import com.narbase.letsgo.web.network.networkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.login.AdminLoginEndpoint
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.utils.DataResponse.Companion.BASIC_SUCCESS
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams
import org.w3c.xhr.XMLHttpRequest


class LoginViewController(
        private val navigator: LoginPageNavigator
) {
    var loginButton: Button? = null
    var usernameTextInput: TextInput? = null
    var passwordTextInput: TextInput? = null
    var loadingImageView: ImageView? = null
    var statusTextView: TextView? = null


    val isLoggedIn = Observable<Boolean>()

    private val successfullTextClass = classRuleSet {
        color = AppColors.greenLight
    }
    private val errorTextClass = classRuleSet {
        color = AppColors.redLight
    }
    private val noInternetTextClass = classRuleSet {
        color = AppColors.separatorLight
    }

    val shouldShowClinicsList: Boolean
        get() = chainSlug.isNullOrBlank().not() && URLSearchParams(window.location.search).get("slug").isNullOrBlank()

    fun onViewMounted() {
        passwordTextInput?.text = ""
        statusTextView?.text = ""
    }

    fun onViewCreated() {
        setupClickListeners()
    }

    private fun hideLoginButton() {
        loginButton?.element?.style?.maxHeight = "0px"
        loginButton?.element?.style?.opacity = "0"
        loginButton?.element?.disabled = true
    }

    private fun showLoginButton() {
        loginButton?.element?.style?.maxHeight = "unset"
        loginButton?.element?.style?.opacity = "1"
        loginButton?.element?.disabled = false
    }

    private fun setupClickListeners() {
        loginButton?.onClick = {
            loadingImageView?.isVisible = true
            hideLoginButton()
            statusTextView?.isVisible = false

            ServerCaller.authenticateUser(
                    usernameTextInput?.text?.trim(),
                    passwordTextInput?.text,
                    onSuccess = { xmlHttp ->
                        if (xmlHttp.status == 200.toShort()) {
                            onSuccess(xmlHttp)
                        } else {
                            onUnauthenticated()
                        }
                    },
                    onError = {
                        onFailure()
                    }
            )

        }
    }

    private fun onUnauthenticated() {
        loadingImageView?.isVisible = false
        showLoginButton()
        statusTextView?.addRuleSet(errorTextClass)
        statusTextView?.removeRuleSet(successfullTextClass)
        statusTextView?.removeRuleSet(noInternetTextClass)
        statusTextView?.text = "Username or password are incorrect" //todo change this message to include wrong slug
        statusTextView?.isVisible = true
    }

    private fun onErrorWithMessage(message: String) {
        loadingImageView?.isVisible = false
        showLoginButton()
        statusTextView?.addRuleSet(errorTextClass)
        statusTextView?.removeRuleSet(successfullTextClass)
        statusTextView?.removeRuleSet(noInternetTextClass)
        statusTextView?.text = message
        statusTextView?.isVisible = true
    }

    private fun onUserDisabled() {
        loadingImageView?.isVisible = false
        showLoginButton()
        statusTextView?.addRuleSet(errorTextClass)
        statusTextView?.removeRuleSet(successfullTextClass)
        statusTextView?.removeRuleSet(noInternetTextClass)
        statusTextView?.text = "Your account has been disabled by the admin"
        statusTextView?.isVisible = true
    }

    private fun onSuccess(xmlHttp: XMLHttpRequest) {
        networkCall(
                onConnectionError = { onErrorWithMessage("Connection Error! Try again") },
                onUnknownError = { onErrorWithMessage("Unknown error occurred. Try again or contact support.") },
                onUnauthorized = { onErrorWithMessage("You don't have the required authorization") },
                onUserDisabled = { onUserDisabled() }) {
            val accessToken = (JSON.parse<TokenResposne>(xmlHttp.responseText)).access_token
            console.log(xmlHttp.responseText)
            StorageManager.accessToken = accessToken
            console.log("Setting access token: ${ServerCaller.accessToken}")
            val response = AdminLoginEndpoint.remoteProcess(AdminLoginEndpoint.Request())
            if (response.status == "$BASIC_SUCCESS") {
                StorageManager.shouldSkipTips = false
                StorageManager.clearDisplayedTips()
                loadingImageView?.isVisible = false
                showLoginButton()
                statusTextView?.addRuleSet(successfullTextClass)
                statusTextView?.removeRuleSet(errorTextClass)
                statusTextView?.removeRuleSet(noInternetTextClass)
                statusTextView?.text = "Logged in successfully"
                statusTextView?.isVisible = true
                isLoggedIn.value = true
                navigator.onLoggedInSuccessful()
            } else {
                StorageManager.accessToken = null
                onFailure()
            }
        }
    }

    private fun onFailure() {
        loadingImageView?.isVisible = false
        showLoginButton()
        statusTextView?.removeRuleSet(errorTextClass)
        statusTextView?.removeRuleSet(successfullTextClass)
        statusTextView?.addRuleSet(noInternetTextClass)
        statusTextView?.text = "No internet connection"
        statusTextView?.isVisible = true
    }

}

data class TokenResposne(
        val status: Int,
        val access_token: String,
)
