package com.narbase.letsgo.web.utils

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2018] Narbase Technologies
 * All Rights Reserved.
 * Created by ${user}
 * On: ${Date}.
 */
enum class BasicUiState {
    Loading, Loaded, Error
}
