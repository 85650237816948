package com.narbase.letsgo.web

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.routing.Router
import com.narbase.laflif.web.utils.eventbus.EventBus
import com.narbase.letsgo.web.events.EscapeClickedEvent
import com.narbase.letsgo.web.login.LoginPageContent
import com.narbase.letsgo.web.storage.StorageManager
import com.narbase.letsgo.web.utils.notifications.NotificationsController
import com.narbase.letsgo.web.views.basePage.BasePageComponent
import com.narbase.letsgo.web.views.startup.StartupComponent
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams

val appFontFamily = StorageManager.language.appFontFamily

class AppComponent(
        val appViewController: AppViewController,
        val loginPage: LoginPageContent
) : Component() {
    private var basePage: BasePageComponent? = null

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        stringRuleSet("*") {
            outline = "none"
        }
        stringRuleSet("html, body, textarea, button, input") {
            fontFamily = appFontFamily
        }
        stringRuleSet(":not(.normalBar)::-webkit-scrollbar") {
            display = "none"
            this["-ms-overflow-style"] = "none"
        }
        stringRuleSet("textarea::-webkit-scrollbar") {
            display = "initial"
        }
        addEscapeListener()
    }

    private fun addEscapeListener() {
        document.onkeydown = { evt ->
            val isEscape = if (evt.key != undefined) {
                (evt.key == "Escape" || evt.key == "Esc");
            } else (evt.keyCode == 27)

            if (isEscape) {
                EventBus.publish(EscapeClickedEvent())
            }
            Unit
        }
    }

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        setupObservers()
        appViewController.onViewCreated()

    }

    override fun View?.getView(): View {
        return verticalLayout {
            id = "appView"
            style {
                width = matchParent
                height = matchParent
            }
        }
    }

    private fun setupObservers() {
        AppViewController.loginState.observe { loginState ->
            Router.invalidateCache()
            EventBus.clearAll()
            rootView?.clearAllChildren()
            NotificationsController.disconnect()
            when (loginState) {
                true -> {
                    rootView?.mount(StartupComponent(onReadyToStart = {
                        basePage = BasePageComponent(BasePageViewModel(), appViewController)
                        basePage?.let { rootView?.mount(it) }
                    }))
                }
                false -> {
                    val shouldDirect = window.location.pathname.trim().trim('/').isNotBlank()
                    if (shouldDirect) {
                        val slug = URLSearchParams(window.location.search).get("slug")
                        if (slug != null) {
                            Router.navigateTo("/?slug=$slug")
                        } else {
                            Router.navigateTo("/")
                        }
                    }
                    rootView?.mount(loginPage)
                }
            }
        }

    }

}
