package com.narbase.letsgo.web.routing.domain.admin.incentives

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.orderingIncentives.OrderingIncentiveRmDto
object UpsertOrderingIncentivesEndpoint : EndPoint<UpsertOrderingIncentivesEndpoint.Request, UpsertOrderingIncentivesEndpoint.Response>() {
    class Request(
        val orderingIncentiveRmDto: OrderingIncentiveRmDto,
    )

    class Response()
}