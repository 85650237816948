package com.narbase.letsgo.web.routing.domain.admin.locationpoints

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto

object GetHistoryPointsEndpoint : EndPoint<GetHistoryPointsEndpoint.Request, GetHistoryPointsEndpoint.Response>() {
    class Request(
        val passengerId : Long,
        val pageNo: Int,
        val pageSize: Int,
        val searchTerm: String,
    )

    class Response(
        val list: Array<LocationPointDto>,
        val total: Int
    )
}