package com.narbase.letsgo.web.views.user.profile

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.network.ServerCaller
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.utils.BasicUiState

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/30.
 */
class UserProfileViewModel {
    val getProfileUiState = Observable<BasicUiState>()
    val updateProfileUiState = Observable<BasicUiState>()

    var loadedProfile: UserProfileDto? = null

    fun getProfile() {

        basicNetworkCall(getProfileUiState) {
            val response = ServerCaller.getUserProfiles()
            loadedProfile = response.data.profile
        }
    }

    fun updateProfile(
            title: String?,
            preferredName: String,
            fullName: String,
            callingCode: String,
            localPhone: String
    ) {
        basicNetworkCall(updateProfileUiState) {
            val dto = UpdateUserProfileDto.RequestDto(title, preferredName, fullName, callingCode, localPhone)
            ServerCaller.updateUserProfile(dto)
        }
    }

}
