package com.narbase.letsgo.web.views.user.profile

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.dimen
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.matchParentDimensions
import com.narbase.letsgo.web.utils.views.pointerCursor
import com.narbase.letsgo.web.utils.views.withLoadingAndError
import com.narbase.letsgo.web.views.user.base.UserProfileBaseViewModel
import com.narbase.letsgo.web.views.user.base.UserProfileTabs

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/28.
 */
class UserProfileComponent(
        private val basePageViewModel: UserProfileBaseViewModel
) : Component() {

    private val passwordUpdateComponent: PasswordUpdateComponent = PasswordUpdateComponent(onUpdated = {
    })
    private var profileViewHolder: LinearLayout? = null
    private var editButtonView: View? = null
    private var saveButtonView: View? = null
    private var saveButtonLayout: View? = null

    private val viewModel = UserProfileViewModel()

    private val phoneEditableView = EditablePhoneNumberView()

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        basePageViewModel.selectedTab.value = UserProfileTabs.userProfile
    }

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        this.profileViewHolder?.withLoadingAndError(viewModel.getProfileUiState,
                startWithLoading = true,
                onRetryClicked = {
                    viewModel.getProfile()
                },
                onLoaded = {
                    profileViewHolder?.clearAllChildren()
                    profileViewHolder?.apply {
                        profileViewContent()
                    }
                    populateProfile(viewModel.loadedProfile)
                })
        viewModel.getProfile()
        saveButtonLayout?.withLoadingAndError(viewModel.updateProfileUiState,
                onRetryClicked = {
                    saveSettings()
                },
                onLoaded = {
                    onProfileSaved()
                }
        )
    }

    private fun populateProfile(loadedProfile: UserProfileDto?) {
        loadedProfile ?: return
        phoneEditableView.code = loadedProfile.callingCode
        phoneEditableView.phoneNumber = loadedProfile.localPhone

    }

    override fun View?.getView() = view {
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                    marginBottom = 20.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Your profile".localized()
                        style {
                            width = wrapContent
                            fontSize = AppFontSizes.titleText
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()
                    horizontalLayout {
                        style {
                            width = wrapContent
                        }
                        saveButtonLayout = view {
                            saveButtonView = saveButton()
                        }
                        makeNotVisible(saveButtonView)
                    }
                    editButtonView = editButton()
                }

                verticalFiller(42.px)

                profileViewHolder = verticalLayout {
                    style {
                        width = matchParent
                        height = wrapContent
                    }
                }

                textView {
                    text = "Change password".localized()
                    addRuleSet(editButtonStyle)
                    onClick = {
                        if (passwordUpdateComponent.rootView?.isVisible == false) {
                            makeVisible(passwordUpdateComponent.rootView)
                            passwordUpdateComponent.reset()
                        } else
                            hidePasswordView()
                    }
                }
                verticalLayout {
                    style {
                        width = matchParent
                        justifyContent = JustifyContent.Center
                        padding = 16.px
                    }
                    mount(passwordUpdateComponent)
                }
                hidePasswordView()
            }
        }
    }

    private fun hidePasswordView() {
        makeNotVisible(passwordUpdateComponent.rootView)
    }

    private fun LinearLayout.profileViewContent() = verticalLayout {
        textView {
            text = "Basic information".localized()
            style {
                width = wrapContent
                fontSize = 16.px
                fontWeight = "600"
                marginBottom = 20.px
                color = AppColors.black
            }
        }
//        profileEntry("Full name".localized(), fullNameEditableText)
//        viewModel.loadedProfile?.preferredName?.let { profileEntry("Preferred name".localized(), preferredNameEditableText) }
        profileEntry("Phone number".localized(), phoneEditableView)

    }


    private fun LinearLayout.profileEntry(label: String, component: Component) {
        horizontalLayout {
            style {
                width = matchParent
            }

            textView {
                text = label
                style {
                    width = 200.px
                    fontSize = 16.px
                    textAlign = TextAlign.Right
                    color = AppColors.mainColor
                    padding = 4.px
                }
            }

            view {
                style {
                    width = 1.px
                    height = matchParent
                    margin = "0px 20px".dimen()
                    backgroundColor = AppColors.mainColor
                }
            }

            view {
                style {
                    width = weightOf(1)
                    marginBottom = 16.px
                }
                mount(component)
            }
        }
    }

    private val editButtonStyle = classRuleSet {
        color = Color.white
        padding = "2px 12px".dimen()
        backgroundColor = AppColors.mainColor
        borderRadius = 12.px
        pointerCursor()
        hover {
            backgroundColor = AppColors.mainDarkColor
        }
    }

    private fun LinearLayout.editButton() = textView {
        text = "Edit profile".localized()
        addRuleSet(editButtonStyle)
        onClick = {
            onEditClicked()
        }
    }

    private fun View.saveButton() = textView {
        text = "Save profile".localized()
        style {
            color = Color.white
            padding = "2px 12px".dimen()
            backgroundColor = AppColors.mainColor
            borderRadius = 12.px
            pointerCursor()
            hover {
                backgroundColor = AppColors.mainDarkColor
            }
        }
        onClick = {
            saveSettings()
        }
    }

    private fun onEditClicked() {
        makeNotVisible(editButtonView)
        makeVisible(saveButtonView)
        phoneEditableView.enableEdit()
    }

    private fun saveSettings() {

        viewModel.updateProfile(
                viewModel.loadedProfile?.title,
                "",
                "fullNameEditableText.text",
                phoneEditableView.code,
                phoneEditableView.phoneNumber
        )
    }


    private fun onProfileSaved() {
        makeVisible(editButtonView)
        makeNotVisible(saveButtonView)
        phoneEditableView.disableEdit()
    }

    companion object {
//        val routeDetails = BasePageViewModel.RouteDetails("/profile", "Profile".localized())
    }

}
