package com.narbase.letsgo.web.views.admin.auditlog

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.dto.admin.ExtendedAuditLogDto
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.formatDateAndTime
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.string.splitCamelCase
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*


class AuditLogComponent(private val subjectId: String? = null) : Component() {
    var componentPath: String? = null
    private val viewModel = AuditLogViewModel(subjectId)

    private var listTableBody: View? = null
    private var paginationControls: PaginationControls? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getList()
    }


    override fun View?.getView() = view {
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Audit log".localized()
                        style {
                            width = wrapContent
                            fontSize = AppFontSizes.titleText
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()

                }
                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }
                    filtersView()
                    horizontalFiller()
//                    searchTextInput("Search".localized()) {
//                        viewModel.searchFor(it)
//                    }
                }

                verticalLayout {
                    style {
                        width = matchParent
                        height = wrapContent
                    }

                    listTableBody = listTable {

                        headerCell("Timestamp".localized(), 1)
                        headerCell("Staff".localized(), 2)
                        headerCell("Event type".localized(), 1)
                        headerCell("Event".localized(), 2)
                        headerCell("Subject".localized(), 2)
                        headerCell("", 1)

                    }
                    paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)

                    withLoadingAndError(viewModel.getUiState,
                            startWithLoading = true,
                            onRetryClicked = {
                                viewModel.getList()
                            },
                            onLoaded = {
                                populateList()
                            })
                }

            }

        }
    }

    private fun View.filtersView() {
//        setupRemoteDropDownList<InsuranceProviderDto>(
//                name = "Insurance provider".localized(),
//                endPoint = "/api/user/v1/insurance/providers/list",
//                itemToString = { it.name },
//                onItemSelected = { viewModel.filterByProviderId(it?.id) },
//                itemsRequestFactory = GetInsuranceProvidersRequestFactory(),
//                viewWidthFactory = { 200.px }
//        )
    }


    private fun upsertDialog(dto: ExtendedAuditLogDto) {
        val entry = dto.entry
        viewModel.upsertUiState.clearObservers()
        entry.data?.let { JsonViewerDialog().show("${entry.event} by ${dto.admin.fullName}", it) }
    }

    private fun populateList() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        val list = viewModel.list
        listTableBody?.clearAllChildren()
        val lastIndex = list.lastIndex
        listTableBody?.apply {
            list.forEachIndexed { index, item ->
                addRow(item)
                if (index != lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    private fun View.addRow(dto: ExtendedAuditLogDto) {
        val entry = dto.entry
        val viewDetailsText = entry.data?.let { "View Details".localized() } ?: ""
        tableRow(isClickable = viewDetailsText.isNotBlank()) {
//            id = entry.id
            tableCell(entry.createdOn.toDate().formatDateAndTime(), 1)
            tableCell(dto.admin.fullName ?: dto.admin.email, 2)
            tableCell(entry.eventType.enum().name.splitCamelCase(), 1)
            tableCell(entry.event.splitCamelCase(), 2)
            tableCell(entry.subjectId.toString(), 2)

            tableCell(viewDetailsText, 1)

            onClick = {
                upsertDialog(dto)
            }
        }
    }

}
