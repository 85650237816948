package com.narbase.almutkhassisa.web.views.googlemaps

import kotlin.js.json

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2019/05/21.
 */

val mapDarkStyle = arrayOf(
        json("elementType" to "geometry", "stylers" to arrayOf(json("color" to "#E8EAEB"))),
        json("elementType" to "labels.text.fill", "stylers" to arrayOf(json("color" to "#004E9E"))),
        json(
                "featureType" to "administrative.locality",
                "elementType" to "labels.text.fill",
                "stylers" to arrayOf(json("color" to "#004E9E"))
        ),
        json(
                "featureType" to "poi",
                "stylers" to arrayOf(json("visibility" to "off"))
        ),
        json(
                "featureType" to "poi.business",
                "stylers" to arrayOf(json("visibility" to "off"))
        ),
        json(
                "featureType" to "road",
                "elementType" to "geometry",
                "stylers" to arrayOf(json("color" to "#BEC3C8"))
        ),
        json(
                "featureType" to "road",
                "elementType" to "labels.text.fill",
                "stylers" to arrayOf(json("color" to "#BEC3C8"))
        ),
        json(
                "featureType" to "road.highway",
                "elementType" to "geometry",
                "stylers" to arrayOf(json("color" to "#BEC3C8"))
        ),
        json(
                "featureType" to "road.highway",
                "elementType" to "labels.text.fill",
                "stylers" to arrayOf(json("color" to "#f3d19c"))
        ),
        json(
                "featureType" to "transit",
                "elementType" to "geometry",
                "stylers" to arrayOf(json("color" to "#E8EAEB"))
        ),
        json(
                "featureType" to "transit.station",
                "elementType" to "labels.text.fill",
                "stylers" to arrayOf(json("color" to "#000000"))
        ),
        json(
                "featureType" to "water",
                "elementType" to "geometry",
                "stylers" to arrayOf(json("color" to "#ABDBFA"))
        ),
        json(
                "featureType" to "water",
                "elementType" to "labels.text.fill",
                "stylers" to arrayOf(json("color" to "#ABDBFA"))
        )
)
