package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.rides.RideDto
import com.narbase.letsgo.web.models.RideChangeabilityStatus

object UpdateRideLocationsEndpoint
    : EndPoint<UpdateRideLocationsEndpoint.Request, UpdateRideLocationsEndpoint.Response>() {
    class Request(
        val rideId: Long,
        val oldRide: RideDto,
        val pickup: LocationPointDto,
        val destination: LocationPointDto?,
        val priceToken: String?,
    )

    class Response(
        val rideChangeabilityStatus: DtoName<RideChangeabilityStatus>
    )
}