package com.narbase.letsgo.web.network.ipLocking

import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.views.PopUpDialog
import com.narbase.letsgo.web.utils.views.showWarningDialog
import kotlinx.browser.window

fun ipAddressNotAllowed(){
    console.log("IP address not Allowed eh")
    PopUpDialog.confirmationPopUpDialog.dismissDialog()
    showWarningDialog(
        title = "IP Address not allowed".localized(),
        message = "This is Not a valid Ip Address.".localized(),
        actionButtonText = "Refresh now".localized(),
        dismissButtonText = "Skip for now".localized(),
        actionButtonStyle = null,
        action = {
//            logoutUser()
            window.location.reload()
        },
        uiState = null,
        onConfirmed = {

        })
}