package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.Component
import com.narbase.kunafa.core.components.TextInput
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.width
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.letsgo.dto.core.GenderDto
import com.narbase.letsgo.web.dto.passengers.RegisterPassengerRequestDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.views.RemoteDropDownList
import com.narbase.letsgo.web.utils.views.SnackBar
import com.narbase.letsgo.web.utils.views.setupDropDownList

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/06/03.
 */
class RegisterPassengerForm(private val phoneNumber: String) : Component() {
    private var fullNameTextInput: TextInput? = null
    private var emailTextInput: TextInput? = null
    private var genderDropList: RemoteDropDownList<GenderDto>? = null
    private var isMale: Boolean? = null

    override fun View?.getView(): View = verticalLayout {
        style {
            width = matchParent
        }

        fullNameTextInput = theme.labeledTextInput(this, "Full name".localized(), isRequired = true) {

        }
        theme.labeledTextInput(this, "Phone number".localized(), isRequired = true) {
            text = phoneNumber
            element.disabled = true
        }
        theme.label(this, "Gender".localized(), isRequired = true)
        genderDropList = setupDropDownList(
            "Gender".localized(),
            getList = { pageNo, _, _ -> if (pageNo == 0) GenderDto.values() else arrayOf() },
            itemToString = { it.name },
            onItemSelected = {
                isMale = when (it) {
                    GenderDto.Male -> true
                    GenderDto.Female -> false
                    null -> null
                }
            },
            viewWidthFactory = { matchParent }
        )
        emailTextInput = theme.labeledTextInput(this, "Email".localized(), isRequired = false) {

        }

    }

    fun validateAndGet(): RegisterPassengerRequestDto? {
        val fullName = fullNameTextInput?.text ?: ""
        val email = emailTextInput?.text ?: ""
        val genderIsMale = isMale
        if (fullName.isBlank()) {
            SnackBar.showText("Full name is required".localized())
            return null
        }
        if (genderIsMale == null) {
            SnackBar.showText("Gender is required".localized())
            return null
        }
        return RegisterPassengerRequestDto(fullName, email, phoneNumber, genderIsMale)
    }

}