package com.narbase.letsgo.web.utils.views

import com.narbase.kunafa.core.dimensions.px

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2018] Narbase Technologies
 * All Rights Reserved.
 * Created by ${user}
 * On: ${Date}.
 */


const val MEDIA_WIDTH_BIG = "(min-width: 760px)"
const val MEDIA_WIDTH_SMALL = "(max-width: 760px)"
const val MEDIA_WIDTH_MEDIUM = "(max-width: 1200px)"
const val MEDIA_BIGGER_THAN_WIDTH_MEDIUM = "(min-width: 1200px)"
