package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.passengers.RegisterPassengerRequestDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.callcenter.RegisterPassengerByCallCenterEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengers.GetPassengerByPhoneEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/06/03.
 */
class PassengerSelectorViewModel {

    val registerPatientUiState = Observable<BasicUiState>()
    var newRegisteredPassenger: ExtendedPassengerDto? = null
    private var phone = ""
    val uiState = Observable<BasicUiState>()
    var data: ExtendedPassengerDto? = null

    fun searchFor(term: String) {
        phone = term
        uiState.value = BasicUiState.Loaded
        getPassenger()
    }
    fun getPassenger() {
        basicNetworkCall(uiState) {
            val dto = GetPassengerByPhoneEndpoint.Request(phone = phone)
            val response = GetPassengerByPhoneEndpoint.remoteProcess(dto).data
            data = response.extendedPassengerDto
        }
    }


    fun registerPatient(details: RegisterPassengerRequestDto) {
        basicNetworkCall(registerPatientUiState) {
            val dto = RegisterPassengerByCallCenterEndpoint.Request(details)
            val response = RegisterPassengerByCallCenterEndpoint.remoteProcess(dto).data
            newRegisteredPassenger = response.extendedPassengerDto
        }
    }

}